import React from 'react';

import { Box, TextField } from '@mui/material';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const getForm = (unit) => ({
  name: unit?.unitname || '',
  floor: unit?.floor || '',
  gla: unit?.gla || '',
  unitid: unit?.unitid || '',
  building: unit?.buildingname || ''
});

const AddUnit = () => {
  const classes = useStyles();
  const unit = useSelector((state) => state.modals.modalProps.unit);
  const [form, setForm] = React.useState(getForm(unit));
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.tenants.loading);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  const handleSave = () => {
    if (!form.name || !form.floor || !form.gla) {
      return toast.error('Please fill in the form!');
    }

    return dispatch(actions.createUnit(form, asset.id))
      .then(() => {
        toast.success('Success');
        setForm(getForm());
        dispatch(closeModal());
        dispatch(actions.getUnitSchedule(asset.id));
        dispatch(actions.getUnitStackingPlan(asset.id));
        dispatch(actions.getTenantStackingPlan(asset.id));
        dispatch(actions.getAssetKpi(asset.id));
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    (<ModalWrapper
      size="xl"
      header={form.unitid ? 'Edit Unit' : 'Create Unit'} // Changed prop name to 'label'
      label={form.unitid ? 'Edit Unit' : 'Create Unit'} // Changed prop name to 'label'
      handleSave={handleSave}
      disabled={isEqual(unit, form)}
      loading={!!loading}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <TextField
          fullWidth
          label="Building (Optional)"
          variant="outlined"
          className={classes.textField}
          value={form.building}
          disabled={loading}
          onChange={(e) => handleChange('building', e.target.value)}
          id="building"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Unit Name"
          variant="outlined"
          className={classes.textField}
          value={form.name}
          disabled={loading}
          onChange={(e) => handleChange('name', e.target.value)}
          id="unit"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Floor"
          variant="outlined"
          className={classes.textField}
          value={form.floor}
          disabled={loading}
          onChange={(e) => handleChange('floor', e.target.value)}
          id="Floor"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />

        <TextField
          fullWidth
          label="GLA (m2)"
          variant="outlined"
          className={classes.textField}
          value={form.gla}
          disabled={loading}
          onChange={(e) => handleChange('gla', e.target.value)}
          id="GLA"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
      </Box>
    </ModalWrapper>)
  );
};

export default AddUnit;
