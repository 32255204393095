import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useState } from 'react';
import { formatZillions } from '../../../helpers/utils';

export const MarkerWithInfo = ({place, setMarkerRef}) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  useEffect(() => {
    setMarkerRef(marker, place.id);
  }, [marker]);

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onMouseEnter={() => setInfowindowOpen(true)}
        onMouseLeave={() => setInfowindowOpen(false)}
        position={{lat: place.latitude, lng: place.longitude}}
        title={'AdvancedMarker that opens an Infowindow when clicked.'}
      />
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          headerDisabled
          maxWidth={200}>
          <div>
            <div style={{ padding: '1px 0' }}>{`Name: ${place?.name}`}</div>
            <div style={{ padding: '1px 0' }}>{`Type: ${place?.type}`}</div>
            <div style={{ padding: '1px 0' }}>{`GLA: ${formatZillions(place?.glatotal)} sqm`}</div>
            <div style={{ padding: '1px 0' }}>{`Occupancy: ${place?.occupancy}%`}</div>
            <div style={{ padding: '1px 0' }}>
              {`Total rent: ${formatZillions(place?.rent != null ? place?.rent : 0)}€`}
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfo;
