import React, { Suspense, useEffect, useState } from 'react';

import { Badge, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Notifications } from '@mui/icons-material';
import Breadcrumps from 'components/Breadcrumps';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import { formatNumberToCurrency, formatZillions } from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';
import clsx from 'clsx';
import AGGridTable from '../AGGrid';

const makeBundleId = (portfolioIds) => `Page1-PortfolioIds${portfolioIds}`;

const useLocalStyles = makeStyles((theme) => ({
  icon: {
    color: '#CCCCCC'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    padding: theme.spacing(6)
  },
  badge: {
    minWidth: theme.spacing(2.2),
    height: theme.spacing(2.2),
    width: theme.spacing(2.2)
  }
}));
import useStyles from 'styles/tables';
import Grow from '@mui/material/Grow';
import useRouter from '../../hooks/useRouter';

const Tenants = () => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const dispatch = useDispatch();
  const { portfolioIds, setPortfolios } = useUrlParams();
  const { loaded, error, tenants } = useSelector((state) => state.tenants.tenants);
  const router = useRouter();

  const [colDefs, setColDefs] = useState([
    {
      colId: 'name',
      headerName: 'Tenant',
      field: 'companyname',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableRowGroup: true
    },
    {
      headerName: 'Asset',
      field: 'assetname',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableRowGroup: true
    },
    {
      headerName: 'Industry',
      field: 'industry',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableRowGroup: true
    },
    {
      headerName: 'Contract Start',
      field: 'contractstartdate',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      chartDataType: 'category'
    },
    {
      headerName: 'Contract End',
      field: 'contractenddate',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      chartDataType: 'category'
    },
    {
      headerName: 'GLA (m2)',
      field: 'totalgla',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value),
      chartDataType: 'series',
      aggFunc: 'sum'
    },
    {
      headerName: 'Current Year Rent',
      field: 'totalrent',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value),
      chartDataType: 'series',
      aggFunc: 'sum'
    },
    {
      headerName: 'Rent / SQM / Month',
      filter: 'agNumberColumnFilter',
      field: 'rentpersqm',
      cellRenderer: (params) => formatNumberToCurrency(params.value),
      chartDataType: 'series',
      aggFunc: 'sum'
    },
    {
      headerName: 'In-place',
      field: 'inplace',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) => (
        <span style={{ color: params.value ? '#01B231' : '#AAAAAA' }}>
          {params.value ? 'Yes' : 'No'}
        </span>
      ),
      enableRowGroup: true
    },
    {
      headerName: 'Next Indexation Date',
      field: 'indexationdate',
      cellDataType: 'date',
      filter: 'agDateColumnFilter'
    },
    {
      headerName: 'Next Indexation',
      field: 'indexation',
      filter: 'agNumberColumnFilter',
      valueFormatter: (params) => (params.value === 0 ? '' : `${params.value}%`)
    },
    {
      headerName: 'Comment',
      field: 'comment',
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Notifications',
      field: 'notifs',
      cellRenderer: (params) => (
        <Badge
          badgeContent={params.value}
          color="error"
          overlap="circular"
          classes={{ badge: localClasses.badge }}
        >
          <Notifications className={localClasses.icon} />
        </Badge>
      )
    }
  ]);

  console.log(tenants);

  function monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    if (d1.getDate() > d2.getDate()) {
      months--;
    }

    return months <= 0 ? 0 : months;
  }

  useEffect(() => {
    const bundleId = makeBundleId(portfolioIds);
    dispatch(actions.getTenants(portfolioIds, bundleId, [], true));
  }, [portfolioIds]);

  if (loaded && error) {
    return (
      <div className={localClasses.root}>
        <Typography color="error" variant="h5">
          {error.substr(0, 45)}
        </Typography>
      </div>
    );
  }

  return (
    <Container>
      <Suspense fallback={<Spinner mini />}>
        <Grow in>
          <Box className={clsx(classes.containerLarge)}>
            <Box className={classes.header}>
              <Typography className={classes.title}>{`Leases (${
                tenants?.length || 0
              })`}</Typography>
            </Box>
            <Box className={clsx(classes.table)}>
              <AGGridTable
                data={
                  loaded &&
                  tenants.map((item) => {
                    let months;
                    let endDate = new Date(item.contractenddate);
                    let now = new Date();
                    if (endDate < now && endDate.getFullYear() === now.getFullYear()) {
                      months = monthDiff(new Date(new Date().getFullYear(), 0, 1), endDate);
                    } else {
                      months = monthDiff(new Date(now.getFullYear(), 0, 1), now);
                    }

                    let rent;
                    if (months > 0 && item.totalgla > 0) {
                      rent = item.totalrent / item.totalgla / months / 2;
                    }

                    return {
                      ...item,
                      rentpersqm: rent
                    };
                  })
                }
                colDefs={colDefs}
                classes={classes}
                onCellClicked={(e) => {
                  if (e.column.colId === 'name') {
                    // cell is from non-select column
                    router.push(`/Asset/${e.data.assetid}/Tenant/${e.data.id}`, {
                      from: 'Details'
                    });
                  }
                }}
                rowGroupPanelShow={'always'}
                groupDisplayType={'multipleColumns'}
              ></AGGridTable>
            </Box>
          </Box>
        </Grow>
      </Suspense>
    </Container>
  );
};

export default Tenants;
