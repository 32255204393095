// source: external/tenant.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.external.CashFlowIncomeItem', null, global);
goog.exportSymbol('proto.external.CashFlowItem', null, global);
goog.exportSymbol('proto.external.CashFlowItemData', null, global);
goog.exportSymbol('proto.external.Clause', null, global);
goog.exportSymbol('proto.external.CreateClauseRequest', null, global);
goog.exportSymbol('proto.external.CreateClauseResponse', null, global);
goog.exportSymbol('proto.external.CreateClausesRequest', null, global);
goog.exportSymbol('proto.external.CreateTenantCostManagerRequest', null, global);
goog.exportSymbol('proto.external.CreateTenantCostManagerResponse', null, global);
goog.exportSymbol('proto.external.CreateTenantCostProviderRequest', null, global);
goog.exportSymbol('proto.external.CreateTenantCostProviderResponse', null, global);
goog.exportSymbol('proto.external.CreateTenantManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.CreateTenantManagerTicketResponse', null, global);
goog.exportSymbol('proto.external.CreateTenantOptionRequest', null, global);
goog.exportSymbol('proto.external.CreateTenantOptionResponse', null, global);
goog.exportSymbol('proto.external.CreateTenantRequest', null, global);
goog.exportSymbol('proto.external.CreateTenantResponse', null, global);
goog.exportSymbol('proto.external.CreateTurnoverRequest', null, global);
goog.exportSymbol('proto.external.CreateTurnoverResponse', null, global);
goog.exportSymbol('proto.external.DeleteClauseRequest', null, global);
goog.exportSymbol('proto.external.DeleteTenantManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.DeleteTenantOptionsRequest', null, global);
goog.exportSymbol('proto.external.DeleteTenantRequest', null, global);
goog.exportSymbol('proto.external.DeleteTurnoverRequest', null, global);
goog.exportSymbol('proto.external.GetCashFlowCurrentRequest', null, global);
goog.exportSymbol('proto.external.GetCashFlowCurrentResponse', null, global);
goog.exportSymbol('proto.external.GetClauseRequest', null, global);
goog.exportSymbol('proto.external.GetClauseResponse', null, global);
goog.exportSymbol('proto.external.GetClausesByContractRequest', null, global);
goog.exportSymbol('proto.external.GetClausesByContractResponse', null, global);
goog.exportSymbol('proto.external.GetIncomeByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetIncomeByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetMonthlyCashFlowRequest', null, global);
goog.exportSymbol('proto.external.GetMonthlyCashFlowResponse', null, global);
goog.exportSymbol('proto.external.GetServiceChargeByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetServiceChargeByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetStrategyByContractIDRequest', null, global);
goog.exportSymbol('proto.external.GetStrategyByIDRequest', null, global);
goog.exportSymbol('proto.external.GetStrategyResponse', null, global);
goog.exportSymbol('proto.external.GetTenantContractDatesResponse', null, global);
goog.exportSymbol('proto.external.GetTenantCostContractDetailsByCostManagerIDResponse', null, global);
goog.exportSymbol('proto.external.GetTenantCostContractDetailsRequest', null, global);
goog.exportSymbol('proto.external.GetTenantCostContractDetailsResponse', null, global);
goog.exportSymbol('proto.external.GetTenantCostProviderResponse', null, global);
goog.exportSymbol('proto.external.GetTenantCostProvidersResponse', null, global);
goog.exportSymbol('proto.external.GetTenantCostSupplierDetailsResponse', null, global);
goog.exportSymbol('proto.external.GetTenantDetailsByContractIDRequest', null, global);
goog.exportSymbol('proto.external.GetTenantDetailsByIDRequest', null, global);
goog.exportSymbol('proto.external.GetTenantManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.GetTenantManagerTicketResponse', null, global);
goog.exportSymbol('proto.external.GetTenantManagerTicketsByTenantIDRequest', null, global);
goog.exportSymbol('proto.external.GetTenantManagerTicketsByTenantIDResponse', null, global);
goog.exportSymbol('proto.external.GetTenantOptionsRequest', null, global);
goog.exportSymbol('proto.external.GetTenantOptionsResponse', null, global);
goog.exportSymbol('proto.external.GetTenantRequest', null, global);
goog.exportSymbol('proto.external.GetTenantResponse', null, global);
goog.exportSymbol('proto.external.GetTenantsRequest', null, global);
goog.exportSymbol('proto.external.GetTenantsResponse', null, global);
goog.exportSymbol('proto.external.GetTotalRentByAssetAndYearRequest', null, global);
goog.exportSymbol('proto.external.GetTurnoverRentCurrentRequest', null, global);
goog.exportSymbol('proto.external.GetTurnoverRentCurrentResponse', null, global);
goog.exportSymbol('proto.external.GetTurnoversRequest', null, global);
goog.exportSymbol('proto.external.GetTurnoversResponse', null, global);
goog.exportSymbol('proto.external.MonthlyCashFlow', null, global);
goog.exportSymbol('proto.external.RentCalculationResponse', null, global);
goog.exportSymbol('proto.external.RentCalculationResponse.RentCalculation', null, global);
goog.exportSymbol('proto.external.Tenant', null, global);
goog.exportSymbol('proto.external.TenantActivityFeed', null, global);
goog.exportSymbol('proto.external.TenantActivityFeedAction', null, global);
goog.exportSymbol('proto.external.TenantActivityFeedsByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.TenantActivityFeedsByComponentRequest', null, global);
goog.exportSymbol('proto.external.TenantActivityFeedsResponse', null, global);
goog.exportSymbol('proto.external.TenantClauseDates', null, global);
goog.exportSymbol('proto.external.TenantCostManagerRequest', null, global);
goog.exportSymbol('proto.external.TenantCostManagerResponse', null, global);
goog.exportSymbol('proto.external.TenantCostManagersResponse', null, global);
goog.exportSymbol('proto.external.TenantCostProviderRequest', null, global);
goog.exportSymbol('proto.external.TenantCostSupplierDetailsRequest', null, global);
goog.exportSymbol('proto.external.TenantDetails', null, global);
goog.exportSymbol('proto.external.TenantOption', null, global);
goog.exportSymbol('proto.external.TenantStatus', null, global);
goog.exportSymbol('proto.external.TurnoverMonth', null, global);
goog.exportSymbol('proto.external.TurnoverRentCurrent', null, global);
goog.exportSymbol('proto.external.TurnoverYearMonth', null, global);
goog.exportSymbol('proto.external.UpdateClauseRequest', null, global);
goog.exportSymbol('proto.external.UpdateStrategyRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantCostContractDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantCostManagerRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantCostProviderRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantCostSupplierDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantOptionsRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantRequest', null, global);
goog.exportSymbol('proto.external.UpdateTurnoverRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CreateTenantRequest.repeatedFields_, null);
};
goog.inherits(proto.external.CreateTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantRequest.displayName = 'proto.external.CreateTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantContractDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantContractDatesResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantContractDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantContractDatesResponse.displayName = 'proto.external.GetTenantContractDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantClauseDates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantClauseDates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantClauseDates.displayName = 'proto.external.TenantClauseDates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantRequest.displayName = 'proto.external.GetTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantResponse.displayName = 'proto.external.GetTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantsRequest.displayName = 'proto.external.GetTenantsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantResponse.displayName = 'proto.external.CreateTenantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Tenant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.Tenant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Tenant.displayName = 'proto.external.Tenant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantsResponse.displayName = 'proto.external.GetTenantsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.UpdateTenantRequest.repeatedFields_, null);
};
goog.inherits(proto.external.UpdateTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantRequest.displayName = 'proto.external.UpdateTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteTenantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteTenantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteTenantRequest.displayName = 'proto.external.DeleteTenantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTurnoverRentCurrentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTurnoverRentCurrentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTurnoverRentCurrentRequest.displayName = 'proto.external.GetTurnoverRentCurrentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTurnoverRentCurrentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTurnoverRentCurrentResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTurnoverRentCurrentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTurnoverRentCurrentResponse.displayName = 'proto.external.GetTurnoverRentCurrentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TurnoverRentCurrent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TurnoverRentCurrent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TurnoverRentCurrent.displayName = 'proto.external.TurnoverRentCurrent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCashFlowCurrentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCashFlowCurrentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCashFlowCurrentRequest.displayName = 'proto.external.GetCashFlowCurrentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCashFlowCurrentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetCashFlowCurrentResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetCashFlowCurrentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCashFlowCurrentResponse.displayName = 'proto.external.GetCashFlowCurrentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CashFlowItem.repeatedFields_, null);
};
goog.inherits(proto.external.CashFlowItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowItem.displayName = 'proto.external.CashFlowItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowItemData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CashFlowItemData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowItemData.displayName = 'proto.external.CashFlowItemData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateClausesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CreateClausesRequest.repeatedFields_, null);
};
goog.inherits(proto.external.CreateClausesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateClausesRequest.displayName = 'proto.external.CreateClausesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateClauseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateClauseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateClauseRequest.displayName = 'proto.external.CreateClauseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetClauseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetClauseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetClauseRequest.displayName = 'proto.external.GetClauseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetClausesByContractRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetClausesByContractRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetClausesByContractRequest.displayName = 'proto.external.GetClausesByContractRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetClausesByContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetClausesByContractResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetClausesByContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetClausesByContractResponse.displayName = 'proto.external.GetClausesByContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetClauseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetClauseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetClauseResponse.displayName = 'proto.external.GetClauseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateClauseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateClauseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateClauseResponse.displayName = 'proto.external.CreateClauseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateClauseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateClauseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateClauseRequest.displayName = 'proto.external.UpdateClauseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteClauseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteClauseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteClauseRequest.displayName = 'proto.external.DeleteClauseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Clause = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.Clause, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Clause.displayName = 'proto.external.Clause';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTotalRentByAssetAndYearRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTotalRentByAssetAndYearRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetTotalRentByAssetAndYearRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTotalRentByAssetAndYearRequest.displayName = 'proto.external.GetTotalRentByAssetAndYearRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.RentCalculationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.RentCalculationResponse.repeatedFields_, null);
};
goog.inherits(proto.external.RentCalculationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.RentCalculationResponse.displayName = 'proto.external.RentCalculationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.RentCalculationResponse.RentCalculation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.RentCalculationResponse.RentCalculation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.RentCalculationResponse.RentCalculation.displayName = 'proto.external.RentCalculationResponse.RentCalculation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetStrategyByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetStrategyByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetStrategyByIDRequest.displayName = 'proto.external.GetStrategyByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetStrategyByContractIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetStrategyByContractIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetStrategyByContractIDRequest.displayName = 'proto.external.GetStrategyByContractIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetStrategyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetStrategyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetStrategyResponse.displayName = 'proto.external.GetStrategyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateStrategyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateStrategyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateStrategyRequest.displayName = 'proto.external.UpdateStrategyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTurnoverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTurnoverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTurnoverRequest.displayName = 'proto.external.CreateTurnoverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTurnoverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTurnoverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTurnoverResponse.displayName = 'proto.external.CreateTurnoverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTurnoversRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTurnoversRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTurnoversRequest.displayName = 'proto.external.GetTurnoversRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TurnoverMonth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TurnoverMonth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TurnoverMonth.displayName = 'proto.external.TurnoverMonth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TurnoverYearMonth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.TurnoverYearMonth.repeatedFields_, null);
};
goog.inherits(proto.external.TurnoverYearMonth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TurnoverYearMonth.displayName = 'proto.external.TurnoverYearMonth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTurnoversResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTurnoversResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTurnoversResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTurnoversResponse.displayName = 'proto.external.GetTurnoversResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTurnoverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTurnoverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTurnoverRequest.displayName = 'proto.external.UpdateTurnoverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteTurnoverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteTurnoverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteTurnoverRequest.displayName = 'proto.external.DeleteTurnoverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantOptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantOptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantOptionRequest.displayName = 'proto.external.CreateTenantOptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantOption.displayName = 'proto.external.TenantOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantOptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantOptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantOptionResponse.displayName = 'proto.external.CreateTenantOptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantOptionsRequest.displayName = 'proto.external.GetTenantOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantOptionsResponse.displayName = 'proto.external.GetTenantOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantOptionsRequest.displayName = 'proto.external.UpdateTenantOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteTenantOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteTenantOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteTenantOptionsRequest.displayName = 'proto.external.DeleteTenantOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantDetailsByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantDetailsByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantDetailsByIDRequest.displayName = 'proto.external.GetTenantDetailsByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantDetailsByContractIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantDetailsByContractIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantDetailsByContractIDRequest.displayName = 'proto.external.GetTenantDetailsByContractIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.TenantDetails.repeatedFields_, null);
};
goog.inherits(proto.external.TenantDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantDetails.displayName = 'proto.external.TenantDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantDetailsRequest.displayName = 'proto.external.UpdateTenantDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetIncomeByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetIncomeByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetIncomeByAssetIDRequest.displayName = 'proto.external.GetIncomeByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetIncomeByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetIncomeByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetIncomeByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetIncomeByAssetIDResponse.displayName = 'proto.external.GetIncomeByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowIncomeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CashFlowIncomeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowIncomeItem.displayName = 'proto.external.CashFlowIncomeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetServiceChargeByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetServiceChargeByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetServiceChargeByAssetIDRequest.displayName = 'proto.external.GetServiceChargeByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetServiceChargeByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetServiceChargeByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetServiceChargeByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetServiceChargeByAssetIDResponse.displayName = 'proto.external.GetServiceChargeByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetMonthlyCashFlowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetMonthlyCashFlowRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetMonthlyCashFlowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetMonthlyCashFlowRequest.displayName = 'proto.external.GetMonthlyCashFlowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetMonthlyCashFlowResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetMonthlyCashFlowResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetMonthlyCashFlowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetMonthlyCashFlowResponse.displayName = 'proto.external.GetMonthlyCashFlowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.MonthlyCashFlow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.MonthlyCashFlow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.MonthlyCashFlow.displayName = 'proto.external.MonthlyCashFlow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantCostContractDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantCostContractDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantCostContractDetailsRequest.displayName = 'proto.external.GetTenantCostContractDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantCostContractDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantCostContractDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantCostContractDetailsResponse.displayName = 'proto.external.GetTenantCostContractDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantCostContractDetailsByCostManagerIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.displayName = 'proto.external.GetTenantCostContractDetailsByCostManagerIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantCostContractDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantCostContractDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantCostContractDetailsRequest.displayName = 'proto.external.UpdateTenantCostContractDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantCostProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantCostProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantCostProviderResponse.displayName = 'proto.external.CreateTenantCostProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantCostProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantCostProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantCostProviderRequest.displayName = 'proto.external.CreateTenantCostProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantCostProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantCostProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantCostProviderRequest.displayName = 'proto.external.TenantCostProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantCostProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantCostProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantCostProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantCostProvidersResponse.displayName = 'proto.external.GetTenantCostProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantCostProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantCostProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantCostProviderResponse.displayName = 'proto.external.GetTenantCostProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantCostProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantCostProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantCostProviderRequest.displayName = 'proto.external.UpdateTenantCostProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantCostSupplierDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantCostSupplierDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantCostSupplierDetailsRequest.displayName = 'proto.external.TenantCostSupplierDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantCostSupplierDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantCostSupplierDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantCostSupplierDetailsRequest.displayName = 'proto.external.UpdateTenantCostSupplierDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantCostSupplierDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantCostSupplierDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantCostSupplierDetailsResponse.displayName = 'proto.external.GetTenantCostSupplierDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantCostManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantCostManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantCostManagerRequest.displayName = 'proto.external.TenantCostManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantCostManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantCostManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantCostManagerResponse.displayName = 'proto.external.TenantCostManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantCostManagersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.TenantCostManagersResponse.repeatedFields_, null);
};
goog.inherits(proto.external.TenantCostManagersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantCostManagersResponse.displayName = 'proto.external.TenantCostManagersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantCostManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantCostManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantCostManagerRequest.displayName = 'proto.external.CreateTenantCostManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantCostManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantCostManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantCostManagerRequest.displayName = 'proto.external.UpdateTenantCostManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantCostManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantCostManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantCostManagerResponse.displayName = 'proto.external.CreateTenantCostManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantActivityFeedsByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantActivityFeedsByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantActivityFeedsByAssetIDRequest.displayName = 'proto.external.TenantActivityFeedsByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantActivityFeedsByComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantActivityFeedsByComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantActivityFeedsByComponentRequest.displayName = 'proto.external.TenantActivityFeedsByComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantActivityFeedsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.TenantActivityFeedsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.TenantActivityFeedsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantActivityFeedsResponse.displayName = 'proto.external.TenantActivityFeedsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantActivityFeed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TenantActivityFeed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantActivityFeed.displayName = 'proto.external.TenantActivityFeed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantManagerTicketRequest.displayName = 'proto.external.CreateTenantManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateTenantManagerTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateTenantManagerTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateTenantManagerTicketResponse.displayName = 'proto.external.CreateTenantManagerTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantManagerTicketRequest.displayName = 'proto.external.GetTenantManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantManagerTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantManagerTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantManagerTicketResponse.displayName = 'proto.external.GetTenantManagerTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantManagerTicketsByTenantIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantManagerTicketsByTenantIDRequest.displayName = 'proto.external.GetTenantManagerTicketsByTenantIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantManagerTicketsByTenantIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantManagerTicketsByTenantIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantManagerTicketsByTenantIDResponse.displayName = 'proto.external.GetTenantManagerTicketsByTenantIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantManagerTicketRequest.displayName = 'proto.external.UpdateTenantManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteTenantManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteTenantManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteTenantManagerTicketRequest.displayName = 'proto.external.DeleteTenantManagerTicketRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CreateTenantRequest.repeatedFields_ = [11];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      companyname: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tradingname: jspb.Message.getFieldWithDefault(msg, 2, ''),
      industry: jspb.Message.getFieldWithDefault(msg, 3, ''),
      country: jspb.Message.getFieldWithDefault(msg, 4, ''),
      city: jspb.Message.getFieldWithDefault(msg, 5, ''),
      street: jspb.Message.getFieldWithDefault(msg, 6, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 7, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 8, ''),
      startdate: (f = msg.getStartdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      enddate: (f = msg.getEnddate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      unitidsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
      headOfficeAddress: (f = msg.getHeadOfficeAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      notes: (f = msg.getNotes()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      mainContact: (f = msg.getMainContact()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      position: (f = msg.getPosition()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      phone: (f = msg.getPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      comment: (f = msg.getComment()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantRequest}
 */
proto.external.CreateTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantRequest;
  return proto.external.CreateTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantRequest}
 */
proto.external.CreateTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTradingname(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setIndustry(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStartdate(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEnddate(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.addUnitids(value);
        break;
      case 12:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setHeadOfficeAddress(value);
        break;
      case 13:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setNotes(value);
        break;
      case 14:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setMainContact(value);
        break;
      case 15:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPosition(value);
        break;
      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEmail(value);
        break;
      case 17:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPhone(value);
        break;
      case 18:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradingname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getHeadOfficeAddress();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMainContact();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string companyName = 1;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tradingName = 2;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getTradingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setTradingname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string industry = 3;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postcode = 7;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string assetID = 8;
 * @return {string}
 */
proto.external.CreateTenantRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.StringValue startDate = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue endDate = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated string unitIDs = 11;
 * @return {!Array<string>}
 */
proto.external.CreateTenantRequest.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setUnitidsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.addUnitids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearUnitidsList = function() {
  return this.setUnitidsList([]);
};


/**
 * optional google.protobuf.StringValue head_office_address = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getHeadOfficeAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setHeadOfficeAddress = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearHeadOfficeAddress = function() {
  return this.setHeadOfficeAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasHeadOfficeAddress = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue notes = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getNotes = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setNotes = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearNotes = function() {
  return this.setNotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue main_contact = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getMainContact = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setMainContact = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearMainContact = function() {
  return this.setMainContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasMainContact = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue position = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getPosition = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue email = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue phone = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};

/**
 * optional google.protobuf.StringValue comment = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateTenantRequest.prototype.getComment = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateTenantRequest} returns this
 */
proto.external.CreateTenantRequest.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateTenantRequest.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantContractDatesResponse.repeatedFields_ = [4];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantContractDatesResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantContractDatesResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantContractDatesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantContractDatesResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      companyname: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      unitidsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      startdate: jspb.Message.getFieldWithDefault(msg, 5, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 6, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantContractDatesResponse}
 */
proto.external.GetTenantContractDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantContractDatesResponse;
  return proto.external.GetTenantContractDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantContractDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantContractDatesResponse}
 */
proto.external.GetTenantContractDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.addUnitids(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantContractDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantContractDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantContractDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantContractDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.GetTenantContractDatesResponse.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string companyName = 2;
 * @return {string}
 */
proto.external.GetTenantContractDatesResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetTenantContractDatesResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string unitIDs = 4;
 * @return {!Array<string>}
 */
proto.external.GetTenantContractDatesResponse.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.setUnitidsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.addUnitids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.clearUnitidsList = function() {
  return this.setUnitidsList([]);
};


/**
 * optional string startDate = 5;
 * @return {string}
 */
proto.external.GetTenantContractDatesResponse.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string endDate = 6;
 * @return {string}
 */
proto.external.GetTenantContractDatesResponse.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantContractDatesResponse} returns this
 */
proto.external.GetTenantContractDatesResponse.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantClauseDates.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantClauseDates.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantClauseDates} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantClauseDates.toObject = function(includeInstance, msg) {
    var f, obj = {
      startdate: jspb.Message.getFieldWithDefault(msg, 1, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantClauseDates}
 */
proto.external.TenantClauseDates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantClauseDates;
  return proto.external.TenantClauseDates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantClauseDates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantClauseDates}
 */
proto.external.TenantClauseDates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantClauseDates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantClauseDates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantClauseDates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantClauseDates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string startDate = 1;
 * @return {string}
 */
proto.external.TenantClauseDates.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantClauseDates} returns this
 */
proto.external.TenantClauseDates.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string endDate = 2;
 * @return {string}
 */
proto.external.TenantClauseDates.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantClauseDates} returns this
 */
proto.external.TenantClauseDates.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantRequest}
 */
proto.external.GetTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantRequest;
  return proto.external.GetTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantRequest}
 */
proto.external.GetTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantRequest} returns this
 */
proto.external.GetTenantRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenant: (f = msg.getTenant()) && proto.external.Tenant.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantResponse}
 */
proto.external.GetTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantResponse;
  return proto.external.GetTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantResponse}
 */
proto.external.GetTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.Tenant;
        reader.readMessage(value, proto.external.Tenant.deserializeBinaryFromReader);
        msg.setTenant(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenant();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.external.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * optional Tenant tenant = 1;
 * @return {?proto.external.Tenant}
 */
proto.external.GetTenantResponse.prototype.getTenant = function() {
  return /** @type{?proto.external.Tenant} */ (
    jspb.Message.getWrapperField(this, proto.external.Tenant, 1));
};


/**
 * @param {?proto.external.Tenant|undefined} value
 * @return {!proto.external.GetTenantResponse} returns this
 */
proto.external.GetTenantResponse.prototype.setTenant = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.GetTenantResponse} returns this
 */
proto.external.GetTenantResponse.prototype.clearTenant = function() {
  return this.setTenant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.GetTenantResponse.prototype.hasTenant = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantsRequest.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantsRequest}
 */
proto.external.GetTenantsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantsRequest;
  return proto.external.GetTenantsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantsRequest}
 */
proto.external.GetTenantsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addAssetids(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string assetIDs = 1;
 * @return {!Array<string>}
 */
proto.external.GetTenantsRequest.prototype.getAssetidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTenantsRequest} returns this
 */
proto.external.GetTenantsRequest.prototype.setAssetidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantsRequest} returns this
 */
proto.external.GetTenantsRequest.prototype.addAssetids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantsRequest} returns this
 */
proto.external.GetTenantsRequest.prototype.clearAssetidsList = function() {
  return this.setAssetidsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantResponse}
 */
proto.external.CreateTenantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantResponse;
  return proto.external.CreateTenantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantResponse}
 */
proto.external.CreateTenantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateTenantResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantResponse} returns this
 */
proto.external.CreateTenantResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.Tenant.prototype.toObject = function(opt_includeInstance) {
    return proto.external.Tenant.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.Tenant} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.Tenant.toObject = function(includeInstance, msg) {
    var f, obj = {
      companyname: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tradingname: jspb.Message.getFieldWithDefault(msg, 2, ''),
      industry: jspb.Message.getFieldWithDefault(msg, 3, ''),
      country: jspb.Message.getFieldWithDefault(msg, 4, ''),
      city: jspb.Message.getFieldWithDefault(msg, 5, ''),
      street: jspb.Message.getFieldWithDefault(msg, 6, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 7, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 8, ''),
      id: jspb.Message.getFieldWithDefault(msg, 9, ''),
      deletedat: (f = msg.getDeletedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      startdate: jspb.Message.getFieldWithDefault(msg, 11, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 12, ''),
      headOfficeAddress: jspb.Message.getFieldWithDefault(msg, 13, ''),
      notes: jspb.Message.getFieldWithDefault(msg, 14, ''),
      mainContact: jspb.Message.getFieldWithDefault(msg, 15, ''),
      position: jspb.Message.getFieldWithDefault(msg, 16, ''),
      email: jspb.Message.getFieldWithDefault(msg, 17, ''),
      phone: jspb.Message.getFieldWithDefault(msg, 18, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Tenant}
 */
proto.external.Tenant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Tenant;
  return proto.external.Tenant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Tenant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Tenant}
 */
proto.external.Tenant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTradingname(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setIndustry(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 10:
        var value = new google_protobuf_timestamp_pb.Timestamp;
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setDeletedat(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setHeadOfficeAddress(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setNotes(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setMainContact(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setPosition(value);
        break;
      case 17:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 18:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      case 19:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Tenant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Tenant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Tenant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Tenant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTradingname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeletedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHeadOfficeAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMainContact();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string companyName = 1;
 * @return {string}
 */
proto.external.Tenant.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tradingName = 2;
 * @return {string}
 */
proto.external.Tenant.prototype.getTradingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setTradingname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string industry = 3;
 * @return {string}
 */
proto.external.Tenant.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.external.Tenant.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.external.Tenant.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string street = 6;
 * @return {string}
 */
proto.external.Tenant.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postcode = 7;
 * @return {string}
 */
proto.external.Tenant.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string assetID = 8;
 * @return {string}
 */
proto.external.Tenant.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string id = 9;
 * @return {string}
 */
proto.external.Tenant.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp deletedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.external.Tenant.prototype.getDeletedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setDeletedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.clearDeletedat = function() {
  return this.setDeletedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Tenant.prototype.hasDeletedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string startDate = 11;
 * @return {string}
 */
proto.external.Tenant.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string endDate = 12;
 * @return {string}
 */
proto.external.Tenant.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string head_office_address = 13;
 * @return {string}
 */
proto.external.Tenant.prototype.getHeadOfficeAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setHeadOfficeAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string notes = 14;
 * @return {string}
 */
proto.external.Tenant.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string main_contact = 15;
 * @return {string}
 */
proto.external.Tenant.prototype.getMainContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setMainContact = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string position = 16;
 * @return {string}
 */
proto.external.Tenant.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string email = 17;
 * @return {string}
 */
proto.external.Tenant.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string phone = 18;
 * @return {string}
 */
proto.external.Tenant.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Tenant} returns this
 */
proto.external.Tenant.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantsList: jspb.Message.toObjectList(msg.getTenantsList(),
        proto.external.Tenant.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantsResponse}
 */
proto.external.GetTenantsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantsResponse;
  return proto.external.GetTenantsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantsResponse}
 */
proto.external.GetTenantsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.Tenant;
        reader.readMessage(value, proto.external.Tenant.deserializeBinaryFromReader);
        msg.addTenants(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.Tenant.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tenant tenants = 1;
 * @return {!Array<!proto.external.Tenant>}
 */
proto.external.GetTenantsResponse.prototype.getTenantsList = function() {
  return /** @type{!Array<!proto.external.Tenant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Tenant, 1));
};


/**
 * @param {!Array<!proto.external.Tenant>} value
 * @return {!proto.external.GetTenantsResponse} returns this
 */
proto.external.GetTenantsResponse.prototype.setTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Tenant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Tenant}
 */
proto.external.GetTenantsResponse.prototype.addTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Tenant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantsResponse} returns this
 */
proto.external.GetTenantsResponse.prototype.clearTenantsList = function() {
  return this.setTenantsList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.UpdateTenantRequest.repeatedFields_ = [10];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      companyname: (f = msg.getCompanyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      tradingname: (f = msg.getTradingname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      industry: (f = msg.getIndustry()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      assetid: (f = msg.getAssetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      restore: (f = msg.getRestore()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      contractstartdate: (f = msg.getContractstartdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      contractenddate: (f = msg.getContractenddate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      unitidsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantRequest}
 */
proto.external.UpdateTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantRequest;
  return proto.external.UpdateTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantRequest}
 */
proto.external.UpdateTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCompanyname(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTradingname(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setIndustry(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setAssetid(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setRestore(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setContractstartdate(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setContractenddate(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.addUnitids(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIndustry();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAssetid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRestore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getContractstartdate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContractenddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantContractID = 2;
 * @return {string}
 */
proto.external.UpdateTenantRequest.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue companyName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantRequest.prototype.getCompanyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearCompanyname = function() {
  return this.setCompanyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasCompanyname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue tradingName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantRequest.prototype.getTradingname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setTradingname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearTradingname = function() {
  return this.setTradingname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasTradingname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue industry = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantRequest.prototype.getIndustry = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setIndustry = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearIndustry = function() {
  return this.setIndustry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasIndustry = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue assetID = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantRequest.prototype.getAssetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearAssetid = function() {
  return this.setAssetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasAssetid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue restore = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.external.UpdateTenantRequest.prototype.getRestore = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setRestore = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearRestore = function() {
  return this.setRestore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasRestore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue contractStartDate = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantRequest.prototype.getContractstartdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setContractstartdate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearContractstartdate = function() {
  return this.setContractstartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasContractstartdate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue contractEndDate = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantRequest.prototype.getContractenddate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setContractenddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearContractenddate = function() {
  return this.setContractenddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantRequest.prototype.hasContractenddate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string unitIDs = 10;
 * @return {!Array<string>}
 */
proto.external.UpdateTenantRequest.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.setUnitidsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.addUnitids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.UpdateTenantRequest} returns this
 */
proto.external.UpdateTenantRequest.prototype.clearUnitidsList = function() {
  return this.setUnitidsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteTenantRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteTenantRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteTenantRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteTenantRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteTenantRequest}
 */
proto.external.DeleteTenantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteTenantRequest;
  return proto.external.DeleteTenantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteTenantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteTenantRequest}
 */
proto.external.DeleteTenantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteTenantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteTenantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteTenantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteTenantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteTenantRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteTenantRequest} returns this
 */
proto.external.DeleteTenantRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTurnoverRentCurrentRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTurnoverRentCurrentRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTurnoverRentCurrentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTurnoverRentCurrentRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractclauseid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTurnoverRentCurrentRequest}
 */
proto.external.GetTurnoverRentCurrentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTurnoverRentCurrentRequest;
  return proto.external.GetTurnoverRentCurrentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTurnoverRentCurrentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTurnoverRentCurrentRequest}
 */
proto.external.GetTurnoverRentCurrentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractclauseid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTurnoverRentCurrentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTurnoverRentCurrentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTurnoverRentCurrentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTurnoverRentCurrentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractclauseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string contractClauseID = 1;
 * @return {string}
 */
proto.external.GetTurnoverRentCurrentRequest.prototype.getContractclauseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTurnoverRentCurrentRequest} returns this
 */
proto.external.GetTurnoverRentCurrentRequest.prototype.setContractclauseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool contracted = 2;
 * @return {boolean}
 */
proto.external.GetTurnoverRentCurrentRequest.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetTurnoverRentCurrentRequest} returns this
 */
proto.external.GetTurnoverRentCurrentRequest.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTurnoverRentCurrentResponse.repeatedFields_ = [6];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTurnoverRentCurrentResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTurnoverRentCurrentResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTurnoverRentCurrentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTurnoverRentCurrentResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      startdate: jspb.Message.getFieldWithDefault(msg, 1, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 2, ''),
      rentpercentage: jspb.Message.getFieldWithDefault(msg, 3, 0),
      yeargrowthpercentage: jspb.Message.getFieldWithDefault(msg, 4, 0),
      rentcurrentList: jspb.Message.toObjectList(msg.getRentcurrentList(),
        proto.external.TurnoverRentCurrent.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTurnoverRentCurrentResponse}
 */
proto.external.GetTurnoverRentCurrentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTurnoverRentCurrentResponse;
  return proto.external.GetTurnoverRentCurrentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTurnoverRentCurrentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTurnoverRentCurrentResponse}
 */
proto.external.GetTurnoverRentCurrentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setRentpercentage(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setYeargrowthpercentage(value);
        break;
      case 6:
        var value = new proto.external.TurnoverRentCurrent;
        reader.readMessage(value, proto.external.TurnoverRentCurrent.deserializeBinaryFromReader);
        msg.addRentcurrent(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTurnoverRentCurrentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTurnoverRentCurrentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTurnoverRentCurrentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRentpercentage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getYeargrowthpercentage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRentcurrentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.external.TurnoverRentCurrent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string startDate = 1;
 * @return {string}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTurnoverRentCurrentResponse} returns this
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string endDate = 2;
 * @return {string}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTurnoverRentCurrentResponse} returns this
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 rentPercentage = 3;
 * @return {number}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.getRentpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTurnoverRentCurrentResponse} returns this
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.setRentpercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 yearGrowthPercentage = 4;
 * @return {number}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.getYeargrowthpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTurnoverRentCurrentResponse} returns this
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.setYeargrowthpercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated TurnoverRentCurrent rentCurrent = 6;
 * @return {!Array<!proto.external.TurnoverRentCurrent>}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.getRentcurrentList = function() {
  return /** @type{!Array<!proto.external.TurnoverRentCurrent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TurnoverRentCurrent, 6));
};


/**
 * @param {!Array<!proto.external.TurnoverRentCurrent>} value
 * @return {!proto.external.GetTurnoverRentCurrentResponse} returns this
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.setRentcurrentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.external.TurnoverRentCurrent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TurnoverRentCurrent}
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.addRentcurrent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.external.TurnoverRentCurrent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTurnoverRentCurrentResponse} returns this
 */
proto.external.GetTurnoverRentCurrentResponse.prototype.clearRentcurrentList = function() {
  return this.setRentcurrentList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TurnoverRentCurrent.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TurnoverRentCurrent.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TurnoverRentCurrent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TurnoverRentCurrent.toObject = function(includeInstance, msg) {
    var f, obj = {
      month: jspb.Message.getFieldWithDefault(msg, 1, 0),
      profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      rent: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TurnoverRentCurrent}
 */
proto.external.TurnoverRentCurrent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TurnoverRentCurrent;
  return proto.external.TurnoverRentCurrent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TurnoverRentCurrent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TurnoverRentCurrent}
 */
proto.external.TurnoverRentCurrent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setProfit(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setRent(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TurnoverRentCurrent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TurnoverRentCurrent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TurnoverRentCurrent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverRentCurrent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRent();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.external.TurnoverRentCurrent.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverRentCurrent} returns this
 */
proto.external.TurnoverRentCurrent.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double profit = 2;
 * @return {number}
 */
proto.external.TurnoverRentCurrent.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverRentCurrent} returns this
 */
proto.external.TurnoverRentCurrent.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double rent = 3;
 * @return {number}
 */
proto.external.TurnoverRentCurrent.prototype.getRent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverRentCurrent} returns this
 */
proto.external.TurnoverRentCurrent.prototype.setRent = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCashFlowCurrentRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCashFlowCurrentRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCashFlowCurrentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCashFlowCurrentRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCashFlowCurrentRequest}
 */
proto.external.GetCashFlowCurrentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCashFlowCurrentRequest;
  return proto.external.GetCashFlowCurrentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCashFlowCurrentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCashFlowCurrentRequest}
 */
proto.external.GetCashFlowCurrentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCashFlowCurrentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCashFlowCurrentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCashFlowCurrentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowCurrentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string tenantContractID = 1;
 * @return {string}
 */
proto.external.GetCashFlowCurrentRequest.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCashFlowCurrentRequest} returns this
 */
proto.external.GetCashFlowCurrentRequest.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool contracted = 2;
 * @return {boolean}
 */
proto.external.GetCashFlowCurrentRequest.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetCashFlowCurrentRequest} returns this
 */
proto.external.GetCashFlowCurrentRequest.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetCashFlowCurrentResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCashFlowCurrentResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCashFlowCurrentResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCashFlowCurrentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCashFlowCurrentResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      currentList: jspb.Message.toObjectList(msg.getCurrentList(),
        proto.external.CashFlowItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCashFlowCurrentResponse}
 */
proto.external.GetCashFlowCurrentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCashFlowCurrentResponse;
  return proto.external.GetCashFlowCurrentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCashFlowCurrentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCashFlowCurrentResponse}
 */
proto.external.GetCashFlowCurrentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CashFlowItem;
        reader.readMessage(value, proto.external.CashFlowItem.deserializeBinaryFromReader);
        msg.addCurrent(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCashFlowCurrentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCashFlowCurrentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCashFlowCurrentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCashFlowCurrentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlowItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlowItem current = 1;
 * @return {!Array<!proto.external.CashFlowItem>}
 */
proto.external.GetCashFlowCurrentResponse.prototype.getCurrentList = function() {
  return /** @type{!Array<!proto.external.CashFlowItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowItem, 1));
};


/**
 * @param {!Array<!proto.external.CashFlowItem>} value
 * @return {!proto.external.GetCashFlowCurrentResponse} returns this
 */
proto.external.GetCashFlowCurrentResponse.prototype.setCurrentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlowItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowItem}
 */
proto.external.GetCashFlowCurrentResponse.prototype.addCurrent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlowItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCashFlowCurrentResponse} returns this
 */
proto.external.GetCashFlowCurrentResponse.prototype.clearCurrentList = function() {
  return this.setCurrentList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CashFlowItem.repeatedFields_ = [4];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CashFlowItem.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CashFlowItem.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CashFlowItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CashFlowItem.toObject = function(includeInstance, msg) {
    var f, obj = {
      metric: jspb.Message.getFieldWithDefault(msg, 1, ''),
      total: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      error: jspb.Message.getFieldWithDefault(msg, 3, ''),
      dataList: jspb.Message.toObjectList(msg.getDataList(),
        proto.external.CashFlowItemData.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowItem}
 */
proto.external.CashFlowItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowItem;
  return proto.external.CashFlowItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowItem}
 */
proto.external.CashFlowItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMetric(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setTotal(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setError(value);
        break;
      case 4:
        var value = new proto.external.CashFlowItemData;
        reader.readMessage(value, proto.external.CashFlowItemData.deserializeBinaryFromReader);
        msg.addData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetric();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.external.CashFlowItemData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string metric = 1;
 * @return {string}
 */
proto.external.CashFlowItem.prototype.getMetric = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CashFlowItem} returns this
 */
proto.external.CashFlowItem.prototype.setMetric = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double total = 2;
 * @return {number}
 */
proto.external.CashFlowItem.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowItem} returns this
 */
proto.external.CashFlowItem.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string error = 3;
 * @return {string}
 */
proto.external.CashFlowItem.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CashFlowItem} returns this
 */
proto.external.CashFlowItem.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CashFlowItemData data = 4;
 * @return {!Array<!proto.external.CashFlowItemData>}
 */
proto.external.CashFlowItem.prototype.getDataList = function() {
  return /** @type{!Array<!proto.external.CashFlowItemData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowItemData, 4));
};


/**
 * @param {!Array<!proto.external.CashFlowItemData>} value
 * @return {!proto.external.CashFlowItem} returns this
 */
proto.external.CashFlowItem.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.external.CashFlowItemData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowItemData}
 */
proto.external.CashFlowItem.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.external.CashFlowItemData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CashFlowItem} returns this
 */
proto.external.CashFlowItem.prototype.clearDataList = function() {
  return this.setDataList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CashFlowItemData.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CashFlowItemData.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CashFlowItemData} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CashFlowItemData.toObject = function(includeInstance, msg) {
    var f, obj = {
      year: jspb.Message.getFieldWithDefault(msg, 1, 0),
      value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      warning: jspb.Message.getFieldWithDefault(msg, 3, ''),
      month: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowItemData}
 */
proto.external.CashFlowItemData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowItemData;
  return proto.external.CashFlowItemData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowItemData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowItemData}
 */
proto.external.CashFlowItemData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setYear(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setValue(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setWarning(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowItemData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowItemData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowItemData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowItemData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getWarning();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.external.CashFlowItemData.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowItemData} returns this
 */
proto.external.CashFlowItemData.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.external.CashFlowItemData.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowItemData} returns this
 */
proto.external.CashFlowItemData.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string warning = 3;
 * @return {string}
 */
proto.external.CashFlowItemData.prototype.getWarning = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CashFlowItemData} returns this
 */
proto.external.CashFlowItemData.prototype.setWarning = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 month = 4;
 * @return {number}
 */
proto.external.CashFlowItemData.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowItemData} returns this
 */
proto.external.CashFlowItemData.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CreateClausesRequest.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateClausesRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateClausesRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateClausesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateClausesRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      clausesList: jspb.Message.toObjectList(msg.getClausesList(),
        proto.external.CreateClauseRequest.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateClausesRequest}
 */
proto.external.CreateClausesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateClausesRequest;
  return proto.external.CreateClausesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateClausesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateClausesRequest}
 */
proto.external.CreateClausesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CreateClauseRequest;
        reader.readMessage(value, proto.external.CreateClauseRequest.deserializeBinaryFromReader);
        msg.addClauses(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateClausesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateClausesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateClausesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateClausesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClausesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CreateClauseRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateClauseRequest clauses = 1;
 * @return {!Array<!proto.external.CreateClauseRequest>}
 */
proto.external.CreateClausesRequest.prototype.getClausesList = function() {
  return /** @type{!Array<!proto.external.CreateClauseRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CreateClauseRequest, 1));
};


/**
 * @param {!Array<!proto.external.CreateClauseRequest>} value
 * @return {!proto.external.CreateClausesRequest} returns this
 */
proto.external.CreateClausesRequest.prototype.setClausesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CreateClauseRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CreateClauseRequest}
 */
proto.external.CreateClausesRequest.prototype.addClauses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CreateClauseRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CreateClausesRequest} returns this
 */
proto.external.CreateClausesRequest.prototype.clearClausesList = function() {
  return this.setClausesList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateClauseRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateClauseRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateClauseRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateClauseRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      type: jspb.Message.getFieldWithDefault(msg, 3, ''),
      typedata: jspb.Message.getFieldWithDefault(msg, 4, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateClauseRequest}
 */
proto.external.CreateClauseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateClauseRequest;
  return proto.external.CreateClauseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateClauseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateClauseRequest}
 */
proto.external.CreateClauseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateClauseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateClauseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateClauseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateClauseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string tenantContractID = 1;
 * @return {string}
 */
proto.external.CreateClauseRequest.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateClauseRequest} returns this
 */
proto.external.CreateClauseRequest.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool contracted = 2;
 * @return {boolean}
 */
proto.external.CreateClauseRequest.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.CreateClauseRequest} returns this
 */
proto.external.CreateClauseRequest.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.external.CreateClauseRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateClauseRequest} returns this
 */
proto.external.CreateClauseRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string typeData = 4;
 * @return {string}
 */
proto.external.CreateClauseRequest.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateClauseRequest} returns this
 */
proto.external.CreateClauseRequest.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetClauseRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetClauseRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetClauseRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetClauseRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetClauseRequest}
 */
proto.external.GetClauseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetClauseRequest;
  return proto.external.GetClauseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetClauseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetClauseRequest}
 */
proto.external.GetClauseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetClauseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetClauseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetClauseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetClauseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetClauseRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetClauseRequest} returns this
 */
proto.external.GetClauseRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool contracted = 2;
 * @return {boolean}
 */
proto.external.GetClauseRequest.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetClauseRequest} returns this
 */
proto.external.GetClauseRequest.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetClausesByContractRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetClausesByContractRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetClausesByContractRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetClausesByContractRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetClausesByContractRequest}
 */
proto.external.GetClausesByContractRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetClausesByContractRequest;
  return proto.external.GetClausesByContractRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetClausesByContractRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetClausesByContractRequest}
 */
proto.external.GetClausesByContractRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractid(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetClausesByContractRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetClausesByContractRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetClausesByContractRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetClausesByContractRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string contractID = 1;
 * @return {string}
 */
proto.external.GetClausesByContractRequest.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetClausesByContractRequest} returns this
 */
proto.external.GetClausesByContractRequest.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool contracted = 2;
 * @return {boolean}
 */
proto.external.GetClausesByContractRequest.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetClausesByContractRequest} returns this
 */
proto.external.GetClausesByContractRequest.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetClausesByContractResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetClausesByContractResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetClausesByContractResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetClausesByContractResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetClausesByContractResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      clausesList: jspb.Message.toObjectList(msg.getClausesList(),
        proto.external.Clause.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetClausesByContractResponse}
 */
proto.external.GetClausesByContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetClausesByContractResponse;
  return proto.external.GetClausesByContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetClausesByContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetClausesByContractResponse}
 */
proto.external.GetClausesByContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.Clause;
        reader.readMessage(value, proto.external.Clause.deserializeBinaryFromReader);
        msg.addClauses(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetClausesByContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetClausesByContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetClausesByContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetClausesByContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClausesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.Clause.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Clause clauses = 1;
 * @return {!Array<!proto.external.Clause>}
 */
proto.external.GetClausesByContractResponse.prototype.getClausesList = function() {
  return /** @type{!Array<!proto.external.Clause>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Clause, 1));
};


/**
 * @param {!Array<!proto.external.Clause>} value
 * @return {!proto.external.GetClausesByContractResponse} returns this
 */
proto.external.GetClausesByContractResponse.prototype.setClausesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Clause=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Clause}
 */
proto.external.GetClausesByContractResponse.prototype.addClauses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Clause, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetClausesByContractResponse} returns this
 */
proto.external.GetClausesByContractResponse.prototype.clearClausesList = function() {
  return this.setClausesList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetClauseResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetClauseResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetClauseResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetClauseResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      type: jspb.Message.getFieldWithDefault(msg, 4, ''),
      typedata: jspb.Message.getFieldWithDefault(msg, 5, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetClauseResponse}
 */
proto.external.GetClauseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetClauseResponse;
  return proto.external.GetClauseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetClauseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetClauseResponse}
 */
proto.external.GetClauseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetClauseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetClauseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetClauseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetClauseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetClauseResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetClauseResponse} returns this
 */
proto.external.GetClauseResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantContractID = 2;
 * @return {string}
 */
proto.external.GetClauseResponse.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetClauseResponse} returns this
 */
proto.external.GetClauseResponse.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool contracted = 3;
 * @return {boolean}
 */
proto.external.GetClauseResponse.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetClauseResponse} returns this
 */
proto.external.GetClauseResponse.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.external.GetClauseResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetClauseResponse} returns this
 */
proto.external.GetClauseResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string typeData = 5;
 * @return {string}
 */
proto.external.GetClauseResponse.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetClauseResponse} returns this
 */
proto.external.GetClauseResponse.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateClauseResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateClauseResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateClauseResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateClauseResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractclauseid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateClauseResponse}
 */
proto.external.CreateClauseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateClauseResponse;
  return proto.external.CreateClauseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateClauseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateClauseResponse}
 */
proto.external.CreateClauseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractclauseid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateClauseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateClauseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateClauseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateClauseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractclauseid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contractClauseID = 1;
 * @return {string}
 */
proto.external.CreateClauseResponse.prototype.getContractclauseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateClauseResponse} returns this
 */
proto.external.CreateClauseResponse.prototype.setContractclauseid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateClauseRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateClauseRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateClauseRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateClauseRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantcontractid: (f = msg.getTenantcontractid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      typedata: jspb.Message.getFieldWithDefault(msg, 4, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateClauseRequest}
 */
proto.external.UpdateClauseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateClauseRequest;
  return proto.external.UpdateClauseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateClauseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateClauseRequest}
 */
proto.external.UpdateClauseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTenantcontractid(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateClauseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateClauseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateClauseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateClauseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantcontractid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateClauseRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateClauseRequest} returns this
 */
proto.external.UpdateClauseRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue tenantContractID = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateClauseRequest.prototype.getTenantcontractid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateClauseRequest} returns this
 */
proto.external.UpdateClauseRequest.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateClauseRequest} returns this
 */
proto.external.UpdateClauseRequest.prototype.clearTenantcontractid = function() {
  return this.setTenantcontractid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateClauseRequest.prototype.hasTenantcontractid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue type = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateClauseRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateClauseRequest} returns this
 */
proto.external.UpdateClauseRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateClauseRequest} returns this
 */
proto.external.UpdateClauseRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateClauseRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string typeData = 4;
 * @return {string}
 */
proto.external.UpdateClauseRequest.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateClauseRequest} returns this
 */
proto.external.UpdateClauseRequest.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteClauseRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteClauseRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteClauseRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteClauseRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteClauseRequest}
 */
proto.external.DeleteClauseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteClauseRequest;
  return proto.external.DeleteClauseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteClauseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteClauseRequest}
 */
proto.external.DeleteClauseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteClauseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteClauseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteClauseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteClauseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteClauseRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteClauseRequest} returns this
 */
proto.external.DeleteClauseRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.Clause.prototype.toObject = function(opt_includeInstance) {
    return proto.external.Clause.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.Clause} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.Clause.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contracted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      type: jspb.Message.getFieldWithDefault(msg, 4, ''),
      typedata: jspb.Message.getFieldWithDefault(msg, 5, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Clause}
 */
proto.external.Clause.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Clause;
  return proto.external.Clause.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Clause} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Clause}
 */
proto.external.Clause.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setContracted(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Clause.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Clause.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Clause} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Clause.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContracted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.Clause.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Clause} returns this
 */
proto.external.Clause.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool contracted = 2;
 * @return {boolean}
 */
proto.external.Clause.prototype.getContracted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.Clause} returns this
 */
proto.external.Clause.prototype.setContracted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string tenantContractID = 3;
 * @return {string}
 */
proto.external.Clause.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Clause} returns this
 */
proto.external.Clause.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.external.Clause.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Clause} returns this
 */
proto.external.Clause.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string typeData = 5;
 * @return {string}
 */
proto.external.Clause.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Clause} returns this
 */
proto.external.Clause.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTotalRentByAssetAndYearRequest.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTotalRentByAssetAndYearRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTotalRentByAssetAndYearRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTotalRentByAssetAndYearRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTotalRentByAssetAndYearRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      year: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTotalRentByAssetAndYearRequest}
 */
proto.external.GetTotalRentByAssetAndYearRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTotalRentByAssetAndYearRequest;
  return proto.external.GetTotalRentByAssetAndYearRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTotalRentByAssetAndYearRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTotalRentByAssetAndYearRequest}
 */
proto.external.GetTotalRentByAssetAndYearRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addAssetid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setYear(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTotalRentByAssetAndYearRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTotalRentByAssetAndYearRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTotalRentByAssetAndYearRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetidList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated string assetID = 1;
 * @return {!Array<string>}
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.getAssetidList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetTotalRentByAssetAndYearRequest} returns this
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.setAssetidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetTotalRentByAssetAndYearRequest} returns this
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.addAssetid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTotalRentByAssetAndYearRequest} returns this
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.clearAssetidList = function() {
  return this.setAssetidList([]);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTotalRentByAssetAndYearRequest} returns this
 */
proto.external.GetTotalRentByAssetAndYearRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.RentCalculationResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.RentCalculationResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.RentCalculationResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.RentCalculationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.RentCalculationResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      calculationList: jspb.Message.toObjectList(msg.getCalculationList(),
        proto.external.RentCalculationResponse.RentCalculation.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.RentCalculationResponse}
 */
proto.external.RentCalculationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.RentCalculationResponse;
  return proto.external.RentCalculationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.RentCalculationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.RentCalculationResponse}
 */
proto.external.RentCalculationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.RentCalculationResponse.RentCalculation;
        reader.readMessage(value, proto.external.RentCalculationResponse.RentCalculation.deserializeBinaryFromReader);
        msg.addCalculation(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.RentCalculationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.RentCalculationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.RentCalculationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.RentCalculationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalculationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.RentCalculationResponse.RentCalculation.serializeBinaryToWriter
    );
  }
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.RentCalculationResponse.RentCalculation.prototype.toObject = function(opt_includeInstance) {
    return proto.external.RentCalculationResponse.RentCalculation.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.RentCalculationResponse.RentCalculation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.RentCalculationResponse.RentCalculation.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      rent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.RentCalculationResponse.RentCalculation}
 */
proto.external.RentCalculationResponse.RentCalculation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.RentCalculationResponse.RentCalculation;
  return proto.external.RentCalculationResponse.RentCalculation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.RentCalculationResponse.RentCalculation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.RentCalculationResponse.RentCalculation}
 */
proto.external.RentCalculationResponse.RentCalculation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setRent(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.RentCalculationResponse.RentCalculation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.RentCalculationResponse.RentCalculation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.RentCalculationResponse.RentCalculation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.RentCalculationResponse.RentCalculation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.RentCalculationResponse.RentCalculation.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.RentCalculationResponse.RentCalculation} returns this
 */
proto.external.RentCalculationResponse.RentCalculation.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double rent = 2;
 * @return {number}
 */
proto.external.RentCalculationResponse.RentCalculation.prototype.getRent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.RentCalculationResponse.RentCalculation} returns this
 */
proto.external.RentCalculationResponse.RentCalculation.prototype.setRent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated RentCalculation calculation = 1;
 * @return {!Array<!proto.external.RentCalculationResponse.RentCalculation>}
 */
proto.external.RentCalculationResponse.prototype.getCalculationList = function() {
  return /** @type{!Array<!proto.external.RentCalculationResponse.RentCalculation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.RentCalculationResponse.RentCalculation, 1));
};


/**
 * @param {!Array<!proto.external.RentCalculationResponse.RentCalculation>} value
 * @return {!proto.external.RentCalculationResponse} returns this
 */
proto.external.RentCalculationResponse.prototype.setCalculationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.RentCalculationResponse.RentCalculation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.RentCalculationResponse.RentCalculation}
 */
proto.external.RentCalculationResponse.prototype.addCalculation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.RentCalculationResponse.RentCalculation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.RentCalculationResponse} returns this
 */
proto.external.RentCalculationResponse.prototype.clearCalculationList = function() {
  return this.setCalculationList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetStrategyByIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetStrategyByIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetStrategyByIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetStrategyByIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetStrategyByIDRequest}
 */
proto.external.GetStrategyByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetStrategyByIDRequest;
  return proto.external.GetStrategyByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetStrategyByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetStrategyByIDRequest}
 */
proto.external.GetStrategyByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetStrategyByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetStrategyByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetStrategyByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetStrategyByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetStrategyByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyByIDRequest} returns this
 */
proto.external.GetStrategyByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetStrategyByContractIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetStrategyByContractIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetStrategyByContractIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetStrategyByContractIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetStrategyByContractIDRequest}
 */
proto.external.GetStrategyByContractIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetStrategyByContractIDRequest;
  return proto.external.GetStrategyByContractIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetStrategyByContractIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetStrategyByContractIDRequest}
 */
proto.external.GetStrategyByContractIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetStrategyByContractIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetStrategyByContractIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetStrategyByContractIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetStrategyByContractIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetStrategyByContractIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyByContractIDRequest} returns this
 */
proto.external.GetStrategyByContractIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetStrategyResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetStrategyResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetStrategyResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetStrategyResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetId: jspb.Message.getFieldWithDefault(msg, 2, ''),
      organizationId: jspb.Message.getFieldWithDefault(msg, 3, ''),
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      strategytext: jspb.Message.getFieldWithDefault(msg, 5, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetStrategyResponse}
 */
proto.external.GetStrategyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetStrategyResponse;
  return proto.external.GetStrategyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetStrategyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetStrategyResponse}
 */
proto.external.GetStrategyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setStrategytext(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetStrategyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetStrategyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetStrategyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetStrategyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStrategytext();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetStrategyResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyResponse} returns this
 */
proto.external.GetStrategyResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_id = 2;
 * @return {string}
 */
proto.external.GetStrategyResponse.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyResponse} returns this
 */
proto.external.GetStrategyResponse.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization_id = 3;
 * @return {string}
 */
proto.external.GetStrategyResponse.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyResponse} returns this
 */
proto.external.GetStrategyResponse.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenantContractID = 4;
 * @return {string}
 */
proto.external.GetStrategyResponse.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyResponse} returns this
 */
proto.external.GetStrategyResponse.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string strategyText = 5;
 * @return {string}
 */
proto.external.GetStrategyResponse.prototype.getStrategytext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetStrategyResponse} returns this
 */
proto.external.GetStrategyResponse.prototype.setStrategytext = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateStrategyRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateStrategyRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateStrategyRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateStrategyRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      strategytext: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateStrategyRequest}
 */
proto.external.UpdateStrategyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateStrategyRequest;
  return proto.external.UpdateStrategyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateStrategyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateStrategyRequest}
 */
proto.external.UpdateStrategyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setStrategytext(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateStrategyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateStrategyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateStrategyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateStrategyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStrategytext();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateStrategyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateStrategyRequest} returns this
 */
proto.external.UpdateStrategyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string strategyText = 2;
 * @return {string}
 */
proto.external.UpdateStrategyRequest.prototype.getStrategytext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateStrategyRequest} returns this
 */
proto.external.UpdateStrategyRequest.prototype.setStrategytext = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTurnoverRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTurnoverRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTurnoverRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTurnoverRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      yearmonth: jspb.Message.getFieldWithDefault(msg, 2, ''),
      turnover: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTurnoverRequest}
 */
proto.external.CreateTurnoverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTurnoverRequest;
  return proto.external.CreateTurnoverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTurnoverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTurnoverRequest}
 */
proto.external.CreateTurnoverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setYearmonth(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTurnover(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTurnoverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTurnoverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTurnoverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTurnoverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYearmonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTurnover();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string tenantContractID = 1;
 * @return {string}
 */
proto.external.CreateTurnoverRequest.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTurnoverRequest} returns this
 */
proto.external.CreateTurnoverRequest.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string yearMonth = 2;
 * @return {string}
 */
proto.external.CreateTurnoverRequest.prototype.getYearmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTurnoverRequest} returns this
 */
proto.external.CreateTurnoverRequest.prototype.setYearmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float turnover = 3;
 * @return {number}
 */
proto.external.CreateTurnoverRequest.prototype.getTurnover = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateTurnoverRequest} returns this
 */
proto.external.CreateTurnoverRequest.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTurnoverResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTurnoverResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTurnoverResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTurnoverResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      turnoverid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTurnoverResponse}
 */
proto.external.CreateTurnoverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTurnoverResponse;
  return proto.external.CreateTurnoverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTurnoverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTurnoverResponse}
 */
proto.external.CreateTurnoverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTurnoverid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTurnoverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTurnoverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTurnoverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTurnoverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTurnoverid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string turnoverID = 1;
 * @return {string}
 */
proto.external.CreateTurnoverResponse.prototype.getTurnoverid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTurnoverResponse} returns this
 */
proto.external.CreateTurnoverResponse.prototype.setTurnoverid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTurnoversRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTurnoversRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTurnoversRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTurnoversRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTurnoversRequest}
 */
proto.external.GetTurnoversRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTurnoversRequest;
  return proto.external.GetTurnoversRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTurnoversRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTurnoversRequest}
 */
proto.external.GetTurnoversRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTurnoversRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTurnoversRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTurnoversRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTurnoversRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenantContractID = 1;
 * @return {string}
 */
proto.external.GetTurnoversRequest.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTurnoversRequest} returns this
 */
proto.external.GetTurnoversRequest.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TurnoverMonth.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TurnoverMonth.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TurnoverMonth} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TurnoverMonth.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      turnover: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TurnoverMonth}
 */
proto.external.TurnoverMonth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TurnoverMonth;
  return proto.external.TurnoverMonth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TurnoverMonth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TurnoverMonth}
 */
proto.external.TurnoverMonth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setTurnover(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TurnoverMonth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TurnoverMonth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TurnoverMonth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverMonth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTurnover();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TurnoverMonth.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TurnoverMonth} returns this
 */
proto.external.TurnoverMonth.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.external.TurnoverMonth.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverMonth} returns this
 */
proto.external.TurnoverMonth.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double turnover = 3;
 * @return {number}
 */
proto.external.TurnoverMonth.prototype.getTurnover = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverMonth} returns this
 */
proto.external.TurnoverMonth.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.TurnoverYearMonth.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TurnoverYearMonth.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TurnoverYearMonth.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TurnoverYearMonth} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TurnoverYearMonth.toObject = function(includeInstance, msg) {
    var f, obj = {
      year: jspb.Message.getFieldWithDefault(msg, 1, 0),
      monthsList: jspb.Message.toObjectList(msg.getMonthsList(),
        proto.external.TurnoverMonth.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TurnoverYearMonth}
 */
proto.external.TurnoverYearMonth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TurnoverYearMonth;
  return proto.external.TurnoverYearMonth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TurnoverYearMonth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TurnoverYearMonth}
 */
proto.external.TurnoverYearMonth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setYear(value);
        break;
      case 2:
        var value = new proto.external.TurnoverMonth;
        reader.readMessage(value, proto.external.TurnoverMonth.deserializeBinaryFromReader);
        msg.addMonths(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TurnoverYearMonth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TurnoverYearMonth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TurnoverYearMonth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverYearMonth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonthsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.TurnoverMonth.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.external.TurnoverYearMonth.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverYearMonth} returns this
 */
proto.external.TurnoverYearMonth.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TurnoverMonth months = 2;
 * @return {!Array<!proto.external.TurnoverMonth>}
 */
proto.external.TurnoverYearMonth.prototype.getMonthsList = function() {
  return /** @type{!Array<!proto.external.TurnoverMonth>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TurnoverMonth, 2));
};


/**
 * @param {!Array<!proto.external.TurnoverMonth>} value
 * @return {!proto.external.TurnoverYearMonth} returns this
 */
proto.external.TurnoverYearMonth.prototype.setMonthsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.TurnoverMonth=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TurnoverMonth}
 */
proto.external.TurnoverYearMonth.prototype.addMonths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.TurnoverMonth, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.TurnoverYearMonth} returns this
 */
proto.external.TurnoverYearMonth.prototype.clearMonthsList = function() {
  return this.setMonthsList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTurnoversResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTurnoversResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTurnoversResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTurnoversResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTurnoversResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      turnoversList: jspb.Message.toObjectList(msg.getTurnoversList(),
        proto.external.TurnoverYearMonth.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTurnoversResponse}
 */
proto.external.GetTurnoversResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTurnoversResponse;
  return proto.external.GetTurnoversResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTurnoversResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTurnoversResponse}
 */
proto.external.GetTurnoversResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.TurnoverYearMonth;
        reader.readMessage(value, proto.external.TurnoverYearMonth.deserializeBinaryFromReader);
        msg.addTurnovers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTurnoversResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTurnoversResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTurnoversResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTurnoversResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTurnoversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TurnoverYearMonth.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TurnoverYearMonth turnovers = 1;
 * @return {!Array<!proto.external.TurnoverYearMonth>}
 */
proto.external.GetTurnoversResponse.prototype.getTurnoversList = function() {
  return /** @type{!Array<!proto.external.TurnoverYearMonth>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TurnoverYearMonth, 1));
};


/**
 * @param {!Array<!proto.external.TurnoverYearMonth>} value
 * @return {!proto.external.GetTurnoversResponse} returns this
 */
proto.external.GetTurnoversResponse.prototype.setTurnoversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TurnoverYearMonth=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TurnoverYearMonth}
 */
proto.external.GetTurnoversResponse.prototype.addTurnovers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TurnoverYearMonth, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTurnoversResponse} returns this
 */
proto.external.GetTurnoversResponse.prototype.clearTurnoversList = function() {
  return this.setTurnoversList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTurnoverRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTurnoverRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTurnoverRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTurnoverRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      yearmonth: (f = msg.getYearmonth()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      turnover: (f = msg.getTurnover()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTurnoverRequest}
 */
proto.external.UpdateTurnoverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTurnoverRequest;
  return proto.external.UpdateTurnoverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTurnoverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTurnoverRequest}
 */
proto.external.UpdateTurnoverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setYearmonth(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.FloatValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
        msg.setTurnover(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTurnoverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTurnoverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTurnoverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTurnoverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYearmonth();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTurnover();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTurnoverRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTurnoverRequest} returns this
 */
proto.external.UpdateTurnoverRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue yearMonth = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTurnoverRequest.prototype.getYearmonth = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTurnoverRequest} returns this
 */
proto.external.UpdateTurnoverRequest.prototype.setYearmonth = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTurnoverRequest} returns this
 */
proto.external.UpdateTurnoverRequest.prototype.clearYearmonth = function() {
  return this.setYearmonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTurnoverRequest.prototype.hasYearmonth = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.FloatValue turnover = 3;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.external.UpdateTurnoverRequest.prototype.getTurnover = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 3));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.external.UpdateTurnoverRequest} returns this
 */
proto.external.UpdateTurnoverRequest.prototype.setTurnover = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTurnoverRequest} returns this
 */
proto.external.UpdateTurnoverRequest.prototype.clearTurnover = function() {
  return this.setTurnover(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTurnoverRequest.prototype.hasTurnover = function() {
  return jspb.Message.getField(this, 3) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteTurnoverRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteTurnoverRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteTurnoverRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteTurnoverRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteTurnoverRequest}
 */
proto.external.DeleteTurnoverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteTurnoverRequest;
  return proto.external.DeleteTurnoverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteTurnoverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteTurnoverRequest}
 */
proto.external.DeleteTurnoverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteTurnoverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteTurnoverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteTurnoverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteTurnoverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteTurnoverRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteTurnoverRequest} returns this
 */
proto.external.DeleteTurnoverRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantOptionRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantOptionRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantOptionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantOptionRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      type: jspb.Message.getFieldWithDefault(msg, 2, ''),
      description: jspb.Message.getFieldWithDefault(msg, 3, ''),
      effectivedate: jspb.Message.getFieldWithDefault(msg, 4, ''),
      noticeperiodstart: jspb.Message.getFieldWithDefault(msg, 5, ''),
      noticeperiodend: jspb.Message.getFieldWithDefault(msg, 6, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantOptionRequest}
 */
proto.external.CreateTenantOptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantOptionRequest;
  return proto.external.CreateTenantOptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantOptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantOptionRequest}
 */
proto.external.CreateTenantOptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setEffectivedate(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setNoticeperiodstart(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setNoticeperiodend(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantOptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantOptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantOptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantOptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEffectivedate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNoticeperiodstart();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoticeperiodend();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string contractID = 1;
 * @return {string}
 */
proto.external.CreateTenantOptionRequest.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionRequest} returns this
 */
proto.external.CreateTenantOptionRequest.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.external.CreateTenantOptionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionRequest} returns this
 */
proto.external.CreateTenantOptionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.external.CreateTenantOptionRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionRequest} returns this
 */
proto.external.CreateTenantOptionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string effectiveDate = 4;
 * @return {string}
 */
proto.external.CreateTenantOptionRequest.prototype.getEffectivedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionRequest} returns this
 */
proto.external.CreateTenantOptionRequest.prototype.setEffectivedate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string noticePeriodStart = 5;
 * @return {string}
 */
proto.external.CreateTenantOptionRequest.prototype.getNoticeperiodstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionRequest} returns this
 */
proto.external.CreateTenantOptionRequest.prototype.setNoticeperiodstart = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string noticePeriodEnd = 6;
 * @return {string}
 */
proto.external.CreateTenantOptionRequest.prototype.getNoticeperiodend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionRequest} returns this
 */
proto.external.CreateTenantOptionRequest.prototype.setNoticeperiodend = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantOption.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantOption.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantOption.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      contractid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      type: jspb.Message.getFieldWithDefault(msg, 3, ''),
      description: jspb.Message.getFieldWithDefault(msg, 4, ''),
      effectivedate: jspb.Message.getFieldWithDefault(msg, 5, ''),
      noticeperiodstart: jspb.Message.getFieldWithDefault(msg, 6, ''),
      noticeperiodend: jspb.Message.getFieldWithDefault(msg, 7, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantOption}
 */
proto.external.TenantOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantOption;
  return proto.external.TenantOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantOption}
 */
proto.external.TenantOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setEffectivedate(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setNoticeperiodstart(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setNoticeperiodend(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEffectivedate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoticeperiodstart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNoticeperiodend();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contractID = 2;
 * @return {string}
 */
proto.external.TenantOption.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.external.TenantOption.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.external.TenantOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string effectiveDate = 5;
 * @return {string}
 */
proto.external.TenantOption.prototype.getEffectivedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setEffectivedate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string noticePeriodStart = 6;
 * @return {string}
 */
proto.external.TenantOption.prototype.getNoticeperiodstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setNoticeperiodstart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string noticePeriodEnd = 7;
 * @return {string}
 */
proto.external.TenantOption.prototype.getNoticeperiodend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantOption} returns this
 */
proto.external.TenantOption.prototype.setNoticeperiodend = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantOptionResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantOptionResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantOptionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantOptionResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantOptionResponse}
 */
proto.external.CreateTenantOptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantOptionResponse;
  return proto.external.CreateTenantOptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantOptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantOptionResponse}
 */
proto.external.CreateTenantOptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantOptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantOptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantOptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantOptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateTenantOptionResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantOptionResponse} returns this
 */
proto.external.CreateTenantOptionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantOptionsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantOptionsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantOptionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantOptionsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantOptionsRequest}
 */
proto.external.GetTenantOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantOptionsRequest;
  return proto.external.GetTenantOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantOptionsRequest}
 */
proto.external.GetTenantOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contractID = 1;
 * @return {string}
 */
proto.external.GetTenantOptionsRequest.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantOptionsRequest} returns this
 */
proto.external.GetTenantOptionsRequest.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantOptionsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantOptionsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantOptionsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantOptionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantOptionsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantoptionsList: jspb.Message.toObjectList(msg.getTenantoptionsList(),
        proto.external.TenantOption.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantOptionsResponse}
 */
proto.external.GetTenantOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantOptionsResponse;
  return proto.external.GetTenantOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantOptionsResponse}
 */
proto.external.GetTenantOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.TenantOption;
        reader.readMessage(value, proto.external.TenantOption.deserializeBinaryFromReader);
        msg.addTenantoptions(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantoptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TenantOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TenantOption tenantOptions = 1;
 * @return {!Array<!proto.external.TenantOption>}
 */
proto.external.GetTenantOptionsResponse.prototype.getTenantoptionsList = function() {
  return /** @type{!Array<!proto.external.TenantOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TenantOption, 1));
};


/**
 * @param {!Array<!proto.external.TenantOption>} value
 * @return {!proto.external.GetTenantOptionsResponse} returns this
 */
proto.external.GetTenantOptionsResponse.prototype.setTenantoptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TenantOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TenantOption}
 */
proto.external.GetTenantOptionsResponse.prototype.addTenantoptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TenantOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantOptionsResponse} returns this
 */
proto.external.GetTenantOptionsResponse.prototype.clearTenantoptionsList = function() {
  return this.setTenantoptionsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantOptionsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantOptionsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantOptionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantOptionsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      effectivedate: (f = msg.getEffectivedate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      noticeperiodstart: (f = msg.getNoticeperiodstart()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      noticeperiodend: (f = msg.getNoticeperiodend()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantOptionsRequest}
 */
proto.external.UpdateTenantOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantOptionsRequest;
  return proto.external.UpdateTenantOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantOptionsRequest}
 */
proto.external.UpdateTenantOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setType(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEffectivedate(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setNoticeperiodstart(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setNoticeperiodend(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEffectivedate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNoticeperiodstart();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNoticeperiodend();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantOptionsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue type = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantOptionsRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantOptionsRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantOptionsRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantOptionsRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue effectiveDate = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantOptionsRequest.prototype.getEffectivedate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.setEffectivedate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.clearEffectivedate = function() {
  return this.setEffectivedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantOptionsRequest.prototype.hasEffectivedate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue noticePeriodStart = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantOptionsRequest.prototype.getNoticeperiodstart = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.setNoticeperiodstart = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.clearNoticeperiodstart = function() {
  return this.setNoticeperiodstart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantOptionsRequest.prototype.hasNoticeperiodstart = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue noticePeriodEnd = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantOptionsRequest.prototype.getNoticeperiodend = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.setNoticeperiodend = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantOptionsRequest} returns this
 */
proto.external.UpdateTenantOptionsRequest.prototype.clearNoticeperiodend = function() {
  return this.setNoticeperiodend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantOptionsRequest.prototype.hasNoticeperiodend = function() {
  return jspb.Message.getField(this, 6) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteTenantOptionsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteTenantOptionsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteTenantOptionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteTenantOptionsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteTenantOptionsRequest}
 */
proto.external.DeleteTenantOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteTenantOptionsRequest;
  return proto.external.DeleteTenantOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteTenantOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteTenantOptionsRequest}
 */
proto.external.DeleteTenantOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteTenantOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteTenantOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteTenantOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteTenantOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteTenantOptionsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteTenantOptionsRequest} returns this
 */
proto.external.DeleteTenantOptionsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantDetailsByIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantDetailsByIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantDetailsByIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantDetailsByIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantDetailsByIDRequest}
 */
proto.external.GetTenantDetailsByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantDetailsByIDRequest;
  return proto.external.GetTenantDetailsByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantDetailsByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantDetailsByIDRequest}
 */
proto.external.GetTenantDetailsByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantDetailsByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantDetailsByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantDetailsByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantDetailsByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantDetailsByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantDetailsByIDRequest} returns this
 */
proto.external.GetTenantDetailsByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantDetailsByContractIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantDetailsByContractIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantDetailsByContractIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantDetailsByContractIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantDetailsByContractIDRequest}
 */
proto.external.GetTenantDetailsByContractIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantDetailsByContractIDRequest;
  return proto.external.GetTenantDetailsByContractIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantDetailsByContractIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantDetailsByContractIDRequest}
 */
proto.external.GetTenantDetailsByContractIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantDetailsByContractIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantDetailsByContractIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantDetailsByContractIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantDetailsByContractIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contractID = 1;
 * @return {string}
 */
proto.external.GetTenantDetailsByContractIDRequest.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantDetailsByContractIDRequest} returns this
 */
proto.external.GetTenantDetailsByContractIDRequest.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.TenantDetails.repeatedFields_ = [14];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantDetails.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantDetails.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantDetails.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantcontractid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      companyname: jspb.Message.getFieldWithDefault(msg, 3, ''),
      tradingname: jspb.Message.getFieldWithDefault(msg, 4, ''),
      industry: jspb.Message.getFieldWithDefault(msg, 5, ''),
      headofficeaddress: jspb.Message.getFieldWithDefault(msg, 6, ''),
      notes: jspb.Message.getFieldWithDefault(msg, 7, ''),
      maincontact: jspb.Message.getFieldWithDefault(msg, 8, ''),
      position: jspb.Message.getFieldWithDefault(msg, 9, ''),
      email: jspb.Message.getFieldWithDefault(msg, 10, ''),
      phone: jspb.Message.getFieldWithDefault(msg, 11, ''),
      contractstartdate: jspb.Message.getFieldWithDefault(msg, 12, ''),
      contractenddate: jspb.Message.getFieldWithDefault(msg, 13, ''),
      unitidsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantDetails}
 */
proto.external.TenantDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantDetails;
  return proto.external.TenantDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantDetails}
 */
proto.external.TenantDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcontractid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTradingname(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setIndustry(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setHeadofficeaddress(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setNotes(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setMaincontact(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setPosition(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractstartdate(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractenddate(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.addUnitids(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantcontractid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTradingname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHeadofficeaddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMaincontact();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContractstartdate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getContractenddate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }

  f = message.getUnitidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantContractID = 2;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getTenantcontractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setTenantcontractid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string companyName = 3;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tradingName = 4;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getTradingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setTradingname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string industry = 5;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string headOfficeAddress = 6;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getHeadofficeaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setHeadofficeaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mainContact = 8;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getMaincontact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setMaincontact = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string position = 9;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone = 11;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string contractStartDate = 12;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getContractstartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setContractstartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string contractEndDate = 13;
 * @return {string}
 */
proto.external.TenantDetails.prototype.getContractenddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setContractenddate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string unitIDs = 14;
 * @return {!Array<string>}
 */
proto.external.TenantDetails.prototype.getUnitidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.setUnitidsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.addUnitids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.TenantDetails} returns this
 */
proto.external.TenantDetails.prototype.clearUnitidsList = function() {
  return this.setUnitidsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      companyname: (f = msg.getCompanyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      tradingname: (f = msg.getTradingname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      industry: (f = msg.getIndustry()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      headOfficeAddress: (f = msg.getHeadOfficeAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      notes: (f = msg.getNotes()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      mainContact: (f = msg.getMainContact()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      position: (f = msg.getPosition()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      phone: (f = msg.getPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantDetailsRequest}
 */
proto.external.UpdateTenantDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantDetailsRequest;
  return proto.external.UpdateTenantDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantDetailsRequest}
 */
proto.external.UpdateTenantDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCompanyname(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTradingname(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setIndustry(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setHeadOfficeAddress(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setNotes(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setMainContact(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPosition(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEmail(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIndustry();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHeadOfficeAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMainContact();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue companyName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getCompanyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearCompanyname = function() {
  return this.setCompanyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasCompanyname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue tradingName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getTradingname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setTradingname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearTradingname = function() {
  return this.setTradingname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasTradingname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue industry = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getIndustry = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setIndustry = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearIndustry = function() {
  return this.setIndustry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasIndustry = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue head_office_address = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getHeadOfficeAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setHeadOfficeAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearHeadOfficeAddress = function() {
  return this.setHeadOfficeAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasHeadOfficeAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue notes = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getNotes = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setNotes = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearNotes = function() {
  return this.setNotes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasNotes = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue main_contact = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getMainContact = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setMainContact = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearMainContact = function() {
  return this.setMainContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasMainContact = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue position = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getPosition = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue email = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue phone = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantDetailsRequest.prototype.getPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantDetailsRequest} returns this
 */
proto.external.UpdateTenantDetailsRequest.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantDetailsRequest.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 10) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetIncomeByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetIncomeByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetIncomeByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetIncomeByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetIncomeByAssetIDRequest}
 */
proto.external.GetIncomeByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetIncomeByAssetIDRequest;
  return proto.external.GetIncomeByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetIncomeByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetIncomeByAssetIDRequest}
 */
proto.external.GetIncomeByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetIncomeByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetIncomeByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetIncomeByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetIncomeByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetIncomeByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetIncomeByAssetIDRequest} returns this
 */
proto.external.GetIncomeByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetIncomeByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetIncomeByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetIncomeByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetIncomeByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetIncomeByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      cashflowincomeList: jspb.Message.toObjectList(msg.getCashflowincomeList(),
        proto.external.CashFlowIncomeItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetIncomeByAssetIDResponse}
 */
proto.external.GetIncomeByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetIncomeByAssetIDResponse;
  return proto.external.GetIncomeByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetIncomeByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetIncomeByAssetIDResponse}
 */
proto.external.GetIncomeByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CashFlowIncomeItem;
        reader.readMessage(value, proto.external.CashFlowIncomeItem.deserializeBinaryFromReader);
        msg.addCashflowincome(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetIncomeByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetIncomeByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetIncomeByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetIncomeByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashflowincomeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlowIncomeItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlowIncomeItem cashFlowIncome = 1;
 * @return {!Array<!proto.external.CashFlowIncomeItem>}
 */
proto.external.GetIncomeByAssetIDResponse.prototype.getCashflowincomeList = function() {
  return /** @type{!Array<!proto.external.CashFlowIncomeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowIncomeItem, 1));
};


/**
 * @param {!Array<!proto.external.CashFlowIncomeItem>} value
 * @return {!proto.external.GetIncomeByAssetIDResponse} returns this
 */
proto.external.GetIncomeByAssetIDResponse.prototype.setCashflowincomeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlowIncomeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowIncomeItem}
 */
proto.external.GetIncomeByAssetIDResponse.prototype.addCashflowincome = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlowIncomeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetIncomeByAssetIDResponse} returns this
 */
proto.external.GetIncomeByAssetIDResponse.prototype.clearCashflowincomeList = function() {
  return this.setCashflowincomeList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CashFlowIncomeItem.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CashFlowIncomeItem.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CashFlowIncomeItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CashFlowIncomeItem.toObject = function(includeInstance, msg) {
    var f, obj = {
      month: jspb.Message.getFieldWithDefault(msg, 1, 0),
      rent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      total: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowIncomeItem}
 */
proto.external.CashFlowIncomeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowIncomeItem;
  return proto.external.CashFlowIncomeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowIncomeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowIncomeItem}
 */
proto.external.CashFlowIncomeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setRent(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setTotal(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowIncomeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowIncomeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowIncomeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowIncomeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.external.CashFlowIncomeItem.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowIncomeItem} returns this
 */
proto.external.CashFlowIncomeItem.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double rent = 2;
 * @return {number}
 */
proto.external.CashFlowIncomeItem.prototype.getRent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowIncomeItem} returns this
 */
proto.external.CashFlowIncomeItem.prototype.setRent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total = 3;
 * @return {number}
 */
proto.external.CashFlowIncomeItem.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowIncomeItem} returns this
 */
proto.external.CashFlowIncomeItem.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetServiceChargeByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetServiceChargeByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetServiceChargeByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetServiceChargeByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetServiceChargeByAssetIDRequest}
 */
proto.external.GetServiceChargeByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetServiceChargeByAssetIDRequest;
  return proto.external.GetServiceChargeByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetServiceChargeByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetServiceChargeByAssetIDRequest}
 */
proto.external.GetServiceChargeByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetServiceChargeByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetServiceChargeByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetServiceChargeByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetServiceChargeByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetServiceChargeByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetServiceChargeByAssetIDRequest} returns this
 */
proto.external.GetServiceChargeByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetServiceChargeByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetServiceChargeByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetServiceChargeByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetServiceChargeByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetServiceChargeByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      cashflowservicechargeList: jspb.Message.toObjectList(msg.getCashflowservicechargeList(),
        proto.external.CashFlowIncomeItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetServiceChargeByAssetIDResponse}
 */
proto.external.GetServiceChargeByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetServiceChargeByAssetIDResponse;
  return proto.external.GetServiceChargeByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetServiceChargeByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetServiceChargeByAssetIDResponse}
 */
proto.external.GetServiceChargeByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CashFlowIncomeItem;
        reader.readMessage(value, proto.external.CashFlowIncomeItem.deserializeBinaryFromReader);
        msg.addCashflowservicecharge(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetServiceChargeByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetServiceChargeByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetServiceChargeByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetServiceChargeByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashflowservicechargeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlowIncomeItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlowIncomeItem cashFlowServiceCharge = 1;
 * @return {!Array<!proto.external.CashFlowIncomeItem>}
 */
proto.external.GetServiceChargeByAssetIDResponse.prototype.getCashflowservicechargeList = function() {
  return /** @type{!Array<!proto.external.CashFlowIncomeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowIncomeItem, 1));
};


/**
 * @param {!Array<!proto.external.CashFlowIncomeItem>} value
 * @return {!proto.external.GetServiceChargeByAssetIDResponse} returns this
 */
proto.external.GetServiceChargeByAssetIDResponse.prototype.setCashflowservicechargeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlowIncomeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowIncomeItem}
 */
proto.external.GetServiceChargeByAssetIDResponse.prototype.addCashflowservicecharge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlowIncomeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetServiceChargeByAssetIDResponse} returns this
 */
proto.external.GetServiceChargeByAssetIDResponse.prototype.clearCashflowservicechargeList = function() {
  return this.setCashflowservicechargeList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetMonthlyCashFlowRequest.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetMonthlyCashFlowRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetMonthlyCashFlowRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetMonthlyCashFlowRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetMonthlyCashFlowRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetMonthlyCashFlowRequest}
 */
proto.external.GetMonthlyCashFlowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetMonthlyCashFlowRequest;
  return proto.external.GetMonthlyCashFlowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetMonthlyCashFlowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetMonthlyCashFlowRequest}
 */
proto.external.GetMonthlyCashFlowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addAssetids(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetMonthlyCashFlowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetMonthlyCashFlowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetMonthlyCashFlowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetMonthlyCashFlowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string assetIDs = 1;
 * @return {!Array<string>}
 */
proto.external.GetMonthlyCashFlowRequest.prototype.getAssetidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetMonthlyCashFlowRequest} returns this
 */
proto.external.GetMonthlyCashFlowRequest.prototype.setAssetidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetMonthlyCashFlowRequest} returns this
 */
proto.external.GetMonthlyCashFlowRequest.prototype.addAssetids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetMonthlyCashFlowRequest} returns this
 */
proto.external.GetMonthlyCashFlowRequest.prototype.clearAssetidsList = function() {
  return this.setAssetidsList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetMonthlyCashFlowResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetMonthlyCashFlowResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetMonthlyCashFlowResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetMonthlyCashFlowResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetMonthlyCashFlowResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      cashflowList: jspb.Message.toObjectList(msg.getCashflowList(),
        proto.external.MonthlyCashFlow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetMonthlyCashFlowResponse}
 */
proto.external.GetMonthlyCashFlowResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetMonthlyCashFlowResponse;
  return proto.external.GetMonthlyCashFlowResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetMonthlyCashFlowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetMonthlyCashFlowResponse}
 */
proto.external.GetMonthlyCashFlowResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.MonthlyCashFlow;
        reader.readMessage(value, proto.external.MonthlyCashFlow.deserializeBinaryFromReader);
        msg.addCashflow(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetMonthlyCashFlowResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetMonthlyCashFlowResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetMonthlyCashFlowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetMonthlyCashFlowResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashflowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.MonthlyCashFlow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MonthlyCashFlow cashFlow = 1;
 * @return {!Array<!proto.external.MonthlyCashFlow>}
 */
proto.external.GetMonthlyCashFlowResponse.prototype.getCashflowList = function() {
  return /** @type{!Array<!proto.external.MonthlyCashFlow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.MonthlyCashFlow, 1));
};


/**
 * @param {!Array<!proto.external.MonthlyCashFlow>} value
 * @return {!proto.external.GetMonthlyCashFlowResponse} returns this
 */
proto.external.GetMonthlyCashFlowResponse.prototype.setCashflowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.MonthlyCashFlow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.MonthlyCashFlow}
 */
proto.external.GetMonthlyCashFlowResponse.prototype.addCashflow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.MonthlyCashFlow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetMonthlyCashFlowResponse} returns this
 */
proto.external.GetMonthlyCashFlowResponse.prototype.clearCashflowList = function() {
  return this.setCashflowList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.MonthlyCashFlow.prototype.toObject = function(opt_includeInstance) {
    return proto.external.MonthlyCashFlow.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.MonthlyCashFlow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.MonthlyCashFlow.toObject = function(includeInstance, msg) {
    var f, obj = {
      portfolioid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      tenantid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      contractid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 5, ''),
      rent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
      month: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.MonthlyCashFlow}
 */
proto.external.MonthlyCashFlow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.MonthlyCashFlow;
  return proto.external.MonthlyCashFlow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.MonthlyCashFlow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.MonthlyCashFlow}
 */
proto.external.MonthlyCashFlow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPortfolioid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setRent(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.MonthlyCashFlow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.MonthlyCashFlow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.MonthlyCashFlow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.MonthlyCashFlow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string portfolioID = 1;
 * @return {string}
 */
proto.external.MonthlyCashFlow.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.MonthlyCashFlow.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenantID = 3;
 * @return {string}
 */
proto.external.MonthlyCashFlow.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contractID = 4;
 * @return {string}
 */
proto.external.MonthlyCashFlow.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string organizationID = 5;
 * @return {string}
 */
proto.external.MonthlyCashFlow.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double rent = 6;
 * @return {number}
 */
proto.external.MonthlyCashFlow.prototype.getRent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setRent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int32 month = 7;
 * @return {number}
 */
proto.external.MonthlyCashFlow.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.MonthlyCashFlow} returns this
 */
proto.external.MonthlyCashFlow.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantCostContractDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantCostContractDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantCostContractDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantCostContractDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantCostContractDetailsRequest}
 */
proto.external.GetTenantCostContractDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantCostContractDetailsRequest;
  return proto.external.GetTenantCostContractDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantCostContractDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantCostContractDetailsRequest}
 */
proto.external.GetTenantCostContractDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantCostContractDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantCostContractDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantCostContractDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantCostContractDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsRequest} returns this
 */
proto.external.GetTenantCostContractDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantCostContractDetailsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantCostContractDetailsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantCostContractDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantCostContractDetailsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantcostmanagerid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      type: jspb.Message.getFieldWithDefault(msg, 5, ''),
      typedata: jspb.Message.getFieldWithDefault(msg, 6, ''),
      startdate: jspb.Message.getFieldWithDefault(msg, 7, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 8, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantCostContractDetailsResponse}
 */
proto.external.GetTenantCostContractDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantCostContractDetailsResponse;
  return proto.external.GetTenantCostContractDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantCostContractDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantCostContractDetailsResponse}
 */
proto.external.GetTenantCostContractDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcostmanagerid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantCostContractDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantCostContractDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantCostContractDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantcostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantCostManagerID = 2;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getTenantcostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setTenantcostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string typeData = 6;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string startDate = 7;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string endDate = 8;
 * @return {string}
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostContractDetailsResponse} returns this
 */
proto.external.GetTenantCostContractDetailsResponse.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractdetailsList: jspb.Message.toObjectList(msg.getContractdetailsList(),
        proto.external.GetTenantCostContractDetailsResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse}
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantCostContractDetailsByCostManagerIDResponse;
  return proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse}
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetTenantCostContractDetailsResponse;
        reader.readMessage(value, proto.external.GetTenantCostContractDetailsResponse.deserializeBinaryFromReader);
        msg.addContractdetails(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetTenantCostContractDetailsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetTenantCostContractDetailsResponse contractDetails = 1;
 * @return {!Array<!proto.external.GetTenantCostContractDetailsResponse>}
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.prototype.getContractdetailsList = function() {
  return /** @type{!Array<!proto.external.GetTenantCostContractDetailsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetTenantCostContractDetailsResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetTenantCostContractDetailsResponse>} value
 * @return {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse} returns this
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.prototype.setContractdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetTenantCostContractDetailsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantCostContractDetailsResponse}
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.prototype.addContractdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetTenantCostContractDetailsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse} returns this
 */
proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.prototype.clearContractdetailsList = function() {
  return this.setContractdetailsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantCostContractDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantCostContractDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantCostContractDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantCostContractDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      typedata: (f = msg.getTypedata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      startdate: (f = msg.getStartdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      enddate: (f = msg.getEnddate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest}
 */
proto.external.UpdateTenantCostContractDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantCostContractDetailsRequest;
  return proto.external.UpdateTenantCostContractDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantCostContractDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest}
 */
proto.external.UpdateTenantCostContractDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setType(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTypedata(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStartdate(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantCostContractDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantCostContractDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantCostContractDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTypedata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue type = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue typeData = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.getTypedata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.setTypedata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.clearTypedata = function() {
  return this.setTypedata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.hasTypedata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue startDate = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue endDate = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostContractDetailsRequest} returns this
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostContractDetailsRequest.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 5) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantCostProviderResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantCostProviderResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantCostProviderResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantCostProviderResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantCostProviderResponse}
 */
proto.external.CreateTenantCostProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantCostProviderResponse;
  return proto.external.CreateTenantCostProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantCostProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantCostProviderResponse}
 */
proto.external.CreateTenantCostProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantCostProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantCostProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantCostProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantCostProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateTenantCostProviderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostProviderResponse} returns this
 */
proto.external.CreateTenantCostProviderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantCostProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantCostProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantCostProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantCostProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantcostmanagerid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      description: jspb.Message.getFieldWithDefault(msg, 3, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantCostProviderRequest}
 */
proto.external.CreateTenantCostProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantCostProviderRequest;
  return proto.external.CreateTenantCostProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantCostProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantCostProviderRequest}
 */
proto.external.CreateTenantCostProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcostmanagerid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantCostProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantCostProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantCostProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantCostProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantcostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenantCostManagerID = 1;
 * @return {string}
 */
proto.external.CreateTenantCostProviderRequest.prototype.getTenantcostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostProviderRequest} returns this
 */
proto.external.CreateTenantCostProviderRequest.prototype.setTenantcostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.CreateTenantCostProviderRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostProviderRequest} returns this
 */
proto.external.CreateTenantCostProviderRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.external.CreateTenantCostProviderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostProviderRequest} returns this
 */
proto.external.CreateTenantCostProviderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantCostProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantCostProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantCostProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantCostProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantCostProviderRequest}
 */
proto.external.TenantCostProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantCostProviderRequest;
  return proto.external.TenantCostProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantCostProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantCostProviderRequest}
 */
proto.external.TenantCostProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantCostProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantCostProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantCostProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantCostProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantCostProviderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostProviderRequest} returns this
 */
proto.external.TenantCostProviderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantCostProvidersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantCostProvidersResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantCostProvidersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantCostProvidersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantCostProvidersResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      costprovidersList: jspb.Message.toObjectList(msg.getCostprovidersList(),
        proto.external.GetTenantCostProviderResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantCostProvidersResponse}
 */
proto.external.GetTenantCostProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantCostProvidersResponse;
  return proto.external.GetTenantCostProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantCostProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantCostProvidersResponse}
 */
proto.external.GetTenantCostProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetTenantCostProviderResponse;
        reader.readMessage(value, proto.external.GetTenantCostProviderResponse.deserializeBinaryFromReader);
        msg.addCostproviders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantCostProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantCostProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantCostProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantCostProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostprovidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetTenantCostProviderResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetTenantCostProviderResponse costProviders = 1;
 * @return {!Array<!proto.external.GetTenantCostProviderResponse>}
 */
proto.external.GetTenantCostProvidersResponse.prototype.getCostprovidersList = function() {
  return /** @type{!Array<!proto.external.GetTenantCostProviderResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetTenantCostProviderResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetTenantCostProviderResponse>} value
 * @return {!proto.external.GetTenantCostProvidersResponse} returns this
 */
proto.external.GetTenantCostProvidersResponse.prototype.setCostprovidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetTenantCostProviderResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantCostProviderResponse}
 */
proto.external.GetTenantCostProvidersResponse.prototype.addCostproviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetTenantCostProviderResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantCostProvidersResponse} returns this
 */
proto.external.GetTenantCostProvidersResponse.prototype.clearCostprovidersList = function() {
  return this.setCostprovidersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantCostProviderResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantCostProviderResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantCostProviderResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantCostProviderResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantcostmanagerid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      name: jspb.Message.getFieldWithDefault(msg, 5, ''),
      description: jspb.Message.getFieldWithDefault(msg, 6, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantCostProviderResponse}
 */
proto.external.GetTenantCostProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantCostProviderResponse;
  return proto.external.GetTenantCostProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantCostProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantCostProviderResponse}
 */
proto.external.GetTenantCostProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcostmanagerid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantCostProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantCostProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantCostProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantCostProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantcostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantCostProviderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostProviderResponse} returns this
 */
proto.external.GetTenantCostProviderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantCostManagerID = 2;
 * @return {string}
 */
proto.external.GetTenantCostProviderResponse.prototype.getTenantcostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostProviderResponse} returns this
 */
proto.external.GetTenantCostProviderResponse.prototype.setTenantcostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetTenantCostProviderResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostProviderResponse} returns this
 */
proto.external.GetTenantCostProviderResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.GetTenantCostProviderResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostProviderResponse} returns this
 */
proto.external.GetTenantCostProviderResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.external.GetTenantCostProviderResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostProviderResponse} returns this
 */
proto.external.GetTenantCostProviderResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.external.GetTenantCostProviderResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostProviderResponse} returns this
 */
proto.external.GetTenantCostProviderResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantCostProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantCostProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantCostProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantCostProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantCostProviderRequest}
 */
proto.external.UpdateTenantCostProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantCostProviderRequest;
  return proto.external.UpdateTenantCostProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantCostProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantCostProviderRequest}
 */
proto.external.UpdateTenantCostProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantCostProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantCostProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantCostProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantCostProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantCostProviderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantCostProviderRequest} returns this
 */
proto.external.UpdateTenantCostProviderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostProviderRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostProviderRequest} returns this
 */
proto.external.UpdateTenantCostProviderRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostProviderRequest} returns this
 */
proto.external.UpdateTenantCostProviderRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostProviderRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostProviderRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostProviderRequest} returns this
 */
proto.external.UpdateTenantCostProviderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostProviderRequest} returns this
 */
proto.external.UpdateTenantCostProviderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostProviderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantCostSupplierDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantCostSupplierDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantCostSupplierDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantCostSupplierDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantCostSupplierDetailsRequest}
 */
proto.external.TenantCostSupplierDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantCostSupplierDetailsRequest;
  return proto.external.TenantCostSupplierDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantCostSupplierDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantCostSupplierDetailsRequest}
 */
proto.external.TenantCostSupplierDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantCostSupplierDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantCostSupplierDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantCostSupplierDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantCostSupplierDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantCostSupplierDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostSupplierDetailsRequest} returns this
 */
proto.external.TenantCostSupplierDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantCostSupplierDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantCostSupplierDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      companyname: (f = msg.getCompanyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      contact: (f = msg.getContact()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      country: (f = msg.getCountry()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      postcode: (f = msg.getPostcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      street: (f = msg.getStreet()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      phone: (f = msg.getPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantCostSupplierDetailsRequest;
  return proto.external.UpdateTenantCostSupplierDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCity(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCompanyname(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setContact(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCountry(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEmail(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPostcode(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStreet(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantCostSupplierDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostcode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreet();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue city = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue companyName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getCompanyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearCompanyname = function() {
  return this.setCompanyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasCompanyname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue contact = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getContact = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue country = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getCountry = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue email = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue postcode = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getPostcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setPostcode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearPostcode = function() {
  return this.setPostcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasPostcode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue street = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getStreet = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setStreet = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearStreet = function() {
  return this.setStreet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasStreet = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue phone = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.getPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantCostSupplierDetailsRequest.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 9) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantCostSupplierDetailsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantCostSupplierDetailsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantCostSupplierDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantCostSupplierDetailsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      tenantcostmanagerid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      city: jspb.Message.getFieldWithDefault(msg, 5, ''),
      companyname: jspb.Message.getFieldWithDefault(msg, 6, ''),
      contact: jspb.Message.getFieldWithDefault(msg, 7, ''),
      country: jspb.Message.getFieldWithDefault(msg, 8, ''),
      email: jspb.Message.getFieldWithDefault(msg, 9, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 10, ''),
      street: jspb.Message.getFieldWithDefault(msg, 11, ''),
      phone: jspb.Message.getFieldWithDefault(msg, 12, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse}
 */
proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantCostSupplierDetailsResponse;
  return proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantCostSupplierDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse}
 */
proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantcostmanagerid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setContact(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantCostSupplierDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantCostSupplierDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantCostSupplierDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantcostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContact();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationID = 3;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenantCostManagerID = 4;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getTenantcostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setTenantcostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string companyName = 6;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact = 7;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setContact = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string postcode = 10;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string street = 11;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantCostSupplierDetailsResponse} returns this
 */
proto.external.GetTenantCostSupplierDetailsResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantCostManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantCostManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantCostManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantCostManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantCostManagerRequest}
 */
proto.external.TenantCostManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantCostManagerRequest;
  return proto.external.TenantCostManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantCostManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantCostManagerRequest}
 */
proto.external.TenantCostManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantCostManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantCostManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantCostManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantCostManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantCostManagerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostManagerRequest} returns this
 */
proto.external.TenantCostManagerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantCostManagerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantCostManagerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantCostManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantCostManagerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      tenantid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      name: jspb.Message.getFieldWithDefault(msg, 5, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantCostManagerResponse}
 */
proto.external.TenantCostManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantCostManagerResponse;
  return proto.external.TenantCostManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantCostManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantCostManagerResponse}
 */
proto.external.TenantCostManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantCostManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantCostManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantCostManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantCostManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantCostManagerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostManagerResponse} returns this
 */
proto.external.TenantCostManagerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.TenantCostManagerResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostManagerResponse} returns this
 */
proto.external.TenantCostManagerResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenantID = 3;
 * @return {string}
 */
proto.external.TenantCostManagerResponse.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostManagerResponse} returns this
 */
proto.external.TenantCostManagerResponse.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.TenantCostManagerResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostManagerResponse} returns this
 */
proto.external.TenantCostManagerResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.external.TenantCostManagerResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantCostManagerResponse} returns this
 */
proto.external.TenantCostManagerResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.TenantCostManagersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantCostManagersResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantCostManagersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantCostManagersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantCostManagersResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      costmanagersList: jspb.Message.toObjectList(msg.getCostmanagersList(),
        proto.external.TenantCostManagerResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantCostManagersResponse}
 */
proto.external.TenantCostManagersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantCostManagersResponse;
  return proto.external.TenantCostManagersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantCostManagersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantCostManagersResponse}
 */
proto.external.TenantCostManagersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.TenantCostManagerResponse;
        reader.readMessage(value, proto.external.TenantCostManagerResponse.deserializeBinaryFromReader);
        msg.addCostmanagers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantCostManagersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantCostManagersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantCostManagersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantCostManagersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostmanagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TenantCostManagerResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TenantCostManagerResponse costManagers = 1;
 * @return {!Array<!proto.external.TenantCostManagerResponse>}
 */
proto.external.TenantCostManagersResponse.prototype.getCostmanagersList = function() {
  return /** @type{!Array<!proto.external.TenantCostManagerResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TenantCostManagerResponse, 1));
};


/**
 * @param {!Array<!proto.external.TenantCostManagerResponse>} value
 * @return {!proto.external.TenantCostManagersResponse} returns this
 */
proto.external.TenantCostManagersResponse.prototype.setCostmanagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TenantCostManagerResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TenantCostManagerResponse}
 */
proto.external.TenantCostManagersResponse.prototype.addCostmanagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TenantCostManagerResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.TenantCostManagersResponse} returns this
 */
proto.external.TenantCostManagersResponse.prototype.clearCostmanagersList = function() {
  return this.setCostmanagersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantCostManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantCostManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantCostManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantCostManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantCostManagerRequest}
 */
proto.external.CreateTenantCostManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantCostManagerRequest;
  return proto.external.CreateTenantCostManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantCostManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantCostManagerRequest}
 */
proto.external.CreateTenantCostManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantCostManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantCostManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantCostManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantCostManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.CreateTenantCostManagerRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostManagerRequest} returns this
 */
proto.external.CreateTenantCostManagerRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.CreateTenantCostManagerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostManagerRequest} returns this
 */
proto.external.CreateTenantCostManagerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantCostManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantCostManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantCostManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantCostManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantCostManagerRequest}
 */
proto.external.UpdateTenantCostManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantCostManagerRequest;
  return proto.external.UpdateTenantCostManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantCostManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantCostManagerRequest}
 */
proto.external.UpdateTenantCostManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantCostManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantCostManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantCostManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantCostManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantCostManagerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantCostManagerRequest} returns this
 */
proto.external.UpdateTenantCostManagerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.UpdateTenantCostManagerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantCostManagerRequest} returns this
 */
proto.external.UpdateTenantCostManagerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantCostManagerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantCostManagerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantCostManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantCostManagerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantCostManagerResponse}
 */
proto.external.CreateTenantCostManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantCostManagerResponse;
  return proto.external.CreateTenantCostManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantCostManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantCostManagerResponse}
 */
proto.external.CreateTenantCostManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantCostManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantCostManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantCostManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantCostManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateTenantCostManagerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantCostManagerResponse} returns this
 */
proto.external.CreateTenantCostManagerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantActivityFeedsByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantActivityFeedsByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantActivityFeedsByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantActivityFeedsByAssetIDRequest}
 */
proto.external.TenantActivityFeedsByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantActivityFeedsByAssetIDRequest;
  return proto.external.TenantActivityFeedsByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantActivityFeedsByAssetIDRequest}
 */
proto.external.TenantActivityFeedsByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantActivityFeedsByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantActivityFeedsByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantActivityFeedsByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.TenantActivityFeedsByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeedsByAssetIDRequest} returns this
 */
proto.external.TenantActivityFeedsByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantActivityFeedsByComponentRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantActivityFeedsByComponentRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantActivityFeedsByComponentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantActivityFeedsByComponentRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantActivityFeedsByComponentRequest}
 */
proto.external.TenantActivityFeedsByComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantActivityFeedsByComponentRequest;
  return proto.external.TenantActivityFeedsByComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantActivityFeedsByComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantActivityFeedsByComponentRequest}
 */
proto.external.TenantActivityFeedsByComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantActivityFeedsByComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantActivityFeedsByComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantActivityFeedsByComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantActivityFeedsByComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantActivityFeedsByComponentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeedsByComponentRequest} returns this
 */
proto.external.TenantActivityFeedsByComponentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.TenantActivityFeedsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantActivityFeedsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantActivityFeedsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantActivityFeedsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantActivityFeedsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      activityfeedsList: jspb.Message.toObjectList(msg.getActivityfeedsList(),
        proto.external.TenantActivityFeed.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantActivityFeedsResponse}
 */
proto.external.TenantActivityFeedsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantActivityFeedsResponse;
  return proto.external.TenantActivityFeedsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantActivityFeedsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantActivityFeedsResponse}
 */
proto.external.TenantActivityFeedsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.TenantActivityFeed;
        reader.readMessage(value, proto.external.TenantActivityFeed.deserializeBinaryFromReader);
        msg.addActivityfeeds(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantActivityFeedsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantActivityFeedsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantActivityFeedsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantActivityFeedsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityfeedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TenantActivityFeed.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TenantActivityFeed activityFeeds = 1;
 * @return {!Array<!proto.external.TenantActivityFeed>}
 */
proto.external.TenantActivityFeedsResponse.prototype.getActivityfeedsList = function() {
  return /** @type{!Array<!proto.external.TenantActivityFeed>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TenantActivityFeed, 1));
};


/**
 * @param {!Array<!proto.external.TenantActivityFeed>} value
 * @return {!proto.external.TenantActivityFeedsResponse} returns this
 */
proto.external.TenantActivityFeedsResponse.prototype.setActivityfeedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TenantActivityFeed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TenantActivityFeed}
 */
proto.external.TenantActivityFeedsResponse.prototype.addActivityfeeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TenantActivityFeed, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.TenantActivityFeedsResponse} returns this
 */
proto.external.TenantActivityFeedsResponse.prototype.clearActivityfeedsList = function() {
  return this.setActivityfeedsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.TenantActivityFeed.prototype.toObject = function(opt_includeInstance) {
    return proto.external.TenantActivityFeed.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.TenantActivityFeed} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.TenantActivityFeed.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      componentid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      componentname: jspb.Message.getFieldWithDefault(msg, 3, ''),
      username: jspb.Message.getFieldWithDefault(msg, 4, ''),
      createddate: jspb.Message.getFieldWithDefault(msg, 5, ''),
      action: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantActivityFeed}
 */
proto.external.TenantActivityFeed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantActivityFeed;
  return proto.external.TenantActivityFeed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantActivityFeed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantActivityFeed}
 */
proto.external.TenantActivityFeed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setComponentid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setComponentname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUsername(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      case 6:
        var value = /** @type {!proto.external.TenantActivityFeedAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantActivityFeed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantActivityFeed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantActivityFeed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantActivityFeed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComponentname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.TenantActivityFeed.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeed} returns this
 */
proto.external.TenantActivityFeed.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string componentID = 2;
 * @return {string}
 */
proto.external.TenantActivityFeed.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeed} returns this
 */
proto.external.TenantActivityFeed.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string componentName = 3;
 * @return {string}
 */
proto.external.TenantActivityFeed.prototype.getComponentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeed} returns this
 */
proto.external.TenantActivityFeed.prototype.setComponentname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.external.TenantActivityFeed.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeed} returns this
 */
proto.external.TenantActivityFeed.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string createdDate = 5;
 * @return {string}
 */
proto.external.TenantActivityFeed.prototype.getCreateddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantActivityFeed} returns this
 */
proto.external.TenantActivityFeed.prototype.setCreateddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TenantActivityFeedAction action = 6;
 * @return {!proto.external.TenantActivityFeedAction}
 */
proto.external.TenantActivityFeed.prototype.getAction = function() {
  return /** @type {!proto.external.TenantActivityFeedAction} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.external.TenantActivityFeedAction} value
 * @return {!proto.external.TenantActivityFeed} returns this
 */
proto.external.TenantActivityFeed.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      queryname: jspb.Message.getFieldWithDefault(msg, 4, ''),
      duedate: jspb.Message.getFieldWithDefault(msg, 5, ''),
      location: jspb.Message.getFieldWithDefault(msg, 6, ''),
      status: jspb.Message.getFieldWithDefault(msg, 7, 0),
      description: jspb.Message.getFieldWithDefault(msg, 8, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantManagerTicketRequest}
 */
proto.external.CreateTenantManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantManagerTicketRequest;
  return proto.external.CreateTenantManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantManagerTicketRequest}
 */
proto.external.CreateTenantManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setQueryname(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setDuedate(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setLocation(value);
        break;
      case 7:
        var value = /** @type {!proto.external.TenantStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQueryname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDuedate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantID = 2;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userID = 3;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string queryName = 4;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getQueryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setQueryname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dueDate = 5;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getDuedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setDuedate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string location = 6;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TenantStatus status = 7;
 * @return {!proto.external.TenantStatus}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getStatus = function() {
  return /** @type {!proto.external.TenantStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.external.TenantStatus} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketRequest} returns this
 */
proto.external.CreateTenantManagerTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateTenantManagerTicketResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateTenantManagerTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateTenantManagerTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateTenantManagerTicketResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      caseref: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateTenantManagerTicketResponse}
 */
proto.external.CreateTenantManagerTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateTenantManagerTicketResponse;
  return proto.external.CreateTenantManagerTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateTenantManagerTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateTenantManagerTicketResponse}
 */
proto.external.CreateTenantManagerTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseref(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateTenantManagerTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateTenantManagerTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateTenantManagerTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateTenantManagerTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaseref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketResponse} returns this
 */
proto.external.CreateTenantManagerTicketResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caseRef = 2;
 * @return {string}
 */
proto.external.CreateTenantManagerTicketResponse.prototype.getCaseref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateTenantManagerTicketResponse} returns this
 */
proto.external.CreateTenantManagerTicketResponse.prototype.setCaseref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantManagerTicketRequest}
 */
proto.external.GetTenantManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantManagerTicketRequest;
  return proto.external.GetTenantManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantManagerTicketRequest}
 */
proto.external.GetTenantManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantManagerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketRequest} returns this
 */
proto.external.GetTenantManagerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantManagerTicketResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantManagerTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantManagerTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantManagerTicketResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      tenantid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      userid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      queryname: jspb.Message.getFieldWithDefault(msg, 5, ''),
      duedate: jspb.Message.getFieldWithDefault(msg, 6, ''),
      location: jspb.Message.getFieldWithDefault(msg, 7, ''),
      status: jspb.Message.getFieldWithDefault(msg, 8, 0),
      description: jspb.Message.getFieldWithDefault(msg, 9, ''),
      caseref: jspb.Message.getFieldWithDefault(msg, 10, ''),
      createddate: jspb.Message.getFieldWithDefault(msg, 11, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantManagerTicketResponse}
 */
proto.external.GetTenantManagerTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantManagerTicketResponse;
  return proto.external.GetTenantManagerTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantManagerTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantManagerTicketResponse}
 */
proto.external.GetTenantManagerTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setQueryname(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setDuedate(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setLocation(value);
        break;
      case 8:
        var value = /** @type {!proto.external.TenantStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseref(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantManagerTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantManagerTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantManagerTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantManagerTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQueryname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDuedate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCaseref();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenantID = 3;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string userID = 4;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string queryName = 5;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getQueryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setQueryname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dueDate = 6;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getDuedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setDuedate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location = 7;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TenantStatus status = 8;
 * @return {!proto.external.TenantStatus}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getStatus = function() {
  return /** @type {!proto.external.TenantStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.external.TenantStatus} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string caseRef = 10;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getCaseref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setCaseref = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string createdDate = 11;
 * @return {string}
 */
proto.external.GetTenantManagerTicketResponse.prototype.getCreateddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketResponse} returns this
 */
proto.external.GetTenantManagerTicketResponse.prototype.setCreateddate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantManagerTicketsByTenantIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantManagerTicketsByTenantIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantManagerTicketsByTenantIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      tenantid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDRequest}
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantManagerTicketsByTenantIDRequest;
  return proto.external.GetTenantManagerTicketsByTenantIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDRequest}
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTenantid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantManagerTicketsByTenantIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDRequest} returns this
 */
proto.external.GetTenantManagerTicketsByTenantIDRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetTenantManagerTicketsByTenantIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetTenantManagerTicketsByTenantIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetTenantManagerTicketsByTenantIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetTenantManagerTicketsByTenantIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
        proto.external.GetTenantManagerTicketResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDResponse}
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantManagerTicketsByTenantIDResponse;
  return proto.external.GetTenantManagerTicketsByTenantIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantManagerTicketsByTenantIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDResponse}
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetTenantManagerTicketResponse;
        reader.readMessage(value, proto.external.GetTenantManagerTicketResponse.deserializeBinaryFromReader);
        msg.addTickets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantManagerTicketsByTenantIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantManagerTicketsByTenantIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetTenantManagerTicketResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetTenantManagerTicketResponse tickets = 1;
 * @return {!Array<!proto.external.GetTenantManagerTicketResponse>}
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.external.GetTenantManagerTicketResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetTenantManagerTicketResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetTenantManagerTicketResponse>} value
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDResponse} returns this
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetTenantManagerTicketResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantManagerTicketResponse}
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetTenantManagerTicketResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantManagerTicketsByTenantIDResponse} returns this
 */
proto.external.GetTenantManagerTicketsByTenantIDResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateTenantManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateTenantManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateTenantManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateTenantManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      tenantid: (f = msg.getTenantid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      userid: (f = msg.getUserid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      queryname: (f = msg.getQueryname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      duedate: (f = msg.getDuedate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      location: (f = msg.getLocation()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantManagerTicketRequest}
 */
proto.external.UpdateTenantManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantManagerTicketRequest;
  return proto.external.UpdateTenantManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantManagerTicketRequest}
 */
proto.external.UpdateTenantManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTenantid(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setUserid(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setQueryname(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDuedate(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setLocation(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStatus(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQueryname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDuedate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue tenantId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getTenantid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearTenantid = function() {
  return this.setTenantid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasTenantid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue userId = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getUserid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue queryName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getQueryname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setQueryname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearQueryname = function() {
  return this.setQueryname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasQueryname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue dueDate = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getDuedate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setDuedate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearDuedate = function() {
  return this.setDuedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasDuedate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue location = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getLocation = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue status = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue description = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateTenantManagerTicketRequest} returns this
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateTenantManagerTicketRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteTenantManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteTenantManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteTenantManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteTenantManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteTenantManagerTicketRequest}
 */
proto.external.DeleteTenantManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteTenantManagerTicketRequest;
  return proto.external.DeleteTenantManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteTenantManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteTenantManagerTicketRequest}
 */
proto.external.DeleteTenantManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteTenantManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteTenantManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteTenantManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteTenantManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteTenantManagerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteTenantManagerTicketRequest} returns this
 */
proto.external.DeleteTenantManagerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.external.TenantActivityFeedAction = {
  UNKNOWN_TENANTACTIVITY: 0,
  UPDATE_TENANTACTIVITY: 1,
  DELETE_TENANTACTIVITY: 2,
  CREATE_TENANTACTIVITY: 3
};

/**
 * @enum {number}
 */
proto.external.TenantStatus = {
  OPEN_TENANT_STATUS: 0,
  RESOLVED_TENANT_STATUS: 1
};

goog.object.extend(exports, proto.external);
