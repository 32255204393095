// source: external/turnover.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.external.GetGCPIRequest', null, global);
goog.exportSymbol('proto.external.GetGCPIResponse', null, global);
goog.exportSymbol('proto.external.GetGCPIResponse.GCPIByYear', null, global);
goog.exportSymbol('proto.external.GetTenantTurnoverRequest', null, global);
goog.exportSymbol('proto.external.GetTenantTurnoverResponse', null, global);
goog.exportSymbol('proto.external.TurnoverByTenant', null, global);
goog.exportSymbol('proto.external.TurnoverByTenant.TurnoverByMonth', null, global);
goog.exportSymbol('proto.external.UpdateGCPIRequest', null, global);
goog.exportSymbol('proto.external.UpdateTenantTurnoverRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateGCPIRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateGCPIRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateGCPIRequest.displayName = 'proto.external.UpdateGCPIRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetGCPIRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetGCPIRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetGCPIRequest.displayName = 'proto.external.GetGCPIRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetGCPIResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetGCPIResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetGCPIResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetGCPIResponse.displayName = 'proto.external.GetGCPIResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetGCPIResponse.GCPIByYear = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetGCPIResponse.GCPIByYear, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetGCPIResponse.GCPIByYear.displayName = 'proto.external.GetGCPIResponse.GCPIByYear';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantTurnoverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantTurnoverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantTurnoverRequest.displayName = 'proto.external.GetTenantTurnoverRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantTurnoverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantTurnoverResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantTurnoverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantTurnoverResponse.displayName = 'proto.external.GetTenantTurnoverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TurnoverByTenant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.TurnoverByTenant.repeatedFields_, null);
};
goog.inherits(proto.external.TurnoverByTenant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TurnoverByTenant.displayName = 'proto.external.TurnoverByTenant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TurnoverByTenant.TurnoverByMonth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.TurnoverByTenant.TurnoverByMonth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TurnoverByTenant.TurnoverByMonth.displayName = 'proto.external.TurnoverByTenant.TurnoverByMonth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateTenantTurnoverRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateTenantTurnoverRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateTenantTurnoverRequest.displayName = 'proto.external.UpdateTenantTurnoverRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.UpdateGCPIRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.UpdateGCPIRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.UpdateGCPIRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateGCPIRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    yearValue: (f = msg.getYearValue()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
    averageValue: (f = msg.getAverageValue()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
    year: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateGCPIRequest}
 */
proto.external.UpdateGCPIRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateGCPIRequest;
  return proto.external.UpdateGCPIRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateGCPIRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateGCPIRequest}
 */
proto.external.UpdateGCPIRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setYearValue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setAverageValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateGCPIRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateGCPIRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateGCPIRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateGCPIRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYearValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getAverageValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.UpdateGCPIRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateGCPIRequest} returns this
 */
proto.external.UpdateGCPIRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.FloatValue year_value = 2;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.external.UpdateGCPIRequest.prototype.getYearValue = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 2));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.external.UpdateGCPIRequest} returns this
*/
proto.external.UpdateGCPIRequest.prototype.setYearValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateGCPIRequest} returns this
 */
proto.external.UpdateGCPIRequest.prototype.clearYearValue = function() {
  return this.setYearValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateGCPIRequest.prototype.hasYearValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.FloatValue average_value = 3;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.external.UpdateGCPIRequest.prototype.getAverageValue = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 3));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.external.UpdateGCPIRequest} returns this
*/
proto.external.UpdateGCPIRequest.prototype.setAverageValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateGCPIRequest} returns this
 */
proto.external.UpdateGCPIRequest.prototype.clearAverageValue = function() {
  return this.setAverageValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateGCPIRequest.prototype.hasAverageValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 year = 4;
 * @return {number}
 */
proto.external.UpdateGCPIRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.UpdateGCPIRequest} returns this
 */
proto.external.UpdateGCPIRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetGCPIRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetGCPIRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetGCPIRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetGCPIRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetGCPIRequest}
 */
proto.external.GetGCPIRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetGCPIRequest;
  return proto.external.GetGCPIRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetGCPIRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetGCPIRequest}
 */
proto.external.GetGCPIRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetGCPIRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetGCPIRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetGCPIRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetGCPIRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetGCPIRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetGCPIRequest} returns this
 */
proto.external.GetGCPIRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetGCPIResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetGCPIResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetGCPIResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetGCPIResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetGCPIResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gcpiList: jspb.Message.toObjectList(msg.getGcpiList(),
    proto.external.GetGCPIResponse.GCPIByYear.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetGCPIResponse}
 */
proto.external.GetGCPIResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetGCPIResponse;
  return proto.external.GetGCPIResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetGCPIResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetGCPIResponse}
 */
proto.external.GetGCPIResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.GetGCPIResponse.GCPIByYear;
      reader.readMessage(value,proto.external.GetGCPIResponse.GCPIByYear.deserializeBinaryFromReader);
      msg.addGcpi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetGCPIResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetGCPIResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetGCPIResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetGCPIResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGcpiList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetGCPIResponse.GCPIByYear.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetGCPIResponse.GCPIByYear.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetGCPIResponse.GCPIByYear} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetGCPIResponse.GCPIByYear.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    yearValue: (f = msg.getYearValue()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
    averageValue: (f = msg.getAverageValue()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetGCPIResponse.GCPIByYear}
 */
proto.external.GetGCPIResponse.GCPIByYear.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetGCPIResponse.GCPIByYear;
  return proto.external.GetGCPIResponse.GCPIByYear.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetGCPIResponse.GCPIByYear} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetGCPIResponse.GCPIByYear}
 */
proto.external.GetGCPIResponse.GCPIByYear.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setYearValue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.FloatValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
      msg.setAverageValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetGCPIResponse.GCPIByYear.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetGCPIResponse.GCPIByYear} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetGCPIResponse.GCPIByYear.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYearValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getAverageValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetGCPIResponse.GCPIByYear} returns this
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.FloatValue year_value = 2;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.getYearValue = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 2));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.external.GetGCPIResponse.GCPIByYear} returns this
*/
proto.external.GetGCPIResponse.GCPIByYear.prototype.setYearValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.GetGCPIResponse.GCPIByYear} returns this
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.clearYearValue = function() {
  return this.setYearValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.hasYearValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.FloatValue average_value = 3;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.getAverageValue = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 3));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.external.GetGCPIResponse.GCPIByYear} returns this
*/
proto.external.GetGCPIResponse.GCPIByYear.prototype.setAverageValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.GetGCPIResponse.GCPIByYear} returns this
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.clearAverageValue = function() {
  return this.setAverageValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.GetGCPIResponse.GCPIByYear.prototype.hasAverageValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated GCPIByYear gcpi = 1;
 * @return {!Array<!proto.external.GetGCPIResponse.GCPIByYear>}
 */
proto.external.GetGCPIResponse.prototype.getGcpiList = function() {
  return /** @type{!Array<!proto.external.GetGCPIResponse.GCPIByYear>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetGCPIResponse.GCPIByYear, 1));
};


/**
 * @param {!Array<!proto.external.GetGCPIResponse.GCPIByYear>} value
 * @return {!proto.external.GetGCPIResponse} returns this
*/
proto.external.GetGCPIResponse.prototype.setGcpiList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetGCPIResponse.GCPIByYear=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetGCPIResponse.GCPIByYear}
 */
proto.external.GetGCPIResponse.prototype.addGcpi = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetGCPIResponse.GCPIByYear, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetGCPIResponse} returns this
 */
proto.external.GetGCPIResponse.prototype.clearGcpiList = function() {
  return this.setGcpiList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantTurnoverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantTurnoverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantTurnoverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantTurnoverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantTurnoverRequest}
 */
proto.external.GetTenantTurnoverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantTurnoverRequest;
  return proto.external.GetTenantTurnoverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantTurnoverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantTurnoverRequest}
 */
proto.external.GetTenantTurnoverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantTurnoverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantTurnoverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantTurnoverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantTurnoverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetTenantTurnoverRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantTurnoverRequest} returns this
 */
proto.external.GetTenantTurnoverRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.external.GetTenantTurnoverRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetTenantTurnoverRequest} returns this
 */
proto.external.GetTenantTurnoverRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantTurnoverResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantTurnoverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantTurnoverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantTurnoverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantTurnoverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantsList: jspb.Message.toObjectList(msg.getTenantsList(),
    proto.external.TurnoverByTenant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantTurnoverResponse}
 */
proto.external.GetTenantTurnoverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantTurnoverResponse;
  return proto.external.GetTenantTurnoverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantTurnoverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantTurnoverResponse}
 */
proto.external.GetTenantTurnoverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.TurnoverByTenant;
      reader.readMessage(value,proto.external.TurnoverByTenant.deserializeBinaryFromReader);
      msg.addTenants(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantTurnoverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantTurnoverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantTurnoverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantTurnoverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TurnoverByTenant.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TurnoverByTenant tenants = 1;
 * @return {!Array<!proto.external.TurnoverByTenant>}
 */
proto.external.GetTenantTurnoverResponse.prototype.getTenantsList = function() {
  return /** @type{!Array<!proto.external.TurnoverByTenant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TurnoverByTenant, 1));
};


/**
 * @param {!Array<!proto.external.TurnoverByTenant>} value
 * @return {!proto.external.GetTenantTurnoverResponse} returns this
*/
proto.external.GetTenantTurnoverResponse.prototype.setTenantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TurnoverByTenant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TurnoverByTenant}
 */
proto.external.GetTenantTurnoverResponse.prototype.addTenants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TurnoverByTenant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantTurnoverResponse} returns this
 */
proto.external.GetTenantTurnoverResponse.prototype.clearTenantsList = function() {
  return this.setTenantsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.TurnoverByTenant.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.TurnoverByTenant.prototype.toObject = function(opt_includeInstance) {
  return proto.external.TurnoverByTenant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.TurnoverByTenant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverByTenant.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    turnoversList: jspb.Message.toObjectList(msg.getTurnoversList(),
    proto.external.TurnoverByTenant.TurnoverByMonth.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TurnoverByTenant}
 */
proto.external.TurnoverByTenant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TurnoverByTenant;
  return proto.external.TurnoverByTenant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TurnoverByTenant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TurnoverByTenant}
 */
proto.external.TurnoverByTenant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 3:
      var value = new proto.external.TurnoverByTenant.TurnoverByMonth;
      reader.readMessage(value,proto.external.TurnoverByTenant.TurnoverByMonth.deserializeBinaryFromReader);
      msg.addTurnovers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TurnoverByTenant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TurnoverByTenant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TurnoverByTenant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverByTenant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTurnoversList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.external.TurnoverByTenant.TurnoverByMonth.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.TurnoverByTenant.TurnoverByMonth.prototype.toObject = function(opt_includeInstance) {
  return proto.external.TurnoverByTenant.TurnoverByMonth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.TurnoverByTenant.TurnoverByMonth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverByTenant.TurnoverByMonth.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, 0),
    turnover: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TurnoverByTenant.TurnoverByMonth}
 */
proto.external.TurnoverByTenant.TurnoverByMonth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TurnoverByTenant.TurnoverByMonth;
  return proto.external.TurnoverByTenant.TurnoverByMonth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TurnoverByTenant.TurnoverByMonth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TurnoverByTenant.TurnoverByMonth}
 */
proto.external.TurnoverByTenant.TurnoverByMonth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnover(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TurnoverByTenant.TurnoverByMonth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TurnoverByTenant.TurnoverByMonth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TurnoverByTenant.TurnoverByMonth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TurnoverByTenant.TurnoverByMonth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTurnover();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.external.TurnoverByTenant.TurnoverByMonth.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverByTenant.TurnoverByMonth} returns this
 */
proto.external.TurnoverByTenant.TurnoverByMonth.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double turnover = 2;
 * @return {number}
 */
proto.external.TurnoverByTenant.TurnoverByMonth.prototype.getTurnover = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TurnoverByTenant.TurnoverByMonth} returns this
 */
proto.external.TurnoverByTenant.TurnoverByMonth.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.TurnoverByTenant.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TurnoverByTenant} returns this
 */
proto.external.TurnoverByTenant.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantName = 2;
 * @return {string}
 */
proto.external.TurnoverByTenant.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TurnoverByTenant} returns this
 */
proto.external.TurnoverByTenant.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TurnoverByMonth turnovers = 3;
 * @return {!Array<!proto.external.TurnoverByTenant.TurnoverByMonth>}
 */
proto.external.TurnoverByTenant.prototype.getTurnoversList = function() {
  return /** @type{!Array<!proto.external.TurnoverByTenant.TurnoverByMonth>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TurnoverByTenant.TurnoverByMonth, 3));
};


/**
 * @param {!Array<!proto.external.TurnoverByTenant.TurnoverByMonth>} value
 * @return {!proto.external.TurnoverByTenant} returns this
*/
proto.external.TurnoverByTenant.prototype.setTurnoversList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.external.TurnoverByTenant.TurnoverByMonth=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TurnoverByTenant.TurnoverByMonth}
 */
proto.external.TurnoverByTenant.prototype.addTurnovers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.external.TurnoverByTenant.TurnoverByMonth, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.TurnoverByTenant} returns this
 */
proto.external.TurnoverByTenant.prototype.clearTurnoversList = function() {
  return this.setTurnoversList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.UpdateTenantTurnoverRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.UpdateTenantTurnoverRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.UpdateTenantTurnoverRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantTurnoverRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    year: jspb.Message.getFieldWithDefault(msg, 3, 0),
    turnover: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateTenantTurnoverRequest}
 */
proto.external.UpdateTenantTurnoverRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateTenantTurnoverRequest;
  return proto.external.UpdateTenantTurnoverRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateTenantTurnoverRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateTenantTurnoverRequest}
 */
proto.external.UpdateTenantTurnoverRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTurnover(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateTenantTurnoverRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateTenantTurnoverRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateTenantTurnoverRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateTenantTurnoverRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTurnover();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string tenantID = 1;
 * @return {string}
 */
proto.external.UpdateTenantTurnoverRequest.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateTenantTurnoverRequest} returns this
 */
proto.external.UpdateTenantTurnoverRequest.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.external.UpdateTenantTurnoverRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.UpdateTenantTurnoverRequest} returns this
 */
proto.external.UpdateTenantTurnoverRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 year = 3;
 * @return {number}
 */
proto.external.UpdateTenantTurnoverRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.UpdateTenantTurnoverRequest} returns this
 */
proto.external.UpdateTenantTurnoverRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double turnover = 4;
 * @return {number}
 */
proto.external.UpdateTenantTurnoverRequest.prototype.getTurnover = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.UpdateTenantTurnoverRequest} returns this
 */
proto.external.UpdateTenantTurnoverRequest.prototype.setTurnover = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


goog.object.extend(exports, proto.external);
