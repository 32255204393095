/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import Clusters from './Clusters';

const MapComponent = ({
                        assets,
  targetAssets,
                        defaultZoom,
                        mapType,
                        center,
                        zoom: mapZoom,
                        detailPage
                      }) => {
  const { data: coordinates, loaded: geoDataLoaded } = useSelector((state) => state.home.geoData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!assets || assets.length === 0) {
      return;
    }

    dispatch(actions.getGeoData({
      AssetID: (targetAssets ? assets.filter((asset) => targetAssets.length === 0 || targetAssets.includes(asset.id)) : assets).map((asset) => asset.id)
    }));
  }, [assets, targetAssets]);

  if (!geoDataLoaded) {
    return null;
  }

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} >
      <Map
        style={{ borderRadius: '12px', overflow: 'hidden' }}
        mapId={'bf51a910020fa25a'}
        gestureHandling={'greedy'}
        defaultCenter={coordinates && coordinates.length === 1 ? {
          lat: coordinates[0].latitude,
          lng: coordinates[0].longitude
        } : center}
        defaultZoom={defaultZoom}
        colorScheme={'DARK'}
        shouldUnregisterMapOnUnmount
      >
        {!detailPage && coordinates &&
          <Clusters coordinates={coordinates} assets={assets.filter((asset) => targetAssets.length === 0 || targetAssets.includes(asset.id))} />}
        {detailPage && coordinates &&
          coordinates.map((cord) => (
            <Marker key={cord.id} position={{ lat: cord.latitude, lng: cord.longitude }} />
          ))}
      </Map>
    </APIProvider>
  );
};

export default MapComponent;

MapComponent.defaultProps = {
  assets: [],
  center: { lat: 49.93, lng: 49.33 },
  defaultZoom: 3,
  zoom: 3,
  mapType: 'terrain',
  detailPage: false
};

MapComponent.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
  defaultZoom: PropTypes.number,
  zoom: PropTypes.number,
  mapType: PropTypes.string,
  center: PropTypes.object,
  detailPage: PropTypes.bool
};
