import React, { useState, useRef } from 'react';

import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import { ref, uploadBytes, rentrollStorage } from 'config/firebase';
import { convertBytesToKB, getEnv } from 'helpers/utils';
import isNull from 'lodash/isNull';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import { runAntivirusScan } from 'store/actions/actualAction';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px dashed #ddd',
    marginBottom: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    '&:hover p,&:hover svg': {
      opacity: 1
    },
    '& p, svg': {
      opacity: 0.4
    }
  },
  hidden: {
    display: 'none'
  },
  onDragOver: {
    border: '1px dashed #136919'
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500]
  },
  padding: {
    padding: '5px'
  },
  marginTop: {
    marginTop: '10px'
  },
  link: {
    color: '#666'
  }
}));

const HOVER_LABEL = 'Click or drag to upload file';
const DROP_LABEL = 'Drop file here';

const UploadRentrollModal = () => {
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [loading, setLoading] = useState(false);

  const [labelText, setLabelText] = useState(HOVER_LABEL);
  const fileInputField = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: assetDetails } = useSelector((state) => state.assets.assetDetails);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(DROP_LABEL);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(HOVER_LABEL);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(HOVER_LABEL);
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const removeFile = () => {
    setFile(null);
  };

  const handleFileUpload = async () => {
    setLoading(true);
    const { infected, error } = await runAntivirusScan([file]);

    if (error) {
      toast.error(error);
      return;
    }

    if (infected) {
      toast.error('File infected!');
      return;
    }

    const filePath = `rentrolls_${assetDetails.id}/${file.name}`;
    const storageRef = ref(rentrollStorage, filePath);

    uploadBytes(storageRef, file)
      .then((snapshot) => {
          dispatch(actions.uploadRentroll(filePath, assetDetails.id))
            .then(() => {
              toast.success('Rentroll uploaded succesfully!');
              dispatch(actions.getAssetKpi(assetDetails.id));
              dispatch(actions.getTenantsByAssetId(assetDetails.id));
              dispatch(actions.getTenantStackingPlan(assetDetails.id));
              dispatch(actions.getTenancySchedule(assetDetails.id));
              dispatch(actions.getUnitSchedule(assetDetails.id));
              dispatch(actions.getUnitStackingPlan(assetDetails.id));
              dispatch(actions.getUntsMLAByAssetID(assetDetails.id));
            })
            .catch((err) => {
              toast.error(err);
            })
            .finally(() => {
              setLoading(false);
              dispatch(closeModal());
            });
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    (<ModalWrapper
        size="xl"
        header="Upload Rent Roll"
        handleSave={handleFileUpload}
        disabled={isNull(file)}
        loading={loading}
        label="Upload"
      >
      <input
        className={classes.hidden}
        type="file"
        ref={fileInputField}
        onChange={handleFileSelect}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        data-testid="file-input"
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={clsx(classes.root, isDragOver && classes.onDragOver)}
        {...dragEvents}
        data-testid="dropzone"
        onClick={handleFileUploadBtnClick}
      >
        <IconButton
            sx={{pointerEvents: 'none'}}
          color="primary"
          aria-label="upload file"
          component="span"
          size="large">
          <CloudUploadIcon fontSize="large" />
        </IconButton>
        <Typography sx={{pointerEvents: 'none'}} component="span" variant="subtitle1" paragraph>
          {labelText}
        </Typography>
      </Grid>
      {file && (
        <>
          <Paper className={classes.padding} data-testid="dropped-item">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item className={classes.padding}>
                <Avatar variant="rounded" className={classes.rounded}>
                  <AssignmentIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item className={classes.padding}>
                {file.name}
              </Grid>
              <Grid item className={classes.padding}>
                {convertBytesToKB(file.size)}
                kb
              </Grid>
              <Grid item>
                <IconButton onClick={removeFile} size="large">
                  <DeleteIcon color="secondary" />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
          <Typography
            component="h6"
            variant="subtitle1"
            paragraph
            color="error"
            className={classes.marginTop}
          >
            Any existing data will be overwritten, please accept if you are happy to proceed
          </Typography>
        </>
      )}
      <a
        href={` https://storage.googleapis.com/ad-public-${getEnv()}/templates/Rentroll%20template.xlsx`}
        download
        className={classes.link}
      >
        Download template
      </a>
    </ModalWrapper>)
  );
};
export default UploadRentrollModal;
