// source: external/asset.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.external.Asset', null, global);
goog.exportSymbol('proto.external.AssetActivityFeed', null, global);
goog.exportSymbol('proto.external.AssetActivityFeedAction', null, global);
goog.exportSymbol('proto.external.AssetActivityFeedsByAssetRequest', null, global);
goog.exportSymbol('proto.external.AssetActivityFeedsByComponentRequest', null, global);
goog.exportSymbol('proto.external.AssetActivityFeedsResponse', null, global);
goog.exportSymbol('proto.external.CashFlowCell', null, global);
goog.exportSymbol('proto.external.CashFlowRow', null, global);
goog.exportSymbol('proto.external.Category', null, global);
goog.exportSymbol('proto.external.CostManagerRequest', null, global);
goog.exportSymbol('proto.external.CostManagerResponse', null, global);
goog.exportSymbol('proto.external.CostManagersResponse', null, global);
goog.exportSymbol('proto.external.CostProviderRequest', null, global);
goog.exportSymbol('proto.external.CostSupplierDetailsRequest', null, global);
goog.exportSymbol('proto.external.CreateAssetRequest', null, global);
goog.exportSymbol('proto.external.CreateCostManagerRequest', null, global);
goog.exportSymbol('proto.external.CreateCostManagerResponse', null, global);
goog.exportSymbol('proto.external.CreateCostProviderRequest', null, global);
goog.exportSymbol('proto.external.CreateCostProviderResponse', null, global);
goog.exportSymbol('proto.external.CreateFacilityManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.CreateFacilityManagerTicketResponse', null, global);
goog.exportSymbol('proto.external.CreateFootfallRequest', null, global);
goog.exportSymbol('proto.external.CreateFootfallResponse', null, global);
goog.exportSymbol('proto.external.CreateLegalTrackerTicketRequest', null, global);
goog.exportSymbol('proto.external.CreateLegalTrackerTicketResponse', null, global);
goog.exportSymbol('proto.external.CreateOpexManagerRequest', null, global);
goog.exportSymbol('proto.external.CreateOpexManagerResponse', null, global);
goog.exportSymbol('proto.external.CreateOpexProviderRequest', null, global);
goog.exportSymbol('proto.external.CreateOpexProviderResponse', null, global);
goog.exportSymbol('proto.external.CreateValuationRequest', null, global);
goog.exportSymbol('proto.external.CreateValuationResponse', null, global);
goog.exportSymbol('proto.external.DeleteAssetRequest', null, global);
goog.exportSymbol('proto.external.DeleteFacilityManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.DeleteLegalTrackerTicketRequest', null, global);
goog.exportSymbol('proto.external.DeleteValuationRequest', null, global);
goog.exportSymbol('proto.external.Footfall', null, global);
goog.exportSymbol('proto.external.GetAssetRequest', null, global);
goog.exportSymbol('proto.external.GetAssetsRequest', null, global);
goog.exportSymbol('proto.external.GetAssetsResponse', null, global);
goog.exportSymbol('proto.external.GetCostContractDetailsByCostManagerIDResponse', null, global);
goog.exportSymbol('proto.external.GetCostContractDetailsRequest', null, global);
goog.exportSymbol('proto.external.GetCostContractDetailsResponse', null, global);
goog.exportSymbol('proto.external.GetCostManagerByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetCostManagerByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetCostProviderResponse', null, global);
goog.exportSymbol('proto.external.GetCostProvidersResponse', null, global);
goog.exportSymbol('proto.external.GetCostSupplierDetailsResponse', null, global);
goog.exportSymbol('proto.external.GetFacilityManagerTicketByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetFacilityManagerTicketByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetFacilityManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.GetFacilityManagerTicketResponse', null, global);
goog.exportSymbol('proto.external.GetFootfallsByAssetAndMonthRequest', null, global);
goog.exportSymbol('proto.external.GetFootfallsRequest', null, global);
goog.exportSymbol('proto.external.GetFootfallsResponse', null, global);
goog.exportSymbol('proto.external.GetLegalTrackerTicketByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetLegalTrackerTicketByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetLegalTrackerTicketRequest', null, global);
goog.exportSymbol('proto.external.GetLegalTrackerTicketResponse', null, global);
goog.exportSymbol('proto.external.GetOpexContractDetailsByOpexManagerIDResponse', null, global);
goog.exportSymbol('proto.external.GetOpexContractDetailsRequest', null, global);
goog.exportSymbol('proto.external.GetOpexContractDetailsResponse', null, global);
goog.exportSymbol('proto.external.GetOpexManagerByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetOpexManagerByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.GetOpexProviderResponse', null, global);
goog.exportSymbol('proto.external.GetOpexProvidersResponse', null, global);
goog.exportSymbol('proto.external.GetOpexSupplierDetailsResponse', null, global);
goog.exportSymbol('proto.external.GetValuationRequest', null, global);
goog.exportSymbol('proto.external.GetValuationResponse', null, global);
goog.exportSymbol('proto.external.GetValuationsByAssetIDRequest', null, global);
goog.exportSymbol('proto.external.GetValuationsByAssetIDResponse', null, global);
goog.exportSymbol('proto.external.OpexManagerRequest', null, global);
goog.exportSymbol('proto.external.OpexManagerResponse', null, global);
goog.exportSymbol('proto.external.OpexManagersResponse', null, global);
goog.exportSymbol('proto.external.OpexProviderRequest', null, global);
goog.exportSymbol('proto.external.OpexSupplierDetailsRequest', null, global);
goog.exportSymbol('proto.external.SearchValuerRequest', null, global);
goog.exportSymbol('proto.external.SearchValuerResponse', null, global);
goog.exportSymbol('proto.external.Status', null, global);
goog.exportSymbol('proto.external.UpdateAssetRequest', null, global);
goog.exportSymbol('proto.external.UpdateCostContractDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateCostManagerRequest', null, global);
goog.exportSymbol('proto.external.UpdateCostProviderRequest', null, global);
goog.exportSymbol('proto.external.UpdateCostSupplierDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateFacilityManagerTicketRequest', null, global);
goog.exportSymbol('proto.external.UpdateFootfallRequest', null, global);
goog.exportSymbol('proto.external.UpdateLegalTrackerTicketRequest', null, global);
goog.exportSymbol('proto.external.UpdateOpexContractDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateOpexManagerRequest', null, global);
goog.exportSymbol('proto.external.UpdateOpexProviderRequest', null, global);
goog.exportSymbol('proto.external.UpdateOpexSupplierDetailsRequest', null, global);
goog.exportSymbol('proto.external.UpdateValuationRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateAssetRequest.displayName = 'proto.external.CreateAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAssetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetAssetsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetAssetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAssetsRequest.displayName = 'proto.external.GetAssetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAssetRequest.displayName = 'proto.external.GetAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAssetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetAssetsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetAssetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAssetsResponse.displayName = 'proto.external.GetAssetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Asset.displayName = 'proto.external.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateAssetRequest.displayName = 'proto.external.UpdateAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteAssetRequest.displayName = 'proto.external.DeleteAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateValuationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateValuationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateValuationRequest.displayName = 'proto.external.CreateValuationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateValuationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateValuationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateValuationResponse.displayName = 'proto.external.CreateValuationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetValuationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetValuationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetValuationRequest.displayName = 'proto.external.GetValuationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetValuationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetValuationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetValuationResponse.displayName = 'proto.external.GetValuationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetValuationsByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetValuationsByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetValuationsByAssetIDRequest.displayName = 'proto.external.GetValuationsByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetValuationsByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetValuationsByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetValuationsByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetValuationsByAssetIDResponse.displayName = 'proto.external.GetValuationsByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateValuationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateValuationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateValuationRequest.displayName = 'proto.external.UpdateValuationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteValuationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteValuationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteValuationRequest.displayName = 'proto.external.DeleteValuationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.SearchValuerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.SearchValuerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.SearchValuerRequest.displayName = 'proto.external.SearchValuerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.SearchValuerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.SearchValuerResponse.repeatedFields_, null);
};
goog.inherits(proto.external.SearchValuerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.SearchValuerResponse.displayName = 'proto.external.SearchValuerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetActivityFeedsByAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetActivityFeedsByAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetActivityFeedsByAssetRequest.displayName = 'proto.external.AssetActivityFeedsByAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetActivityFeedsByComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetActivityFeedsByComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetActivityFeedsByComponentRequest.displayName = 'proto.external.AssetActivityFeedsByComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetActivityFeedsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.AssetActivityFeedsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.AssetActivityFeedsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetActivityFeedsResponse.displayName = 'proto.external.AssetActivityFeedsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AssetActivityFeed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AssetActivityFeed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AssetActivityFeed.displayName = 'proto.external.AssetActivityFeed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostContractDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCostContractDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostContractDetailsRequest.displayName = 'proto.external.GetCostContractDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostContractDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCostContractDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostContractDetailsResponse.displayName = 'proto.external.GetCostContractDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetCostContractDetailsByCostManagerIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetCostContractDetailsByCostManagerIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostContractDetailsByCostManagerIDResponse.displayName = 'proto.external.GetCostContractDetailsByCostManagerIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateCostContractDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateCostContractDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateCostContractDetailsRequest.displayName = 'proto.external.UpdateCostContractDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateCostProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateCostProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateCostProviderResponse.displayName = 'proto.external.CreateCostProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateCostProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateCostProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateCostProviderRequest.displayName = 'proto.external.CreateCostProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CostProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CostProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CostProviderRequest.displayName = 'proto.external.CostProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetCostProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetCostProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostProvidersResponse.displayName = 'proto.external.GetCostProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCostProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostProviderResponse.displayName = 'proto.external.GetCostProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateCostProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateCostProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateCostProviderRequest.displayName = 'proto.external.UpdateCostProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CostSupplierDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CostSupplierDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CostSupplierDetailsRequest.displayName = 'proto.external.CostSupplierDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateCostSupplierDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateCostSupplierDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateCostSupplierDetailsRequest.displayName = 'proto.external.UpdateCostSupplierDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostSupplierDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCostSupplierDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostSupplierDetailsResponse.displayName = 'proto.external.GetCostSupplierDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CostManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CostManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CostManagerRequest.displayName = 'proto.external.CostManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CostManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CostManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CostManagerResponse.displayName = 'proto.external.CostManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CostManagersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CostManagersResponse.repeatedFields_, null);
};
goog.inherits(proto.external.CostManagersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CostManagersResponse.displayName = 'proto.external.CostManagersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateCostManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateCostManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateCostManagerRequest.displayName = 'proto.external.CreateCostManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateCostManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateCostManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateCostManagerRequest.displayName = 'proto.external.UpdateCostManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateCostManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateCostManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateCostManagerResponse.displayName = 'proto.external.CreateCostManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexContractDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetOpexContractDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexContractDetailsRequest.displayName = 'proto.external.GetOpexContractDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexContractDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetOpexContractDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexContractDetailsResponse.displayName = 'proto.external.GetOpexContractDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetOpexContractDetailsByOpexManagerIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetOpexContractDetailsByOpexManagerIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse.displayName = 'proto.external.GetOpexContractDetailsByOpexManagerIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateOpexContractDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateOpexContractDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateOpexContractDetailsRequest.displayName = 'proto.external.UpdateOpexContractDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateOpexProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateOpexProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateOpexProviderResponse.displayName = 'proto.external.CreateOpexProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateOpexProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateOpexProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateOpexProviderRequest.displayName = 'proto.external.CreateOpexProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.OpexProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.OpexProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.OpexProviderRequest.displayName = 'proto.external.OpexProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexProvidersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetOpexProvidersResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetOpexProvidersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexProvidersResponse.displayName = 'proto.external.GetOpexProvidersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetOpexProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexProviderResponse.displayName = 'proto.external.GetOpexProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateOpexProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateOpexProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateOpexProviderRequest.displayName = 'proto.external.UpdateOpexProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.OpexSupplierDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.OpexSupplierDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.OpexSupplierDetailsRequest.displayName = 'proto.external.OpexSupplierDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateOpexSupplierDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateOpexSupplierDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateOpexSupplierDetailsRequest.displayName = 'proto.external.UpdateOpexSupplierDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexSupplierDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetOpexSupplierDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexSupplierDetailsResponse.displayName = 'proto.external.GetOpexSupplierDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.OpexManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.OpexManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.OpexManagerRequest.displayName = 'proto.external.OpexManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.OpexManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.OpexManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.OpexManagerResponse.displayName = 'proto.external.OpexManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.OpexManagersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.OpexManagersResponse.repeatedFields_, null);
};
goog.inherits(proto.external.OpexManagersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.OpexManagersResponse.displayName = 'proto.external.OpexManagersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateOpexManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateOpexManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateOpexManagerRequest.displayName = 'proto.external.CreateOpexManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateOpexManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateOpexManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateOpexManagerRequest.displayName = 'proto.external.UpdateOpexManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateOpexManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateOpexManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateOpexManagerResponse.displayName = 'proto.external.CreateOpexManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Footfall = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.Footfall, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Footfall.displayName = 'proto.external.Footfall';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFootfallsByAssetAndMonthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetFootfallsByAssetAndMonthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFootfallsByAssetAndMonthRequest.displayName = 'proto.external.GetFootfallsByAssetAndMonthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFootfallsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetFootfallsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFootfallsRequest.displayName = 'proto.external.GetFootfallsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFootfallsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetFootfallsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetFootfallsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFootfallsResponse.displayName = 'proto.external.GetFootfallsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateFootfallRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateFootfallRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateFootfallRequest.displayName = 'proto.external.UpdateFootfallRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateFootfallRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateFootfallRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateFootfallRequest.displayName = 'proto.external.CreateFootfallRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateFootfallResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateFootfallResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateFootfallResponse.displayName = 'proto.external.CreateFootfallResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexManagerByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetOpexManagerByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexManagerByAssetIDRequest.displayName = 'proto.external.GetOpexManagerByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostManagerByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetCostManagerByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostManagerByAssetIDRequest.displayName = 'proto.external.GetCostManagerByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetCostManagerByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetCostManagerByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetCostManagerByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetCostManagerByAssetIDResponse.displayName = 'proto.external.GetCostManagerByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetOpexManagerByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetOpexManagerByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetOpexManagerByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetOpexManagerByAssetIDResponse.displayName = 'proto.external.GetOpexManagerByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowCell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CashFlowCell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowCell.displayName = 'proto.external.CashFlowCell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CashFlowRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CashFlowRow.repeatedFields_, null);
};
goog.inherits(proto.external.CashFlowRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CashFlowRow.displayName = 'proto.external.CashFlowRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFacilityManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetFacilityManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFacilityManagerTicketRequest.displayName = 'proto.external.GetFacilityManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFacilityManagerTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetFacilityManagerTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFacilityManagerTicketResponse.displayName = 'proto.external.GetFacilityManagerTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetFacilityManagerTicketByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFacilityManagerTicketByAssetIDRequest.displayName = 'proto.external.GetFacilityManagerTicketByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetFacilityManagerTicketByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetFacilityManagerTicketByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetFacilityManagerTicketByAssetIDResponse.displayName = 'proto.external.GetFacilityManagerTicketByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateFacilityManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateFacilityManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateFacilityManagerTicketRequest.displayName = 'proto.external.CreateFacilityManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateFacilityManagerTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateFacilityManagerTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateFacilityManagerTicketResponse.displayName = 'proto.external.CreateFacilityManagerTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateFacilityManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateFacilityManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateFacilityManagerTicketRequest.displayName = 'proto.external.UpdateFacilityManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteFacilityManagerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteFacilityManagerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteFacilityManagerTicketRequest.displayName = 'proto.external.DeleteFacilityManagerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateLegalTrackerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateLegalTrackerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateLegalTrackerTicketRequest.displayName = 'proto.external.CreateLegalTrackerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateLegalTrackerTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateLegalTrackerTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateLegalTrackerTicketResponse.displayName = 'proto.external.CreateLegalTrackerTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetLegalTrackerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetLegalTrackerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetLegalTrackerTicketRequest.displayName = 'proto.external.GetLegalTrackerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetLegalTrackerTicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetLegalTrackerTicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetLegalTrackerTicketResponse.displayName = 'proto.external.GetLegalTrackerTicketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetLegalTrackerTicketByAssetIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetLegalTrackerTicketByAssetIDRequest.displayName = 'proto.external.GetLegalTrackerTicketByAssetIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetLegalTrackerTicketByAssetIDResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetLegalTrackerTicketByAssetIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetLegalTrackerTicketByAssetIDResponse.displayName = 'proto.external.GetLegalTrackerTicketByAssetIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateLegalTrackerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateLegalTrackerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateLegalTrackerTicketRequest.displayName = 'proto.external.UpdateLegalTrackerTicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteLegalTrackerTicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteLegalTrackerTicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteLegalTrackerTicketRequest.displayName = 'proto.external.DeleteLegalTrackerTicketRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateAssetRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateAssetRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateAssetRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ''),
      country: jspb.Message.getFieldWithDefault(msg, 2, ''),
      city: jspb.Message.getFieldWithDefault(msg, 3, ''),
      street: jspb.Message.getFieldWithDefault(msg, 4, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 5, ''),
      type: jspb.Message.getFieldWithDefault(msg, 6, ''),
      portfolioid: jspb.Message.getFieldWithDefault(msg, 7, ''),
      manager: jspb.Message.getFieldWithDefault(msg, 8, ''),
      comment: jspb.Message.getFieldWithDefault(msg, 9, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateAssetRequest}
 */
proto.external.CreateAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateAssetRequest;
  return proto.external.CreateAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateAssetRequest}
 */
proto.external.CreateAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setPortfolioid(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setManager(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setComment(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getManager();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 3;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string street = 4;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string postcode = 5;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string type = 6;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string portfolioID = 7;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string manager = 8;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getManager = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setManager = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.external.CreateAssetRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateAssetRequest} returns this
 */
proto.external.CreateAssetRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetAssetsRequest.repeatedFields_ = [1, 2];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetAssetsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetAssetsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetAssetsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetAssetsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      portfolioidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      assetmanagersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAssetsRequest}
 */
proto.external.GetAssetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAssetsRequest;
  return proto.external.GetAssetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAssetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAssetsRequest}
 */
proto.external.GetAssetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addPortfolioids(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.addAssetmanagers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAssetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAssetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAssetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAssetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfolioidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAssetmanagersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string portfolioIDs = 1;
 * @return {!Array<string>}
 */
proto.external.GetAssetsRequest.prototype.getPortfolioidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetAssetsRequest} returns this
 */
proto.external.GetAssetsRequest.prototype.setPortfolioidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetAssetsRequest} returns this
 */
proto.external.GetAssetsRequest.prototype.addPortfolioids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetAssetsRequest} returns this
 */
proto.external.GetAssetsRequest.prototype.clearPortfolioidsList = function() {
  return this.setPortfolioidsList([]);
};


/**
 * repeated string assetManagers = 2;
 * @return {!Array<string>}
 */
proto.external.GetAssetsRequest.prototype.getAssetmanagersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.GetAssetsRequest} returns this
 */
proto.external.GetAssetsRequest.prototype.setAssetmanagersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.GetAssetsRequest} returns this
 */
proto.external.GetAssetsRequest.prototype.addAssetmanagers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetAssetsRequest} returns this
 */
proto.external.GetAssetsRequest.prototype.clearAssetmanagersList = function() {
  return this.setAssetmanagersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetAssetRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetAssetRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetAssetRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAssetRequest}
 */
proto.external.GetAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAssetRequest;
  return proto.external.GetAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAssetRequest}
 */
proto.external.GetAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetAssetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetAssetRequest} returns this
 */
proto.external.GetAssetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetAssetsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetAssetsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetAssetsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetAssetsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetAssetsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
        proto.external.Asset.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAssetsResponse}
 */
proto.external.GetAssetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAssetsResponse;
  return proto.external.GetAssetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAssetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAssetsResponse}
 */
proto.external.GetAssetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.Asset;
        reader.readMessage(value, proto.external.Asset.deserializeBinaryFromReader);
        msg.addAssets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAssetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAssetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAssetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAssetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.external.Asset>}
 */
proto.external.GetAssetsResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.external.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Asset, 1));
};


/**
 * @param {!Array<!proto.external.Asset>} value
 * @return {!proto.external.GetAssetsResponse} returns this
 */
proto.external.GetAssetsResponse.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Asset}
 */
proto.external.GetAssetsResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetAssetsResponse} returns this
 */
proto.external.GetAssetsResponse.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.Asset.prototype.toObject = function(opt_includeInstance) {
    return proto.external.Asset.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.Asset} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.Asset.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      country: jspb.Message.getFieldWithDefault(msg, 3, ''),
      city: jspb.Message.getFieldWithDefault(msg, 4, ''),
      street: jspb.Message.getFieldWithDefault(msg, 5, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 6, ''),
      type: jspb.Message.getFieldWithDefault(msg, 7, ''),
      portfolioid: jspb.Message.getFieldWithDefault(msg, 8, ''),
      manager: jspb.Message.getFieldWithDefault(msg, 9, ''),
      profitcenter: jspb.Message.getFieldWithDefault(msg, 10, ''),
      costcentercode: jspb.Message.getFieldWithDefault(msg, 11, ''),
      internalassetid: jspb.Message.getFieldWithDefault(msg, 12, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Asset}
 */
proto.external.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Asset;
  return proto.external.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Asset}
 */
proto.external.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setPortfolioid(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setManager(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setProfitcenter(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setCostcentercode(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setInternalassetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getManager();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProfitcenter();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCostcentercode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInternalassetid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.Asset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.Asset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.external.Asset.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.external.Asset.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string street = 5;
 * @return {string}
 */
proto.external.Asset.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postcode = 6;
 * @return {string}
 */
proto.external.Asset.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.external.Asset.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string portfolioID = 8;
 * @return {string}
 */
proto.external.Asset.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string manager = 9;
 * @return {string}
 */
proto.external.Asset.prototype.getManager = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setManager = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string profitCenter = 10;
 * @return {string}
 */
proto.external.Asset.prototype.getProfitcenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setProfitcenter = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string costCenterCode = 11;
 * @return {string}
 */
proto.external.Asset.prototype.getCostcentercode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setCostcentercode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string internalAssetID = 12;
 * @return {string}
 */
proto.external.Asset.prototype.getInternalassetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Asset} returns this
 */
proto.external.Asset.prototype.setInternalassetid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


proto.external.Asset.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};


proto.external.Asset.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateAssetRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateAssetRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateAssetRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      country: (f = msg.getCountry()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      street: (f = msg.getStreet()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      postcode: (f = msg.getPostcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      manager: (f = msg.getManager()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      profitcenter: (f = msg.getProfitcenter()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      costcentercode: (f = msg.getCostcentercode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      internalassetid: (f = msg.getInternalassetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateAssetRequest}
 */
proto.external.UpdateAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateAssetRequest;
  return proto.external.UpdateAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateAssetRequest}
 */
proto.external.UpdateAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCountry(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCity(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStreet(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPostcode(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setType(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setManager(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setProfitcenter(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCostcentercode(value);
        break;
      case 11:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setInternalassetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreet();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostcode();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getManager();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProfitcenter();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCostcentercode();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInternalassetid();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateAssetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue country = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getCountry = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue city = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue street = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getStreet = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setStreet = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearStreet = function() {
  return this.setStreet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasStreet = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue postcode = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getPostcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setPostcode = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearPostcode = function() {
  return this.setPostcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasPostcode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue type = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue manager = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getManager = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setManager = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};

proto.external.UpdateAssetRequest.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearManager = function() {
  return this.setManager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasManager = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue profitCenter = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getProfitcenter = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setProfitcenter = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearProfitcenter = function() {
  return this.setProfitcenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasProfitcenter = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue costCenterCode = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getCostcentercode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setCostcentercode = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearCostcentercode = function() {
  return this.setCostcentercode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasCostcentercode = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue internalAssetID = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateAssetRequest.prototype.getInternalassetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.setInternalassetid = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateAssetRequest} returns this
 */
proto.external.UpdateAssetRequest.prototype.clearInternalassetid = function() {
  return this.setInternalassetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateAssetRequest.prototype.hasInternalassetid = function() {
  return jspb.Message.getField(this, 11) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteAssetRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteAssetRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteAssetRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteAssetRequest}
 */
proto.external.DeleteAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteAssetRequest;
  return proto.external.DeleteAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteAssetRequest}
 */
proto.external.DeleteAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteAssetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteAssetRequest} returns this
 */
proto.external.DeleteAssetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateValuationRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateValuationRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateValuationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateValuationRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      valuation: jspb.Message.getFieldWithDefault(msg, 2, ''),
      marketvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
      date: jspb.Message.getFieldWithDefault(msg, 4, ''),
      liquidationvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
      valuer: jspb.Message.getFieldWithDefault(msg, 6, ''),
      summary: jspb.Message.getFieldWithDefault(msg, 7, ''),
      dcfMethodValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
      comparativeMethodValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
      insuranceMethodValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
      currentRent: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
      erv: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
      discountRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
      exitYield: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateValuationRequest}
 */
proto.external.CreateValuationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateValuationRequest;
  return proto.external.CreateValuationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateValuationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateValuationRequest}
 */
proto.external.CreateValuationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setValuation(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setMarketvalue(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setLiquidationvalue(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setValuer(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setSummary(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setDcfMethodValue(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setComparativeMethodValue(value);
        break;
      case 10:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setInsuranceMethodValue(value);
        break;
      case 11:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setCurrentRent(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setErv(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setDiscountRate(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setExitYield(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateValuationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateValuationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateValuationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateValuationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLiquidationvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getValuer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDcfMethodValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getComparativeMethodValue();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getInsuranceMethodValue();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getCurrentRent();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getErv();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDiscountRate();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getExitYield();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateValuationRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string valuation = 2;
 * @return {string}
 */
proto.external.CreateValuationRequest.prototype.getValuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setValuation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double marketValue = 3;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getMarketvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setMarketvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.external.CreateValuationRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double liquidationValue = 5;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getLiquidationvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setLiquidationvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string valuer = 6;
 * @return {string}
 */
proto.external.CreateValuationRequest.prototype.getValuer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setValuer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string summary = 7;
 * @return {string}
 */
proto.external.CreateValuationRequest.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double dcf_method_value = 8;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getDcfMethodValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setDcfMethodValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double comparative_method_value = 9;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getComparativeMethodValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setComparativeMethodValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double insurance_method_value = 10;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getInsuranceMethodValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setInsuranceMethodValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double current_rent = 11;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getCurrentRent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setCurrentRent = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double erv = 12;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getErv = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setErv = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double discount_rate = 13;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getDiscountRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setDiscountRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double exit_yield = 14;
 * @return {number}
 */
proto.external.CreateValuationRequest.prototype.getExitYield = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateValuationRequest} returns this
 */
proto.external.CreateValuationRequest.prototype.setExitYield = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateValuationResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateValuationResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateValuationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateValuationResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateValuationResponse}
 */
proto.external.CreateValuationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateValuationResponse;
  return proto.external.CreateValuationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateValuationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateValuationResponse}
 */
proto.external.CreateValuationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateValuationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateValuationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateValuationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateValuationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateValuationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateValuationResponse} returns this
 */
proto.external.CreateValuationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetValuationRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetValuationRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetValuationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetValuationRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetValuationRequest}
 */
proto.external.GetValuationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetValuationRequest;
  return proto.external.GetValuationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetValuationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetValuationRequest}
 */
proto.external.GetValuationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetValuationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetValuationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetValuationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetValuationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetValuationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationRequest} returns this
 */
proto.external.GetValuationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetValuationResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetValuationResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetValuationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetValuationResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      valuation: jspb.Message.getFieldWithDefault(msg, 3, ''),
      date: jspb.Message.getFieldWithDefault(msg, 4, ''),
      marketvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
      liquidationvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
      valuer: jspb.Message.getFieldWithDefault(msg, 7, ''),
      summary: jspb.Message.getFieldWithDefault(msg, 8, ''),
      filesmetadata: jspb.Message.getFieldWithDefault(msg, 9, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetValuationResponse}
 */
proto.external.GetValuationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetValuationResponse;
  return proto.external.GetValuationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetValuationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetValuationResponse}
 */
proto.external.GetValuationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setValuation(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setMarketvalue(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setLiquidationvalue(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setValuer(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setSummary(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setFilesmetadata(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetValuationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetValuationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetValuationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetValuationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLiquidationvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getValuer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFilesmetadata();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string valuation = 3;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getValuation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setValuation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double marketValue = 5;
 * @return {number}
 */
proto.external.GetValuationResponse.prototype.getMarketvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setMarketvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double liquidationValue = 6;
 * @return {number}
 */
proto.external.GetValuationResponse.prototype.getLiquidationvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setLiquidationvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string valuer = 7;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getValuer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setValuer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string summary = 8;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string filesMetadata = 9;
 * @return {string}
 */
proto.external.GetValuationResponse.prototype.getFilesmetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationResponse} returns this
 */
proto.external.GetValuationResponse.prototype.setFilesmetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetValuationsByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetValuationsByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetValuationsByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetValuationsByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetValuationsByAssetIDRequest}
 */
proto.external.GetValuationsByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetValuationsByAssetIDRequest;
  return proto.external.GetValuationsByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetValuationsByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetValuationsByAssetIDRequest}
 */
proto.external.GetValuationsByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetValuationsByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetValuationsByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetValuationsByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetValuationsByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetValuationsByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetValuationsByAssetIDRequest} returns this
 */
proto.external.GetValuationsByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetValuationsByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetValuationsByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetValuationsByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetValuationsByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetValuationsByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      valuationsList: jspb.Message.toObjectList(msg.getValuationsList(),
        proto.external.GetValuationResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetValuationsByAssetIDResponse}
 */
proto.external.GetValuationsByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetValuationsByAssetIDResponse;
  return proto.external.GetValuationsByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetValuationsByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetValuationsByAssetIDResponse}
 */
proto.external.GetValuationsByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetValuationResponse;
        reader.readMessage(value, proto.external.GetValuationResponse.deserializeBinaryFromReader);
        msg.addValuations(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetValuationsByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetValuationsByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetValuationsByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetValuationsByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetValuationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetValuationResponse valuations = 1;
 * @return {!Array<!proto.external.GetValuationResponse>}
 */
proto.external.GetValuationsByAssetIDResponse.prototype.getValuationsList = function() {
  return /** @type{!Array<!proto.external.GetValuationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetValuationResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetValuationResponse>} value
 * @return {!proto.external.GetValuationsByAssetIDResponse} returns this
 */
proto.external.GetValuationsByAssetIDResponse.prototype.setValuationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetValuationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetValuationResponse}
 */
proto.external.GetValuationsByAssetIDResponse.prototype.addValuations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetValuationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetValuationsByAssetIDResponse} returns this
 */
proto.external.GetValuationsByAssetIDResponse.prototype.clearValuationsList = function() {
  return this.setValuationsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateValuationRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateValuationRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateValuationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateValuationRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: (f = msg.getAssetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      valuation: (f = msg.getValuation()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      marketvalue: (f = msg.getMarketvalue()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      liquidationvalue: (f = msg.getLiquidationvalue()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      valuer: (f = msg.getValuer()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      summary: (f = msg.getSummary()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      date: (f = msg.getDate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      filesmetadata: (f = msg.getFilesmetadata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      dcfMethodValue: (f = msg.getDcfMethodValue()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      comparativeMethodValue: (f = msg.getComparativeMethodValue()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      insuranceMethodValue: (f = msg.getInsuranceMethodValue()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      currentRent: (f = msg.getCurrentRent()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      erv: (f = msg.getErv()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      discountRate: (f = msg.getDiscountRate()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      exitYield: (f = msg.getExitYield()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateValuationRequest}
 */
proto.external.UpdateValuationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateValuationRequest;
  return proto.external.UpdateValuationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateValuationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateValuationRequest}
 */
proto.external.UpdateValuationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setAssetid(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setValuation(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setMarketvalue(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setLiquidationvalue(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setValuer(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setSummary(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDate(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setFilesmetadata(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setDcfMethodValue(value);
        break;
      case 11:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setComparativeMethodValue(value);
        break;
      case 12:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setInsuranceMethodValue(value);
        break;
      case 13:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setCurrentRent(value);
        break;
      case 14:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setErv(value);
        break;
      case 15:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setDiscountRate(value);
        break;
      case 16:
        var value = new google_protobuf_wrappers_pb.DoubleValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setExitYield(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateValuationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateValuationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateValuationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateValuationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getValuation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMarketvalue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getLiquidationvalue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getValuer();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFilesmetadata();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDcfMethodValue();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getComparativeMethodValue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getInsuranceMethodValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrentRent();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getErv();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getDiscountRate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getExitYield();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateValuationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue assetID = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateValuationRequest.prototype.getAssetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearAssetid = function() {
  return this.setAssetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasAssetid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue valuation = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateValuationRequest.prototype.getValuation = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setValuation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearValuation = function() {
  return this.setValuation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasValuation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.DoubleValue marketValue = 4;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getMarketvalue = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 4));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setMarketvalue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearMarketvalue = function() {
  return this.setMarketvalue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasMarketvalue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.DoubleValue liquidationValue = 5;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getLiquidationvalue = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 5));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setLiquidationvalue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearLiquidationvalue = function() {
  return this.setLiquidationvalue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasLiquidationvalue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue valuer = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateValuationRequest.prototype.getValuer = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setValuer = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearValuer = function() {
  return this.setValuer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasValuer = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue summary = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateValuationRequest.prototype.getSummary = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue date = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateValuationRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue filesMetadata = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateValuationRequest.prototype.getFilesmetadata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setFilesmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearFilesmetadata = function() {
  return this.setFilesmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasFilesmetadata = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.DoubleValue dcf_method_value = 10;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getDcfMethodValue = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 10));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setDcfMethodValue = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearDcfMethodValue = function() {
  return this.setDcfMethodValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasDcfMethodValue = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.DoubleValue comparative_method_value = 11;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getComparativeMethodValue = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 11));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setComparativeMethodValue = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearComparativeMethodValue = function() {
  return this.setComparativeMethodValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasComparativeMethodValue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.DoubleValue insurance_method_value = 12;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getInsuranceMethodValue = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 12));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setInsuranceMethodValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearInsuranceMethodValue = function() {
  return this.setInsuranceMethodValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasInsuranceMethodValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.DoubleValue current_rent = 13;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getCurrentRent = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 13));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setCurrentRent = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearCurrentRent = function() {
  return this.setCurrentRent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasCurrentRent = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.DoubleValue erv = 14;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getErv = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 14));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setErv = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearErv = function() {
  return this.setErv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasErv = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.DoubleValue discount_rate = 15;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getDiscountRate = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 15));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setDiscountRate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearDiscountRate = function() {
  return this.setDiscountRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasDiscountRate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.DoubleValue exit_yield = 16;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.external.UpdateValuationRequest.prototype.getExitYield = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 16));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.setExitYield = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateValuationRequest} returns this
 */
proto.external.UpdateValuationRequest.prototype.clearExitYield = function() {
  return this.setExitYield(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateValuationRequest.prototype.hasExitYield = function() {
  return jspb.Message.getField(this, 16) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteValuationRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteValuationRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteValuationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteValuationRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteValuationRequest}
 */
proto.external.DeleteValuationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteValuationRequest;
  return proto.external.DeleteValuationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteValuationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteValuationRequest}
 */
proto.external.DeleteValuationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteValuationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteValuationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteValuationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteValuationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteValuationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteValuationRequest} returns this
 */
proto.external.DeleteValuationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.SearchValuerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.SearchValuerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.SearchValuerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.SearchValuerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      query: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.SearchValuerRequest}
 */
proto.external.SearchValuerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.SearchValuerRequest;
  return proto.external.SearchValuerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.SearchValuerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.SearchValuerRequest}
 */
proto.external.SearchValuerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setQuery(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.SearchValuerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.SearchValuerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.SearchValuerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.SearchValuerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.external.SearchValuerRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.SearchValuerRequest} returns this
 */
proto.external.SearchValuerRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.SearchValuerResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.SearchValuerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.SearchValuerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.SearchValuerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.SearchValuerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      valuersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.SearchValuerResponse}
 */
proto.external.SearchValuerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.SearchValuerResponse;
  return proto.external.SearchValuerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.SearchValuerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.SearchValuerResponse}
 */
proto.external.SearchValuerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addValuers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.SearchValuerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.SearchValuerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.SearchValuerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.SearchValuerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string valuers = 1;
 * @return {!Array<string>}
 */
proto.external.SearchValuerResponse.prototype.getValuersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.SearchValuerResponse} returns this
 */
proto.external.SearchValuerResponse.prototype.setValuersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.SearchValuerResponse} returns this
 */
proto.external.SearchValuerResponse.prototype.addValuers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.SearchValuerResponse} returns this
 */
proto.external.SearchValuerResponse.prototype.clearValuersList = function() {
  return this.setValuersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.AssetActivityFeedsByAssetRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.AssetActivityFeedsByAssetRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.AssetActivityFeedsByAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.AssetActivityFeedsByAssetRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetActivityFeedsByAssetRequest}
 */
proto.external.AssetActivityFeedsByAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetActivityFeedsByAssetRequest;
  return proto.external.AssetActivityFeedsByAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetActivityFeedsByAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetActivityFeedsByAssetRequest}
 */
proto.external.AssetActivityFeedsByAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetActivityFeedsByAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetActivityFeedsByAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetActivityFeedsByAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetActivityFeedsByAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.AssetActivityFeedsByAssetRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeedsByAssetRequest} returns this
 */
proto.external.AssetActivityFeedsByAssetRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.AssetActivityFeedsByComponentRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.AssetActivityFeedsByComponentRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.AssetActivityFeedsByComponentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.AssetActivityFeedsByComponentRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetActivityFeedsByComponentRequest}
 */
proto.external.AssetActivityFeedsByComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetActivityFeedsByComponentRequest;
  return proto.external.AssetActivityFeedsByComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetActivityFeedsByComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetActivityFeedsByComponentRequest}
 */
proto.external.AssetActivityFeedsByComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetActivityFeedsByComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetActivityFeedsByComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetActivityFeedsByComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetActivityFeedsByComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.AssetActivityFeedsByComponentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeedsByComponentRequest} returns this
 */
proto.external.AssetActivityFeedsByComponentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.AssetActivityFeedsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.AssetActivityFeedsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.AssetActivityFeedsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.AssetActivityFeedsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.AssetActivityFeedsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      activityfeedsList: jspb.Message.toObjectList(msg.getActivityfeedsList(),
        proto.external.AssetActivityFeed.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetActivityFeedsResponse}
 */
proto.external.AssetActivityFeedsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetActivityFeedsResponse;
  return proto.external.AssetActivityFeedsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetActivityFeedsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetActivityFeedsResponse}
 */
proto.external.AssetActivityFeedsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.AssetActivityFeed;
        reader.readMessage(value, proto.external.AssetActivityFeed.deserializeBinaryFromReader);
        msg.addActivityfeeds(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetActivityFeedsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetActivityFeedsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetActivityFeedsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetActivityFeedsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityfeedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.AssetActivityFeed.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssetActivityFeed activityFeeds = 1;
 * @return {!Array<!proto.external.AssetActivityFeed>}
 */
proto.external.AssetActivityFeedsResponse.prototype.getActivityfeedsList = function() {
  return /** @type{!Array<!proto.external.AssetActivityFeed>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.AssetActivityFeed, 1));
};


/**
 * @param {!Array<!proto.external.AssetActivityFeed>} value
 * @return {!proto.external.AssetActivityFeedsResponse} returns this
 */
proto.external.AssetActivityFeedsResponse.prototype.setActivityfeedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.AssetActivityFeed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.AssetActivityFeed}
 */
proto.external.AssetActivityFeedsResponse.prototype.addActivityfeeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.AssetActivityFeed, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.AssetActivityFeedsResponse} returns this
 */
proto.external.AssetActivityFeedsResponse.prototype.clearActivityfeedsList = function() {
  return this.setActivityfeedsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.AssetActivityFeed.prototype.toObject = function(opt_includeInstance) {
    return proto.external.AssetActivityFeed.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.AssetActivityFeed} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.AssetActivityFeed.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      componentid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      componentname: jspb.Message.getFieldWithDefault(msg, 3, ''),
      username: jspb.Message.getFieldWithDefault(msg, 4, ''),
      createddate: jspb.Message.getFieldWithDefault(msg, 5, ''),
      action: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AssetActivityFeed}
 */
proto.external.AssetActivityFeed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AssetActivityFeed;
  return proto.external.AssetActivityFeed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AssetActivityFeed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AssetActivityFeed}
 */
proto.external.AssetActivityFeed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setComponentid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setComponentname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setUsername(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      case 6:
        var value = /** @type {!proto.external.AssetActivityFeedAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AssetActivityFeed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AssetActivityFeed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AssetActivityFeed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AssetActivityFeed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComponentname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.AssetActivityFeed.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeed} returns this
 */
proto.external.AssetActivityFeed.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string componentID = 2;
 * @return {string}
 */
proto.external.AssetActivityFeed.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeed} returns this
 */
proto.external.AssetActivityFeed.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string componentName = 3;
 * @return {string}
 */
proto.external.AssetActivityFeed.prototype.getComponentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeed} returns this
 */
proto.external.AssetActivityFeed.prototype.setComponentname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string username = 4;
 * @return {string}
 */
proto.external.AssetActivityFeed.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeed} returns this
 */
proto.external.AssetActivityFeed.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string createdDate = 5;
 * @return {string}
 */
proto.external.AssetActivityFeed.prototype.getCreateddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.AssetActivityFeed} returns this
 */
proto.external.AssetActivityFeed.prototype.setCreateddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AssetActivityFeedAction action = 6;
 * @return {!proto.external.AssetActivityFeedAction}
 */
proto.external.AssetActivityFeed.prototype.getAction = function() {
  return /** @type {!proto.external.AssetActivityFeedAction} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.external.AssetActivityFeedAction} value
 * @return {!proto.external.AssetActivityFeed} returns this
 */
proto.external.AssetActivityFeed.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostContractDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostContractDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostContractDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostContractDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostContractDetailsRequest}
 */
proto.external.GetCostContractDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostContractDetailsRequest;
  return proto.external.GetCostContractDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostContractDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostContractDetailsRequest}
 */
proto.external.GetCostContractDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostContractDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostContractDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostContractDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostContractDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetCostContractDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsRequest} returns this
 */
proto.external.GetCostContractDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostContractDetailsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostContractDetailsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostContractDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostContractDetailsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      costmanagerid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      type: jspb.Message.getFieldWithDefault(msg, 5, ''),
      typedata: jspb.Message.getFieldWithDefault(msg, 6, ''),
      startdate: jspb.Message.getFieldWithDefault(msg, 7, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 8, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostContractDetailsResponse}
 */
proto.external.GetCostContractDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostContractDetailsResponse;
  return proto.external.GetCostContractDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostContractDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostContractDetailsResponse}
 */
proto.external.GetCostContractDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCostmanagerid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostContractDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostContractDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostContractDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostContractDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string costManagerID = 2;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getCostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setCostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string typeData = 6;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string startDate = 7;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string endDate = 8;
 * @return {string}
 */
proto.external.GetCostContractDetailsResponse.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostContractDetailsResponse} returns this
 */
proto.external.GetCostContractDetailsResponse.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostContractDetailsByCostManagerIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostContractDetailsByCostManagerIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostContractDetailsByCostManagerIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostContractDetailsByCostManagerIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractdetailsList: jspb.Message.toObjectList(msg.getContractdetailsList(),
        proto.external.GetCostContractDetailsResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostContractDetailsByCostManagerIDResponse}
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostContractDetailsByCostManagerIDResponse;
  return proto.external.GetCostContractDetailsByCostManagerIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostContractDetailsByCostManagerIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostContractDetailsByCostManagerIDResponse}
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetCostContractDetailsResponse;
        reader.readMessage(value, proto.external.GetCostContractDetailsResponse.deserializeBinaryFromReader);
        msg.addContractdetails(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostContractDetailsByCostManagerIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostContractDetailsByCostManagerIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetCostContractDetailsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetCostContractDetailsResponse contractDetails = 1;
 * @return {!Array<!proto.external.GetCostContractDetailsResponse>}
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.prototype.getContractdetailsList = function() {
  return /** @type{!Array<!proto.external.GetCostContractDetailsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetCostContractDetailsResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetCostContractDetailsResponse>} value
 * @return {!proto.external.GetCostContractDetailsByCostManagerIDResponse} returns this
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.prototype.setContractdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetCostContractDetailsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetCostContractDetailsResponse}
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.prototype.addContractdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetCostContractDetailsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCostContractDetailsByCostManagerIDResponse} returns this
 */
proto.external.GetCostContractDetailsByCostManagerIDResponse.prototype.clearContractdetailsList = function() {
  return this.setContractdetailsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateCostContractDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateCostContractDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateCostContractDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateCostContractDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      typedata: (f = msg.getTypedata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      startdate: (f = msg.getStartdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      enddate: (f = msg.getEnddate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateCostContractDetailsRequest}
 */
proto.external.UpdateCostContractDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateCostContractDetailsRequest;
  return proto.external.UpdateCostContractDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateCostContractDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateCostContractDetailsRequest}
 */
proto.external.UpdateCostContractDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setType(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTypedata(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStartdate(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateCostContractDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateCostContractDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateCostContractDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTypedata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue type = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue typeData = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.getTypedata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.setTypedata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.clearTypedata = function() {
  return this.setTypedata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.hasTypedata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue startDate = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue endDate = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostContractDetailsRequest} returns this
 */
proto.external.UpdateCostContractDetailsRequest.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostContractDetailsRequest.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 5) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateCostProviderResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateCostProviderResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateCostProviderResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateCostProviderResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateCostProviderResponse}
 */
proto.external.CreateCostProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateCostProviderResponse;
  return proto.external.CreateCostProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateCostProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateCostProviderResponse}
 */
proto.external.CreateCostProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateCostProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateCostProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateCostProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateCostProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateCostProviderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostProviderResponse} returns this
 */
proto.external.CreateCostProviderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateCostProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateCostProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateCostProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateCostProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      costmanagerid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      description: jspb.Message.getFieldWithDefault(msg, 3, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateCostProviderRequest}
 */
proto.external.CreateCostProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateCostProviderRequest;
  return proto.external.CreateCostProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateCostProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateCostProviderRequest}
 */
proto.external.CreateCostProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCostmanagerid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateCostProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateCostProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateCostProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateCostProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string costManagerID = 1;
 * @return {string}
 */
proto.external.CreateCostProviderRequest.prototype.getCostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostProviderRequest} returns this
 */
proto.external.CreateCostProviderRequest.prototype.setCostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.CreateCostProviderRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostProviderRequest} returns this
 */
proto.external.CreateCostProviderRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.external.CreateCostProviderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostProviderRequest} returns this
 */
proto.external.CreateCostProviderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CostProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CostProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CostProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CostProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CostProviderRequest}
 */
proto.external.CostProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CostProviderRequest;
  return proto.external.CostProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CostProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CostProviderRequest}
 */
proto.external.CostProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CostProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CostProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CostProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CostProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CostProviderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostProviderRequest} returns this
 */
proto.external.CostProviderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetCostProvidersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostProvidersResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostProvidersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostProvidersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostProvidersResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      costprovidersList: jspb.Message.toObjectList(msg.getCostprovidersList(),
        proto.external.GetCostProviderResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostProvidersResponse}
 */
proto.external.GetCostProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostProvidersResponse;
  return proto.external.GetCostProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostProvidersResponse}
 */
proto.external.GetCostProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetCostProviderResponse;
        reader.readMessage(value, proto.external.GetCostProviderResponse.deserializeBinaryFromReader);
        msg.addCostproviders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostprovidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetCostProviderResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetCostProviderResponse costProviders = 1;
 * @return {!Array<!proto.external.GetCostProviderResponse>}
 */
proto.external.GetCostProvidersResponse.prototype.getCostprovidersList = function() {
  return /** @type{!Array<!proto.external.GetCostProviderResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetCostProviderResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetCostProviderResponse>} value
 * @return {!proto.external.GetCostProvidersResponse} returns this
 */
proto.external.GetCostProvidersResponse.prototype.setCostprovidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetCostProviderResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetCostProviderResponse}
 */
proto.external.GetCostProvidersResponse.prototype.addCostproviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetCostProviderResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCostProvidersResponse} returns this
 */
proto.external.GetCostProvidersResponse.prototype.clearCostprovidersList = function() {
  return this.setCostprovidersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostProviderResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostProviderResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostProviderResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostProviderResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      costmanagerid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      name: jspb.Message.getFieldWithDefault(msg, 5, ''),
      description: jspb.Message.getFieldWithDefault(msg, 6, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostProviderResponse}
 */
proto.external.GetCostProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostProviderResponse;
  return proto.external.GetCostProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostProviderResponse}
 */
proto.external.GetCostProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCostmanagerid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetCostProviderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostProviderResponse} returns this
 */
proto.external.GetCostProviderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string costManagerID = 2;
 * @return {string}
 */
proto.external.GetCostProviderResponse.prototype.getCostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostProviderResponse} returns this
 */
proto.external.GetCostProviderResponse.prototype.setCostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetCostProviderResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostProviderResponse} returns this
 */
proto.external.GetCostProviderResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.GetCostProviderResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostProviderResponse} returns this
 */
proto.external.GetCostProviderResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.external.GetCostProviderResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostProviderResponse} returns this
 */
proto.external.GetCostProviderResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.external.GetCostProviderResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostProviderResponse} returns this
 */
proto.external.GetCostProviderResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateCostProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateCostProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateCostProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateCostProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateCostProviderRequest}
 */
proto.external.UpdateCostProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateCostProviderRequest;
  return proto.external.UpdateCostProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateCostProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateCostProviderRequest}
 */
proto.external.UpdateCostProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateCostProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateCostProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateCostProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateCostProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateCostProviderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateCostProviderRequest} returns this
 */
proto.external.UpdateCostProviderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostProviderRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostProviderRequest} returns this
 */
proto.external.UpdateCostProviderRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostProviderRequest} returns this
 */
proto.external.UpdateCostProviderRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostProviderRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostProviderRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostProviderRequest} returns this
 */
proto.external.UpdateCostProviderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostProviderRequest} returns this
 */
proto.external.UpdateCostProviderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostProviderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CostSupplierDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CostSupplierDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CostSupplierDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CostSupplierDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CostSupplierDetailsRequest}
 */
proto.external.CostSupplierDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CostSupplierDetailsRequest;
  return proto.external.CostSupplierDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CostSupplierDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CostSupplierDetailsRequest}
 */
proto.external.CostSupplierDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CostSupplierDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CostSupplierDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CostSupplierDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CostSupplierDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CostSupplierDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostSupplierDetailsRequest} returns this
 */
proto.external.CostSupplierDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateCostSupplierDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateCostSupplierDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateCostSupplierDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateCostSupplierDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      companyname: (f = msg.getCompanyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      contact: (f = msg.getContact()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      country: (f = msg.getCountry()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      postcode: (f = msg.getPostcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      street: (f = msg.getStreet()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      phone: (f = msg.getPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest}
 */
proto.external.UpdateCostSupplierDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateCostSupplierDetailsRequest;
  return proto.external.UpdateCostSupplierDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateCostSupplierDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest}
 */
proto.external.UpdateCostSupplierDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCity(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCompanyname(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setContact(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCountry(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEmail(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPostcode(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStreet(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateCostSupplierDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateCostSupplierDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateCostSupplierDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostcode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreet();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue city = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue companyName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getCompanyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearCompanyname = function() {
  return this.setCompanyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasCompanyname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue contact = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getContact = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue country = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getCountry = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue email = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue postcode = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getPostcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setPostcode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearPostcode = function() {
  return this.setPostcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasPostcode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue street = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getStreet = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setStreet = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearStreet = function() {
  return this.setStreet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasStreet = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue phone = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.getPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateCostSupplierDetailsRequest} returns this
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateCostSupplierDetailsRequest.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 9) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostSupplierDetailsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostSupplierDetailsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostSupplierDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostSupplierDetailsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      costmanagerid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      city: jspb.Message.getFieldWithDefault(msg, 5, ''),
      companyname: jspb.Message.getFieldWithDefault(msg, 6, ''),
      contact: jspb.Message.getFieldWithDefault(msg, 7, ''),
      country: jspb.Message.getFieldWithDefault(msg, 8, ''),
      email: jspb.Message.getFieldWithDefault(msg, 9, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 10, ''),
      street: jspb.Message.getFieldWithDefault(msg, 11, ''),
      phone: jspb.Message.getFieldWithDefault(msg, 12, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostSupplierDetailsResponse}
 */
proto.external.GetCostSupplierDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostSupplierDetailsResponse;
  return proto.external.GetCostSupplierDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostSupplierDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostSupplierDetailsResponse}
 */
proto.external.GetCostSupplierDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCostmanagerid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setContact(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostSupplierDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostSupplierDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostSupplierDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCostmanagerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContact();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationID = 3;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string costManagerID = 4;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getCostmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setCostmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string companyName = 6;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact = 7;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setContact = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string postcode = 10;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string street = 11;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.external.GetCostSupplierDetailsResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostSupplierDetailsResponse} returns this
 */
proto.external.GetCostSupplierDetailsResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CostManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CostManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CostManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CostManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CostManagerRequest}
 */
proto.external.CostManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CostManagerRequest;
  return proto.external.CostManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CostManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CostManagerRequest}
 */
proto.external.CostManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CostManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CostManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CostManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CostManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CostManagerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerRequest} returns this
 */
proto.external.CostManagerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CostManagerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CostManagerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CostManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CostManagerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      name: jspb.Message.getFieldWithDefault(msg, 4, ''),
      supplier: jspb.Message.getFieldWithDefault(msg, 5, ''),
      contractstart: jspb.Message.getFieldWithDefault(msg, 6, ''),
      contractend: jspb.Message.getFieldWithDefault(msg, 7, ''),
      contractvalue: jspb.Message.getFieldWithDefault(msg, 8, 0),
      paymentthisyear: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CostManagerResponse}
 */
proto.external.CostManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CostManagerResponse;
  return proto.external.CostManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CostManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CostManagerResponse}
 */
proto.external.CostManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setSupplier(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractstart(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractend(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setContractvalue(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPaymentthisyear(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CostManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CostManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CostManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CostManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContractstart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContractend();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContractvalue();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPaymentthisyear();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationID = 3;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier = 5;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contractStart = 6;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getContractstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setContractstart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contractEnd = 7;
 * @return {string}
 */
proto.external.CostManagerResponse.prototype.getContractend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setContractend = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 contractValue = 8;
 * @return {number}
 */
proto.external.CostManagerResponse.prototype.getContractvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setContractvalue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 paymentThisYear = 9;
 * @return {number}
 */
proto.external.CostManagerResponse.prototype.getPaymentthisyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CostManagerResponse} returns this
 */
proto.external.CostManagerResponse.prototype.setPaymentthisyear = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CostManagersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CostManagersResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CostManagersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CostManagersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CostManagersResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      costmanagersList: jspb.Message.toObjectList(msg.getCostmanagersList(),
        proto.external.CostManagerResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CostManagersResponse}
 */
proto.external.CostManagersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CostManagersResponse;
  return proto.external.CostManagersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CostManagersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CostManagersResponse}
 */
proto.external.CostManagersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CostManagerResponse;
        reader.readMessage(value, proto.external.CostManagerResponse.deserializeBinaryFromReader);
        msg.addCostmanagers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CostManagersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CostManagersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CostManagersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CostManagersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostmanagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CostManagerResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CostManagerResponse costManagers = 1;
 * @return {!Array<!proto.external.CostManagerResponse>}
 */
proto.external.CostManagersResponse.prototype.getCostmanagersList = function() {
  return /** @type{!Array<!proto.external.CostManagerResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CostManagerResponse, 1));
};


/**
 * @param {!Array<!proto.external.CostManagerResponse>} value
 * @return {!proto.external.CostManagersResponse} returns this
 */
proto.external.CostManagersResponse.prototype.setCostmanagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CostManagerResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CostManagerResponse}
 */
proto.external.CostManagersResponse.prototype.addCostmanagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CostManagerResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CostManagersResponse} returns this
 */
proto.external.CostManagersResponse.prototype.clearCostmanagersList = function() {
  return this.setCostmanagersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateCostManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateCostManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateCostManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateCostManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateCostManagerRequest}
 */
proto.external.CreateCostManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateCostManagerRequest;
  return proto.external.CreateCostManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateCostManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateCostManagerRequest}
 */
proto.external.CreateCostManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateCostManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateCostManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateCostManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateCostManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateCostManagerRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostManagerRequest} returns this
 */
proto.external.CreateCostManagerRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.CreateCostManagerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostManagerRequest} returns this
 */
proto.external.CreateCostManagerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateCostManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateCostManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateCostManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateCostManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateCostManagerRequest}
 */
proto.external.UpdateCostManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateCostManagerRequest;
  return proto.external.UpdateCostManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateCostManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateCostManagerRequest}
 */
proto.external.UpdateCostManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateCostManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateCostManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateCostManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateCostManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateCostManagerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateCostManagerRequest} returns this
 */
proto.external.UpdateCostManagerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.UpdateCostManagerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateCostManagerRequest} returns this
 */
proto.external.UpdateCostManagerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateCostManagerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateCostManagerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateCostManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateCostManagerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateCostManagerResponse}
 */
proto.external.CreateCostManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateCostManagerResponse;
  return proto.external.CreateCostManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateCostManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateCostManagerResponse}
 */
proto.external.CreateCostManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateCostManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateCostManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateCostManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateCostManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateCostManagerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateCostManagerResponse} returns this
 */
proto.external.CreateCostManagerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexContractDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexContractDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexContractDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexContractDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexContractDetailsRequest}
 */
proto.external.GetOpexContractDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexContractDetailsRequest;
  return proto.external.GetOpexContractDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexContractDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexContractDetailsRequest}
 */
proto.external.GetOpexContractDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexContractDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexContractDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexContractDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexContractDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetOpexContractDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsRequest} returns this
 */
proto.external.GetOpexContractDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexContractDetailsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexContractDetailsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexContractDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexContractDetailsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      opexmanagerid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      type: jspb.Message.getFieldWithDefault(msg, 5, ''),
      typedata: jspb.Message.getFieldWithDefault(msg, 6, ''),
      startdate: jspb.Message.getFieldWithDefault(msg, 7, ''),
      enddate: jspb.Message.getFieldWithDefault(msg, 8, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexContractDetailsResponse}
 */
proto.external.GetOpexContractDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexContractDetailsResponse;
  return proto.external.GetOpexContractDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexContractDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexContractDetailsResponse}
 */
proto.external.GetOpexContractDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setOpexmanagerid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setTypedata(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexContractDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexContractDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexContractDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexContractDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpexmanagerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTypedata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string opexManagerID = 2;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getOpexmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setOpexmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string typeData = 6;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getTypedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setTypedata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string startDate = 7;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string endDate = 8;
 * @return {string}
 */
proto.external.GetOpexContractDetailsResponse.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexContractDetailsResponse} returns this
 */
proto.external.GetOpexContractDetailsResponse.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexContractDetailsByOpexManagerIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      contractdetailsList: jspb.Message.toObjectList(msg.getContractdetailsList(),
        proto.external.GetOpexContractDetailsResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse}
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexContractDetailsByOpexManagerIDResponse;
  return proto.external.GetOpexContractDetailsByOpexManagerIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse}
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetOpexContractDetailsResponse;
        reader.readMessage(value, proto.external.GetOpexContractDetailsResponse.deserializeBinaryFromReader);
        msg.addContractdetails(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetOpexContractDetailsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetOpexContractDetailsResponse contractDetails = 1;
 * @return {!Array<!proto.external.GetOpexContractDetailsResponse>}
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.prototype.getContractdetailsList = function() {
  return /** @type{!Array<!proto.external.GetOpexContractDetailsResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetOpexContractDetailsResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetOpexContractDetailsResponse>} value
 * @return {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse} returns this
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.prototype.setContractdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetOpexContractDetailsResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetOpexContractDetailsResponse}
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.prototype.addContractdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetOpexContractDetailsResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetOpexContractDetailsByOpexManagerIDResponse} returns this
 */
proto.external.GetOpexContractDetailsByOpexManagerIDResponse.prototype.clearContractdetailsList = function() {
  return this.setContractdetailsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateOpexContractDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateOpexContractDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateOpexContractDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateOpexContractDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      typedata: (f = msg.getTypedata()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      startdate: (f = msg.getStartdate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      enddate: (f = msg.getEnddate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateOpexContractDetailsRequest}
 */
proto.external.UpdateOpexContractDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateOpexContractDetailsRequest;
  return proto.external.UpdateOpexContractDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateOpexContractDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateOpexContractDetailsRequest}
 */
proto.external.UpdateOpexContractDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setType(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setTypedata(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStartdate(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEnddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateOpexContractDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateOpexContractDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateOpexContractDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTypedata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue type = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue typeData = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.getTypedata = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.setTypedata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.clearTypedata = function() {
  return this.setTypedata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.hasTypedata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue startDate = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue endDate = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexContractDetailsRequest} returns this
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexContractDetailsRequest.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 5) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateOpexProviderResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateOpexProviderResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateOpexProviderResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateOpexProviderResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateOpexProviderResponse}
 */
proto.external.CreateOpexProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateOpexProviderResponse;
  return proto.external.CreateOpexProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateOpexProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateOpexProviderResponse}
 */
proto.external.CreateOpexProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateOpexProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateOpexProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateOpexProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateOpexProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateOpexProviderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexProviderResponse} returns this
 */
proto.external.CreateOpexProviderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateOpexProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateOpexProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateOpexProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateOpexProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      opexmanagerid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      description: jspb.Message.getFieldWithDefault(msg, 3, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateOpexProviderRequest}
 */
proto.external.CreateOpexProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateOpexProviderRequest;
  return proto.external.CreateOpexProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateOpexProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateOpexProviderRequest}
 */
proto.external.CreateOpexProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setOpexmanagerid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateOpexProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateOpexProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateOpexProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateOpexProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpexmanagerid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string opexManagerID = 1;
 * @return {string}
 */
proto.external.CreateOpexProviderRequest.prototype.getOpexmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexProviderRequest} returns this
 */
proto.external.CreateOpexProviderRequest.prototype.setOpexmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.CreateOpexProviderRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexProviderRequest} returns this
 */
proto.external.CreateOpexProviderRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.external.CreateOpexProviderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexProviderRequest} returns this
 */
proto.external.CreateOpexProviderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.OpexProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.OpexProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.OpexProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.OpexProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.OpexProviderRequest}
 */
proto.external.OpexProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.OpexProviderRequest;
  return proto.external.OpexProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.OpexProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.OpexProviderRequest}
 */
proto.external.OpexProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.OpexProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.OpexProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.OpexProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OpexProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.OpexProviderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexProviderRequest} returns this
 */
proto.external.OpexProviderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetOpexProvidersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexProvidersResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexProvidersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexProvidersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexProvidersResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      opexprovidersList: jspb.Message.toObjectList(msg.getOpexprovidersList(),
        proto.external.GetOpexProviderResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexProvidersResponse}
 */
proto.external.GetOpexProvidersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexProvidersResponse;
  return proto.external.GetOpexProvidersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexProvidersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexProvidersResponse}
 */
proto.external.GetOpexProvidersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetOpexProviderResponse;
        reader.readMessage(value, proto.external.GetOpexProviderResponse.deserializeBinaryFromReader);
        msg.addOpexproviders(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexProvidersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexProvidersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexProvidersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexProvidersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpexprovidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetOpexProviderResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetOpexProviderResponse opexProviders = 1;
 * @return {!Array<!proto.external.GetOpexProviderResponse>}
 */
proto.external.GetOpexProvidersResponse.prototype.getOpexprovidersList = function() {
  return /** @type{!Array<!proto.external.GetOpexProviderResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetOpexProviderResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetOpexProviderResponse>} value
 * @return {!proto.external.GetOpexProvidersResponse} returns this
 */
proto.external.GetOpexProvidersResponse.prototype.setOpexprovidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetOpexProviderResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetOpexProviderResponse}
 */
proto.external.GetOpexProvidersResponse.prototype.addOpexproviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetOpexProviderResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetOpexProvidersResponse} returns this
 */
proto.external.GetOpexProvidersResponse.prototype.clearOpexprovidersList = function() {
  return this.setOpexprovidersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexProviderResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexProviderResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexProviderResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexProviderResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      opexmanagerid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      name: jspb.Message.getFieldWithDefault(msg, 5, ''),
      description: jspb.Message.getFieldWithDefault(msg, 6, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexProviderResponse}
 */
proto.external.GetOpexProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexProviderResponse;
  return proto.external.GetOpexProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexProviderResponse}
 */
proto.external.GetOpexProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setOpexmanagerid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpexmanagerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetOpexProviderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexProviderResponse} returns this
 */
proto.external.GetOpexProviderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string opexManagerID = 2;
 * @return {string}
 */
proto.external.GetOpexProviderResponse.prototype.getOpexmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexProviderResponse} returns this
 */
proto.external.GetOpexProviderResponse.prototype.setOpexmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assetID = 3;
 * @return {string}
 */
proto.external.GetOpexProviderResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexProviderResponse} returns this
 */
proto.external.GetOpexProviderResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationID = 4;
 * @return {string}
 */
proto.external.GetOpexProviderResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexProviderResponse} returns this
 */
proto.external.GetOpexProviderResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.external.GetOpexProviderResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexProviderResponse} returns this
 */
proto.external.GetOpexProviderResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.external.GetOpexProviderResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexProviderResponse} returns this
 */
proto.external.GetOpexProviderResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateOpexProviderRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateOpexProviderRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateOpexProviderRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateOpexProviderRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateOpexProviderRequest}
 */
proto.external.UpdateOpexProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateOpexProviderRequest;
  return proto.external.UpdateOpexProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateOpexProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateOpexProviderRequest}
 */
proto.external.UpdateOpexProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateOpexProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateOpexProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateOpexProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateOpexProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateOpexProviderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateOpexProviderRequest} returns this
 */
proto.external.UpdateOpexProviderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexProviderRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexProviderRequest} returns this
 */
proto.external.UpdateOpexProviderRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexProviderRequest} returns this
 */
proto.external.UpdateOpexProviderRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexProviderRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexProviderRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexProviderRequest} returns this
 */
proto.external.UpdateOpexProviderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexProviderRequest} returns this
 */
proto.external.UpdateOpexProviderRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexProviderRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.OpexSupplierDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.OpexSupplierDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.OpexSupplierDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.OpexSupplierDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.OpexSupplierDetailsRequest}
 */
proto.external.OpexSupplierDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.OpexSupplierDetailsRequest;
  return proto.external.OpexSupplierDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.OpexSupplierDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.OpexSupplierDetailsRequest}
 */
proto.external.OpexSupplierDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.OpexSupplierDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.OpexSupplierDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.OpexSupplierDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OpexSupplierDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.OpexSupplierDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexSupplierDetailsRequest} returns this
 */
proto.external.OpexSupplierDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateOpexSupplierDetailsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateOpexSupplierDetailsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateOpexSupplierDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateOpexSupplierDetailsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      companyname: (f = msg.getCompanyname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      contact: (f = msg.getContact()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      country: (f = msg.getCountry()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      postcode: (f = msg.getPostcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      street: (f = msg.getStreet()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      phone: (f = msg.getPhone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest}
 */
proto.external.UpdateOpexSupplierDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateOpexSupplierDetailsRequest;
  return proto.external.UpdateOpexSupplierDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateOpexSupplierDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest}
 */
proto.external.UpdateOpexSupplierDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCity(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCompanyname(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setContact(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCountry(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEmail(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPostcode(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStreet(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateOpexSupplierDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateOpexSupplierDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateOpexSupplierDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCompanyname();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostcode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreet();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhone();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue city = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue companyName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getCompanyname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setCompanyname = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearCompanyname = function() {
  return this.setCompanyname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasCompanyname = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue contact = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getContact = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue country = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getCountry = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setCountry = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearCountry = function() {
  return this.setCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasCountry = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue email = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue postcode = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getPostcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setPostcode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearPostcode = function() {
  return this.setPostcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasPostcode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue street = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getStreet = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setStreet = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearStreet = function() {
  return this.setStreet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasStreet = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue phone = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.getPhone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.setPhone = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateOpexSupplierDetailsRequest} returns this
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.clearPhone = function() {
  return this.setPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateOpexSupplierDetailsRequest.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 9) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexSupplierDetailsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexSupplierDetailsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexSupplierDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexSupplierDetailsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      opexmanagerid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      city: jspb.Message.getFieldWithDefault(msg, 5, ''),
      companyname: jspb.Message.getFieldWithDefault(msg, 6, ''),
      contact: jspb.Message.getFieldWithDefault(msg, 7, ''),
      country: jspb.Message.getFieldWithDefault(msg, 8, ''),
      email: jspb.Message.getFieldWithDefault(msg, 9, ''),
      postcode: jspb.Message.getFieldWithDefault(msg, 10, ''),
      street: jspb.Message.getFieldWithDefault(msg, 11, ''),
      phone: jspb.Message.getFieldWithDefault(msg, 12, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexSupplierDetailsResponse}
 */
proto.external.GetOpexSupplierDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexSupplierDetailsResponse;
  return proto.external.GetOpexSupplierDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexSupplierDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexSupplierDetailsResponse}
 */
proto.external.GetOpexSupplierDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOpexmanagerid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCity(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCompanyname(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setContact(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountry(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setEmail(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setPostcode(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setStreet(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setPhone(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexSupplierDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexSupplierDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexSupplierDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOpexmanagerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompanyname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContact();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostcode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationID = 3;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string opexManagerID = 4;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getOpexmanagerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setOpexmanagerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string companyName = 6;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getCompanyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setCompanyname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact = 7;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setContact = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string postcode = 10;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getPostcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setPostcode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string street = 11;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexSupplierDetailsResponse} returns this
 */
proto.external.GetOpexSupplierDetailsResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.OpexManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.OpexManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.OpexManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.OpexManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.OpexManagerRequest}
 */
proto.external.OpexManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.OpexManagerRequest;
  return proto.external.OpexManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.OpexManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.OpexManagerRequest}
 */
proto.external.OpexManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.OpexManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.OpexManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.OpexManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OpexManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.OpexManagerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerRequest} returns this
 */
proto.external.OpexManagerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.OpexManagerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.OpexManagerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.OpexManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.OpexManagerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      organizationid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      name: jspb.Message.getFieldWithDefault(msg, 4, ''),
      supplier: jspb.Message.getFieldWithDefault(msg, 5, ''),
      contractstart: jspb.Message.getFieldWithDefault(msg, 6, ''),
      contractend: jspb.Message.getFieldWithDefault(msg, 7, ''),
      contractvalue: jspb.Message.getFieldWithDefault(msg, 8, 0),
      paymentthisyear: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.OpexManagerResponse}
 */
proto.external.OpexManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.OpexManagerResponse;
  return proto.external.OpexManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.OpexManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.OpexManagerResponse}
 */
proto.external.OpexManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOrganizationid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setSupplier(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractstart(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setContractend(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setContractvalue(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setPaymentthisyear(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.OpexManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.OpexManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.OpexManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OpexManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContractstart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContractend();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContractvalue();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPaymentthisyear();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationID = 3;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string supplier = 5;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contractStart = 6;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getContractstart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setContractstart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contractEnd = 7;
 * @return {string}
 */
proto.external.OpexManagerResponse.prototype.getContractend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setContractend = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 contractValue = 8;
 * @return {number}
 */
proto.external.OpexManagerResponse.prototype.getContractvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setContractvalue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 paymentThisYear = 9;
 * @return {number}
 */
proto.external.OpexManagerResponse.prototype.getPaymentthisyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.OpexManagerResponse} returns this
 */
proto.external.OpexManagerResponse.prototype.setPaymentthisyear = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.OpexManagersResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.OpexManagersResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.OpexManagersResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.OpexManagersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.OpexManagersResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      opexmanagersList: jspb.Message.toObjectList(msg.getOpexmanagersList(),
        proto.external.OpexManagerResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.OpexManagersResponse}
 */
proto.external.OpexManagersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.OpexManagersResponse;
  return proto.external.OpexManagersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.OpexManagersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.OpexManagersResponse}
 */
proto.external.OpexManagersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.OpexManagerResponse;
        reader.readMessage(value, proto.external.OpexManagerResponse.deserializeBinaryFromReader);
        msg.addOpexmanagers(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.OpexManagersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.OpexManagersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.OpexManagersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OpexManagersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpexmanagersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.OpexManagerResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OpexManagerResponse opexManagers = 1;
 * @return {!Array<!proto.external.OpexManagerResponse>}
 */
proto.external.OpexManagersResponse.prototype.getOpexmanagersList = function() {
  return /** @type{!Array<!proto.external.OpexManagerResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.OpexManagerResponse, 1));
};


/**
 * @param {!Array<!proto.external.OpexManagerResponse>} value
 * @return {!proto.external.OpexManagersResponse} returns this
 */
proto.external.OpexManagersResponse.prototype.setOpexmanagersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.OpexManagerResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.OpexManagerResponse}
 */
proto.external.OpexManagersResponse.prototype.addOpexmanagers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.OpexManagerResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.OpexManagersResponse} returns this
 */
proto.external.OpexManagersResponse.prototype.clearOpexmanagersList = function() {
  return this.setOpexmanagersList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateOpexManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateOpexManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateOpexManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateOpexManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateOpexManagerRequest}
 */
proto.external.CreateOpexManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateOpexManagerRequest;
  return proto.external.CreateOpexManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateOpexManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateOpexManagerRequest}
 */
proto.external.CreateOpexManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateOpexManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateOpexManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateOpexManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateOpexManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateOpexManagerRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexManagerRequest} returns this
 */
proto.external.CreateOpexManagerRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.CreateOpexManagerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexManagerRequest} returns this
 */
proto.external.CreateOpexManagerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateOpexManagerRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateOpexManagerRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateOpexManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateOpexManagerRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateOpexManagerRequest}
 */
proto.external.UpdateOpexManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateOpexManagerRequest;
  return proto.external.UpdateOpexManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateOpexManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateOpexManagerRequest}
 */
proto.external.UpdateOpexManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateOpexManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateOpexManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateOpexManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateOpexManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateOpexManagerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateOpexManagerRequest} returns this
 */
proto.external.UpdateOpexManagerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.UpdateOpexManagerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateOpexManagerRequest} returns this
 */
proto.external.UpdateOpexManagerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateOpexManagerResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateOpexManagerResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateOpexManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateOpexManagerResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateOpexManagerResponse}
 */
proto.external.CreateOpexManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateOpexManagerResponse;
  return proto.external.CreateOpexManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateOpexManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateOpexManagerResponse}
 */
proto.external.CreateOpexManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateOpexManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateOpexManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateOpexManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateOpexManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateOpexManagerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateOpexManagerResponse} returns this
 */
proto.external.CreateOpexManagerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.Footfall.prototype.toObject = function(opt_includeInstance) {
    return proto.external.Footfall.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.Footfall} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.Footfall.toObject = function(includeInstance, msg) {
    var f, obj = {
      footfallcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      date: jspb.Message.getFieldWithDefault(msg, 2, ''),
      id: jspb.Message.getFieldWithDefault(msg, 3, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Footfall}
 */
proto.external.Footfall.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Footfall;
  return proto.external.Footfall.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Footfall} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Footfall}
 */
proto.external.Footfall.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFootfallcount(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Footfall.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Footfall.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Footfall} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Footfall.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFootfallcount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 footfallCount = 1;
 * @return {number}
 */
proto.external.Footfall.prototype.getFootfallcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.Footfall} returns this
 */
proto.external.Footfall.prototype.setFootfallcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.external.Footfall.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Footfall} returns this
 */
proto.external.Footfall.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.external.Footfall.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Footfall} returns this
 */
proto.external.Footfall.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFootfallsByAssetAndMonthRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFootfallsByAssetAndMonthRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFootfallsByAssetAndMonthRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      yearmonth: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFootfallsByAssetAndMonthRequest}
 */
proto.external.GetFootfallsByAssetAndMonthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFootfallsByAssetAndMonthRequest;
  return proto.external.GetFootfallsByAssetAndMonthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFootfallsByAssetAndMonthRequest}
 */
proto.external.GetFootfallsByAssetAndMonthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setYearmonth(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFootfallsByAssetAndMonthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFootfallsByAssetAndMonthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFootfallsByAssetAndMonthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYearmonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetFootfallsByAssetAndMonthRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFootfallsByAssetAndMonthRequest} returns this
 */
proto.external.GetFootfallsByAssetAndMonthRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string yearMonth = 2;
 * @return {string}
 */
proto.external.GetFootfallsByAssetAndMonthRequest.prototype.getYearmonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFootfallsByAssetAndMonthRequest} returns this
 */
proto.external.GetFootfallsByAssetAndMonthRequest.prototype.setYearmonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFootfallsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFootfallsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFootfallsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFootfallsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFootfallsRequest}
 */
proto.external.GetFootfallsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFootfallsRequest;
  return proto.external.GetFootfallsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFootfallsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFootfallsRequest}
 */
proto.external.GetFootfallsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFootfallsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFootfallsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFootfallsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFootfallsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetFootfallsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFootfallsRequest} returns this
 */
proto.external.GetFootfallsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetFootfallsResponse.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFootfallsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFootfallsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFootfallsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFootfallsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      footfallsList: jspb.Message.toObjectList(msg.getFootfallsList(),
        proto.external.Footfall.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFootfallsResponse}
 */
proto.external.GetFootfallsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFootfallsResponse;
  return proto.external.GetFootfallsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFootfallsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFootfallsResponse}
 */
proto.external.GetFootfallsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = new proto.external.Footfall;
        reader.readMessage(value, proto.external.Footfall.deserializeBinaryFromReader);
        msg.addFootfalls(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFootfallsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFootfallsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFootfallsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFootfallsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFootfallsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.Footfall.serializeBinaryToWriter
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetFootfallsResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFootfallsResponse} returns this
 */
proto.external.GetFootfallsResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Footfall footfalls = 2;
 * @return {!Array<!proto.external.Footfall>}
 */
proto.external.GetFootfallsResponse.prototype.getFootfallsList = function() {
  return /** @type{!Array<!proto.external.Footfall>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Footfall, 2));
};


/**
 * @param {!Array<!proto.external.Footfall>} value
 * @return {!proto.external.GetFootfallsResponse} returns this
 */
proto.external.GetFootfallsResponse.prototype.setFootfallsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.Footfall=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Footfall}
 */
proto.external.GetFootfallsResponse.prototype.addFootfalls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.Footfall, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetFootfallsResponse} returns this
 */
proto.external.GetFootfallsResponse.prototype.clearFootfallsList = function() {
  return this.setFootfallsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateFootfallRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateFootfallRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateFootfallRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateFootfallRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      date: (f = msg.getDate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      footfall: (f = msg.getFootfall()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateFootfallRequest}
 */
proto.external.UpdateFootfallRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateFootfallRequest;
  return proto.external.UpdateFootfallRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateFootfallRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateFootfallRequest}
 */
proto.external.UpdateFootfallRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDate(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value, google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setFootfall(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateFootfallRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateFootfallRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateFootfallRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateFootfallRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFootfall();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateFootfallRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateFootfallRequest} returns this
 */
proto.external.UpdateFootfallRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue date = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFootfallRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFootfallRequest} returns this
 */
proto.external.UpdateFootfallRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFootfallRequest} returns this
 */
proto.external.UpdateFootfallRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFootfallRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value footfall = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.external.UpdateFootfallRequest.prototype.getFootfall = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.external.UpdateFootfallRequest} returns this
 */
proto.external.UpdateFootfallRequest.prototype.setFootfall = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFootfallRequest} returns this
 */
proto.external.UpdateFootfallRequest.prototype.clearFootfall = function() {
  return this.setFootfall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFootfallRequest.prototype.hasFootfall = function() {
  return jspb.Message.getField(this, 3) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateFootfallRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateFootfallRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateFootfallRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateFootfallRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      footfallcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateFootfallRequest}
 */
proto.external.CreateFootfallRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateFootfallRequest;
  return proto.external.CreateFootfallRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateFootfallRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateFootfallRequest}
 */
proto.external.CreateFootfallRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFootfallcount(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateFootfallRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateFootfallRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateFootfallRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateFootfallRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFootfallcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateFootfallRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFootfallRequest} returns this
 */
proto.external.CreateFootfallRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 footfallCount = 2;
 * @return {number}
 */
proto.external.CreateFootfallRequest.prototype.getFootfallcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateFootfallRequest} returns this
 */
proto.external.CreateFootfallRequest.prototype.setFootfallcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.external.CreateFootfallRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFootfallRequest} returns this
 */
proto.external.CreateFootfallRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateFootfallResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateFootfallResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateFootfallResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateFootfallResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateFootfallResponse}
 */
proto.external.CreateFootfallResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateFootfallResponse;
  return proto.external.CreateFootfallResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateFootfallResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateFootfallResponse}
 */
proto.external.CreateFootfallResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateFootfallResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateFootfallResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateFootfallResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateFootfallResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateFootfallResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFootfallResponse} returns this
 */
proto.external.CreateFootfallResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexManagerByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexManagerByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexManagerByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexManagerByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexManagerByAssetIDRequest}
 */
proto.external.GetOpexManagerByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexManagerByAssetIDRequest;
  return proto.external.GetOpexManagerByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexManagerByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexManagerByAssetIDRequest}
 */
proto.external.GetOpexManagerByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexManagerByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexManagerByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexManagerByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexManagerByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetOpexManagerByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetOpexManagerByAssetIDRequest} returns this
 */
proto.external.GetOpexManagerByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostManagerByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostManagerByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostManagerByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostManagerByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostManagerByAssetIDRequest}
 */
proto.external.GetCostManagerByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostManagerByAssetIDRequest;
  return proto.external.GetCostManagerByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostManagerByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostManagerByAssetIDRequest}
 */
proto.external.GetCostManagerByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostManagerByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostManagerByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostManagerByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostManagerByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetCostManagerByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetCostManagerByAssetIDRequest} returns this
 */
proto.external.GetCostManagerByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetCostManagerByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetCostManagerByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetCostManagerByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetCostManagerByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetCostManagerByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
        proto.external.CashFlowRow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetCostManagerByAssetIDResponse}
 */
proto.external.GetCostManagerByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetCostManagerByAssetIDResponse;
  return proto.external.GetCostManagerByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetCostManagerByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetCostManagerByAssetIDResponse}
 */
proto.external.GetCostManagerByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CashFlowRow;
        reader.readMessage(value, proto.external.CashFlowRow.deserializeBinaryFromReader);
        msg.addItems(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetCostManagerByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetCostManagerByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetCostManagerByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetCostManagerByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlowRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlowRow items = 1;
 * @return {!Array<!proto.external.CashFlowRow>}
 */
proto.external.GetCostManagerByAssetIDResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.external.CashFlowRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowRow, 1));
};


/**
 * @param {!Array<!proto.external.CashFlowRow>} value
 * @return {!proto.external.GetCostManagerByAssetIDResponse} returns this
 */
proto.external.GetCostManagerByAssetIDResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlowRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowRow}
 */
proto.external.GetCostManagerByAssetIDResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlowRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetCostManagerByAssetIDResponse} returns this
 */
proto.external.GetCostManagerByAssetIDResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetOpexManagerByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetOpexManagerByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetOpexManagerByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetOpexManagerByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetOpexManagerByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
        proto.external.CashFlowRow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetOpexManagerByAssetIDResponse}
 */
proto.external.GetOpexManagerByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetOpexManagerByAssetIDResponse;
  return proto.external.GetOpexManagerByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetOpexManagerByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetOpexManagerByAssetIDResponse}
 */
proto.external.GetOpexManagerByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CashFlowRow;
        reader.readMessage(value, proto.external.CashFlowRow.deserializeBinaryFromReader);
        msg.addItems(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetOpexManagerByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetOpexManagerByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetOpexManagerByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetOpexManagerByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CashFlowRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashFlowRow items = 1;
 * @return {!Array<!proto.external.CashFlowRow>}
 */
proto.external.GetOpexManagerByAssetIDResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.external.CashFlowRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowRow, 1));
};


/**
 * @param {!Array<!proto.external.CashFlowRow>} value
 * @return {!proto.external.GetOpexManagerByAssetIDResponse} returns this
 */
proto.external.GetOpexManagerByAssetIDResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CashFlowRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowRow}
 */
proto.external.GetOpexManagerByAssetIDResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CashFlowRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetOpexManagerByAssetIDResponse} returns this
 */
proto.external.GetOpexManagerByAssetIDResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CashFlowCell.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CashFlowCell.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CashFlowCell} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CashFlowCell.toObject = function(includeInstance, msg) {
    var f, obj = {
      month: jspb.Message.getFieldWithDefault(msg, 1, 0),
      value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowCell}
 */
proto.external.CashFlowCell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowCell;
  return proto.external.CashFlowCell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowCell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowCell}
 */
proto.external.CashFlowCell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowCell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowCell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowCell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowCell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 month = 1;
 * @return {number}
 */
proto.external.CashFlowCell.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowCell} returns this
 */
proto.external.CashFlowCell.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.external.CashFlowCell.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CashFlowCell} returns this
 */
proto.external.CashFlowCell.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CashFlowRow.repeatedFields_ = [2];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CashFlowRow.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CashFlowRow.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CashFlowRow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CashFlowRow.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ''),
      rowList: jspb.Message.toObjectList(msg.getRowList(),
        proto.external.CashFlowCell.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CashFlowRow}
 */
proto.external.CashFlowRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CashFlowRow;
  return proto.external.CashFlowRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CashFlowRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CashFlowRow}
 */
proto.external.CashFlowRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = new proto.external.CashFlowCell;
        reader.readMessage(value, proto.external.CashFlowCell.deserializeBinaryFromReader);
        msg.addRow(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CashFlowRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CashFlowRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CashFlowRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CashFlowRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.CashFlowCell.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.CashFlowRow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CashFlowRow} returns this
 */
proto.external.CashFlowRow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CashFlowCell row = 2;
 * @return {!Array<!proto.external.CashFlowCell>}
 */
proto.external.CashFlowRow.prototype.getRowList = function() {
  return /** @type{!Array<!proto.external.CashFlowCell>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CashFlowCell, 2));
};


/**
 * @param {!Array<!proto.external.CashFlowCell>} value
 * @return {!proto.external.CashFlowRow} returns this
 */
proto.external.CashFlowRow.prototype.setRowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.CashFlowCell=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CashFlowCell}
 */
proto.external.CashFlowRow.prototype.addRow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.CashFlowCell, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CashFlowRow} returns this
 */
proto.external.CashFlowRow.prototype.clearRowList = function() {
  return this.setRowList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFacilityManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFacilityManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFacilityManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFacilityManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFacilityManagerTicketRequest}
 */
proto.external.GetFacilityManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFacilityManagerTicketRequest;
  return proto.external.GetFacilityManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFacilityManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFacilityManagerTicketRequest}
 */
proto.external.GetFacilityManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFacilityManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFacilityManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFacilityManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFacilityManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketRequest} returns this
 */
proto.external.GetFacilityManagerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFacilityManagerTicketResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFacilityManagerTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFacilityManagerTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFacilityManagerTicketResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      queryname: jspb.Message.getFieldWithDefault(msg, 4, ''),
      duedate: jspb.Message.getFieldWithDefault(msg, 5, ''),
      location: jspb.Message.getFieldWithDefault(msg, 6, ''),
      status: jspb.Message.getFieldWithDefault(msg, 7, 0),
      description: jspb.Message.getFieldWithDefault(msg, 8, ''),
      caseref: jspb.Message.getFieldWithDefault(msg, 9, ''),
      createddate: jspb.Message.getFieldWithDefault(msg, 10, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFacilityManagerTicketResponse}
 */
proto.external.GetFacilityManagerTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFacilityManagerTicketResponse;
  return proto.external.GetFacilityManagerTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFacilityManagerTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFacilityManagerTicketResponse}
 */
proto.external.GetFacilityManagerTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setQueryname(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setDuedate(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setLocation(value);
        break;
      case 7:
        var value = /** @type {!proto.external.Status} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseref(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCreateddate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFacilityManagerTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFacilityManagerTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFacilityManagerTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQueryname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDuedate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCaseref();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreateddate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userID = 3;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string queryName = 4;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getQueryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setQueryname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dueDate = 5;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getDuedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setDuedate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string location = 6;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Status status = 7;
 * @return {!proto.external.Status}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getStatus = function() {
  return /** @type {!proto.external.Status} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.external.Status} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string caseRef = 9;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getCaseref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setCaseref = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string createdDate = 10;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketResponse.prototype.getCreateddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketResponse} returns this
 */
proto.external.GetFacilityManagerTicketResponse.prototype.setCreateddate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFacilityManagerTicketByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFacilityManagerTicketByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFacilityManagerTicketByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDRequest}
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFacilityManagerTicketByAssetIDRequest;
  return proto.external.GetFacilityManagerTicketByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDRequest}
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFacilityManagerTicketByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDRequest} returns this
 */
proto.external.GetFacilityManagerTicketByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetFacilityManagerTicketByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetFacilityManagerTicketByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetFacilityManagerTicketByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetFacilityManagerTicketByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
        proto.external.GetFacilityManagerTicketResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDResponse}
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetFacilityManagerTicketByAssetIDResponse;
  return proto.external.GetFacilityManagerTicketByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetFacilityManagerTicketByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDResponse}
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetFacilityManagerTicketResponse;
        reader.readMessage(value, proto.external.GetFacilityManagerTicketResponse.deserializeBinaryFromReader);
        msg.addTickets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetFacilityManagerTicketByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetFacilityManagerTicketByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetFacilityManagerTicketResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetFacilityManagerTicketResponse tickets = 1;
 * @return {!Array<!proto.external.GetFacilityManagerTicketResponse>}
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.external.GetFacilityManagerTicketResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetFacilityManagerTicketResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetFacilityManagerTicketResponse>} value
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDResponse} returns this
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetFacilityManagerTicketResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetFacilityManagerTicketResponse}
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetFacilityManagerTicketResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetFacilityManagerTicketByAssetIDResponse} returns this
 */
proto.external.GetFacilityManagerTicketByAssetIDResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateFacilityManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateFacilityManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateFacilityManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateFacilityManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      queryname: jspb.Message.getFieldWithDefault(msg, 3, ''),
      duedate: jspb.Message.getFieldWithDefault(msg, 4, ''),
      location: jspb.Message.getFieldWithDefault(msg, 5, ''),
      status: jspb.Message.getFieldWithDefault(msg, 6, 0),
      description: jspb.Message.getFieldWithDefault(msg, 7, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateFacilityManagerTicketRequest}
 */
proto.external.CreateFacilityManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateFacilityManagerTicketRequest;
  return proto.external.CreateFacilityManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateFacilityManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateFacilityManagerTicketRequest}
 */
proto.external.CreateFacilityManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setQueryname(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDuedate(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setLocation(value);
        break;
      case 6:
        var value = /** @type {!proto.external.Status} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateFacilityManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateFacilityManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateFacilityManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQueryname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDuedate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userID = 2;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string queryName = 3;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getQueryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setQueryname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dueDate = 4;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getDuedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setDuedate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location = 5;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Status status = 6;
 * @return {!proto.external.Status}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getStatus = function() {
  return /** @type {!proto.external.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.external.Status} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketRequest} returns this
 */
proto.external.CreateFacilityManagerTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateFacilityManagerTicketResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateFacilityManagerTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateFacilityManagerTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateFacilityManagerTicketResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      caseref: jspb.Message.getFieldWithDefault(msg, 2, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateFacilityManagerTicketResponse}
 */
proto.external.CreateFacilityManagerTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateFacilityManagerTicketResponse;
  return proto.external.CreateFacilityManagerTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateFacilityManagerTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateFacilityManagerTicketResponse}
 */
proto.external.CreateFacilityManagerTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseref(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateFacilityManagerTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateFacilityManagerTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateFacilityManagerTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateFacilityManagerTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaseref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketResponse} returns this
 */
proto.external.CreateFacilityManagerTicketResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caseRef = 2;
 * @return {string}
 */
proto.external.CreateFacilityManagerTicketResponse.prototype.getCaseref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateFacilityManagerTicketResponse} returns this
 */
proto.external.CreateFacilityManagerTicketResponse.prototype.setCaseref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateFacilityManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateFacilityManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateFacilityManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateFacilityManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: (f = msg.getAssetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      userid: (f = msg.getUserid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      queryname: (f = msg.getQueryname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      duedate: (f = msg.getDuedate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      location: (f = msg.getLocation()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest}
 */
proto.external.UpdateFacilityManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateFacilityManagerTicketRequest;
  return proto.external.UpdateFacilityManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateFacilityManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest}
 */
proto.external.UpdateFacilityManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setAssetid(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setUserid(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setQueryname(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDuedate(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setLocation(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStatus(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateFacilityManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateFacilityManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateFacilityManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQueryname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDuedate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue assetId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getAssetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearAssetid = function() {
  return this.setAssetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasAssetid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue userId = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getUserid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue queryName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getQueryname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setQueryname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearQueryname = function() {
  return this.setQueryname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasQueryname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue dueDate = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getDuedate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setDuedate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearDuedate = function() {
  return this.setDuedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasDuedate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue location = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getLocation = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue status = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue description = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateFacilityManagerTicketRequest} returns this
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateFacilityManagerTicketRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteFacilityManagerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteFacilityManagerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteFacilityManagerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteFacilityManagerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteFacilityManagerTicketRequest}
 */
proto.external.DeleteFacilityManagerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteFacilityManagerTicketRequest;
  return proto.external.DeleteFacilityManagerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteFacilityManagerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteFacilityManagerTicketRequest}
 */
proto.external.DeleteFacilityManagerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteFacilityManagerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteFacilityManagerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteFacilityManagerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteFacilityManagerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteFacilityManagerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteFacilityManagerTicketRequest} returns this
 */
proto.external.DeleteFacilityManagerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateLegalTrackerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateLegalTrackerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateLegalTrackerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateLegalTrackerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, ''),
      userid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      caseref: jspb.Message.getFieldWithDefault(msg, 3, ''),
      status: jspb.Message.getFieldWithDefault(msg, 4, 0),
      claimant: jspb.Message.getFieldWithDefault(msg, 5, ''),
      party: jspb.Message.getFieldWithDefault(msg, 6, ''),
      category: jspb.Message.getFieldWithDefault(msg, 7, 0),
      foragainst: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      expecteddate: jspb.Message.getFieldWithDefault(msg, 9, ''),
      description: jspb.Message.getFieldWithDefault(msg, 10, ''),
      directorate: jspb.Message.getFieldWithDefault(msg, 11, ''),
      estimatedpayment: jspb.Message.getFieldWithDefault(msg, 12, 0),
      opinion: jspb.Message.getFieldWithDefault(msg, 13, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateLegalTrackerTicketRequest}
 */
proto.external.CreateLegalTrackerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateLegalTrackerTicketRequest;
  return proto.external.CreateLegalTrackerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateLegalTrackerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateLegalTrackerTicketRequest}
 */
proto.external.CreateLegalTrackerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseref(value);
        break;
      case 4:
        var value = /** @type {!proto.external.Status} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setClaimant(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setParty(value);
        break;
      case 7:
        var value = /** @type {!proto.external.Category} */ (reader.readEnum());
        msg.setCategory(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForagainst(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setExpecteddate(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setDirectorate(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEstimatedpayment(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setOpinion(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateLegalTrackerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateLegalTrackerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateLegalTrackerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaseref();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getClaimant();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParty();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getForagainst();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getExpecteddate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDirectorate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEstimatedpayment();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getOpinion();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userID = 2;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string caseRef = 3;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getCaseref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setCaseref = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.external.Status}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getStatus = function() {
  return /** @type {!proto.external.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.external.Status} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string claimant = 5;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getClaimant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setClaimant = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string party = 6;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setParty = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Category category = 7;
 * @return {!proto.external.Category}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getCategory = function() {
  return /** @type {!proto.external.Category} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.external.Category} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool forAgainst = 8;
 * @return {boolean}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getForagainst = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setForagainst = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string expectedDate = 9;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getExpecteddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setExpecteddate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string directorate = 11;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getDirectorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setDirectorate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 estimatedPayment = 12;
 * @return {number}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getEstimatedpayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setEstimatedpayment = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string opinion = 13;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.getOpinion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketRequest} returns this
 */
proto.external.CreateLegalTrackerTicketRequest.prototype.setOpinion = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateLegalTrackerTicketResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateLegalTrackerTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateLegalTrackerTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateLegalTrackerTicketResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateLegalTrackerTicketResponse}
 */
proto.external.CreateLegalTrackerTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateLegalTrackerTicketResponse;
  return proto.external.CreateLegalTrackerTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateLegalTrackerTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateLegalTrackerTicketResponse}
 */
proto.external.CreateLegalTrackerTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateLegalTrackerTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateLegalTrackerTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateLegalTrackerTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateLegalTrackerTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.CreateLegalTrackerTicketResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateLegalTrackerTicketResponse} returns this
 */
proto.external.CreateLegalTrackerTicketResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetLegalTrackerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetLegalTrackerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetLegalTrackerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetLegalTrackerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetLegalTrackerTicketRequest}
 */
proto.external.GetLegalTrackerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetLegalTrackerTicketRequest;
  return proto.external.GetLegalTrackerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetLegalTrackerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetLegalTrackerTicketRequest}
 */
proto.external.GetLegalTrackerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetLegalTrackerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetLegalTrackerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetLegalTrackerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetLegalTrackerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketRequest} returns this
 */
proto.external.GetLegalTrackerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetLegalTrackerTicketResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetLegalTrackerTicketResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetLegalTrackerTicketResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetLegalTrackerTicketResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      userid: jspb.Message.getFieldWithDefault(msg, 3, ''),
      caseref: jspb.Message.getFieldWithDefault(msg, 4, ''),
      status: jspb.Message.getFieldWithDefault(msg, 5, 0),
      claimant: jspb.Message.getFieldWithDefault(msg, 6, ''),
      party: jspb.Message.getFieldWithDefault(msg, 7, ''),
      category: jspb.Message.getFieldWithDefault(msg, 8, 0),
      foragainst: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      expecteddate: jspb.Message.getFieldWithDefault(msg, 10, ''),
      description: jspb.Message.getFieldWithDefault(msg, 11, ''),
      directorate: jspb.Message.getFieldWithDefault(msg, 12, ''),
      estimatedpayment: jspb.Message.getFieldWithDefault(msg, 13, 0),
      opinion: jspb.Message.getFieldWithDefault(msg, 14, ''),
      datecreated: jspb.Message.getFieldWithDefault(msg, 15, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetLegalTrackerTicketResponse}
 */
proto.external.GetLegalTrackerTicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetLegalTrackerTicketResponse;
  return proto.external.GetLegalTrackerTicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetLegalTrackerTicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetLegalTrackerTicketResponse}
 */
proto.external.GetLegalTrackerTicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCaseref(value);
        break;
      case 5:
        var value = /** @type {!proto.external.Status} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setClaimant(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setParty(value);
        break;
      case 8:
        var value = /** @type {!proto.external.Category} */ (reader.readEnum());
        msg.setCategory(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForagainst(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setExpecteddate(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setDirectorate(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEstimatedpayment(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setOpinion(value);
        break;
      case 15:
        var value = /** @type {string} */ (reader.readString());
        msg.setDatecreated(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetLegalTrackerTicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetLegalTrackerTicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetLegalTrackerTicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCaseref();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getClaimant();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getParty();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getForagainst();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getExpecteddate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDirectorate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEstimatedpayment();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getOpinion();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assetID = 2;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userID = 3;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string caseRef = 4;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getCaseref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setCaseref = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Status status = 5;
 * @return {!proto.external.Status}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getStatus = function() {
  return /** @type {!proto.external.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.external.Status} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string claimant = 6;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getClaimant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setClaimant = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string party = 7;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getParty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setParty = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Category category = 8;
 * @return {!proto.external.Category}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getCategory = function() {
  return /** @type {!proto.external.Category} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.external.Category} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional bool forAgainst = 9;
 * @return {boolean}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getForagainst = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setForagainst = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string expectedDate = 10;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getExpecteddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setExpecteddate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string directorate = 12;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getDirectorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setDirectorate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 estimatedPayment = 13;
 * @return {number}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getEstimatedpayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setEstimatedpayment = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string opinion = 14;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getOpinion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setOpinion = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string dateCreated = 15;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketResponse.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketResponse} returns this
 */
proto.external.GetLegalTrackerTicketResponse.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetLegalTrackerTicketByAssetIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetLegalTrackerTicketByAssetIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetLegalTrackerTicketByAssetIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDRequest}
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetLegalTrackerTicketByAssetIDRequest;
  return proto.external.GetLegalTrackerTicketByAssetIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDRequest}
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetLegalTrackerTicketByAssetIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDRequest} returns this
 */
proto.external.GetLegalTrackerTicketByAssetIDRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetLegalTrackerTicketByAssetIDResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetLegalTrackerTicketByAssetIDResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetLegalTrackerTicketByAssetIDResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetLegalTrackerTicketByAssetIDResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
        proto.external.GetLegalTrackerTicketResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDResponse}
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetLegalTrackerTicketByAssetIDResponse;
  return proto.external.GetLegalTrackerTicketByAssetIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetLegalTrackerTicketByAssetIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDResponse}
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.GetLegalTrackerTicketResponse;
        reader.readMessage(value, proto.external.GetLegalTrackerTicketResponse.deserializeBinaryFromReader);
        msg.addTickets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetLegalTrackerTicketByAssetIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetLegalTrackerTicketByAssetIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetLegalTrackerTicketResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetLegalTrackerTicketResponse tickets = 1;
 * @return {!Array<!proto.external.GetLegalTrackerTicketResponse>}
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.external.GetLegalTrackerTicketResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetLegalTrackerTicketResponse, 1));
};


/**
 * @param {!Array<!proto.external.GetLegalTrackerTicketResponse>} value
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDResponse} returns this
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetLegalTrackerTicketResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetLegalTrackerTicketResponse}
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetLegalTrackerTicketResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetLegalTrackerTicketByAssetIDResponse} returns this
 */
proto.external.GetLegalTrackerTicketByAssetIDResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateLegalTrackerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateLegalTrackerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateLegalTrackerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateLegalTrackerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      assetid: (f = msg.getAssetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      userid: (f = msg.getUserid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      caseref: (f = msg.getCaseref()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      claimant: (f = msg.getClaimant()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      party: (f = msg.getParty()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      category: (f = msg.getCategory()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      foragainst: (f = msg.getForagainst()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      expecteddate: (f = msg.getExpecteddate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      directorate: (f = msg.getDirectorate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      estimatedpayment: (f = msg.getEstimatedpayment()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      opinion: (f = msg.getOpinion()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      datecreated: (f = msg.getDatecreated()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest}
 */
proto.external.UpdateLegalTrackerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateLegalTrackerTicketRequest;
  return proto.external.UpdateLegalTrackerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateLegalTrackerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest}
 */
proto.external.UpdateLegalTrackerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setAssetid(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setUserid(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCaseref(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setStatus(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setClaimant(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setParty(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setCategory(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setForagainst(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setExpecteddate(value);
        break;
      case 11:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;
      case 12:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDirectorate(value);
        break;
      case 13:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setEstimatedpayment(value);
        break;
      case 14:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setOpinion(value);
        break;
      case 15:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDatecreated(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateLegalTrackerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateLegalTrackerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateLegalTrackerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCaseref();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClaimant();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getParty();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getForagainst();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getExpecteddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectorate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedpayment();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpinion();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDatecreated();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue assetId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getAssetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearAssetid = function() {
  return this.setAssetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasAssetid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue userId = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getUserid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue caseRef = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getCaseref = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setCaseref = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearCaseref = function() {
  return this.setCaseref(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasCaseref = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue status = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue claimant = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getClaimant = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setClaimant = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearClaimant = function() {
  return this.setClaimant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasClaimant = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue party = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getParty = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setParty = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearParty = function() {
  return this.setParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasParty = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue category = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getCategory = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue forAgainst = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getForagainst = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setForagainst = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearForagainst = function() {
  return this.setForagainst(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasForagainst = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue expectedDate = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getExpecteddate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setExpecteddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearExpecteddate = function() {
  return this.setExpecteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasExpecteddate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue description = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue directorate = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getDirectorate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setDirectorate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearDirectorate = function() {
  return this.setDirectorate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasDirectorate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue estimatedPayment = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getEstimatedpayment = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setEstimatedpayment = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearEstimatedpayment = function() {
  return this.setEstimatedpayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasEstimatedpayment = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue opinion = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getOpinion = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setOpinion = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearOpinion = function() {
  return this.setOpinion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasOpinion = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue dateCreated = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.getDatecreated = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.setDatecreated = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateLegalTrackerTicketRequest} returns this
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.clearDatecreated = function() {
  return this.setDatecreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateLegalTrackerTicketRequest.prototype.hasDatecreated = function() {
  return jspb.Message.getField(this, 15) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteLegalTrackerTicketRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteLegalTrackerTicketRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteLegalTrackerTicketRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteLegalTrackerTicketRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteLegalTrackerTicketRequest}
 */
proto.external.DeleteLegalTrackerTicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteLegalTrackerTicketRequest;
  return proto.external.DeleteLegalTrackerTicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteLegalTrackerTicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteLegalTrackerTicketRequest}
 */
proto.external.DeleteLegalTrackerTicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteLegalTrackerTicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteLegalTrackerTicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteLegalTrackerTicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteLegalTrackerTicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteLegalTrackerTicketRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteLegalTrackerTicketRequest} returns this
 */
proto.external.DeleteLegalTrackerTicketRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.external.AssetActivityFeedAction = {
  UNKNOWN: 0,
  UPDATE: 1,
  DELETE: 2,
  CREATE: 3,
  ATTACHMENT_ADD: 4,
  ATTACHMENT_REMOVE: 5
};

/**
 * @enum {number}
 */
proto.external.Status = {
  OPEN: 0,
  RESOLVED: 1
};

/**
 * @enum {number}
 */
proto.external.Category = {
  DAMAGES: 0,
  COMPENSATION: 1
};

goog.object.extend(exports, proto.external);
