import React, { Suspense, useEffect, useState } from 'react';

import Badge from '@mui/material/Badge';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Notifications } from '@mui/icons-material';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import { formatNumberToCurrency, formatZillions } from 'helpers/utils';
import UserHasAccess from 'hooks/UserHasAccess';
import useUrlParams from 'hooks/useUrlParams';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import AGGridTable from '../AGGrid';
import { toast } from 'react-toastify';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { openModal } from '../../components/Modal/modalReducer';
import clsx from 'clsx';
import { Box, Grid2 } from '@mui/material';
import Grow from '@mui/material/Grow';
import useStyles from 'styles/tables';
import useRouter from '../../hooks/useRouter';
import ApexChart from '../../components/Charts';
import Tabs from "../../components/Tabs/Tabs";
import TabPanel from "../../components/Tabs/TabPanel";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

const useLocalStyles = makeStyles((theme) => ({
  icon: {
    color: '#CCCCCC'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 500,
    padding: theme.spacing(6)
  },
  badge: {
    minWidth: theme.spacing(2.2),
    height: theme.spacing(2.2),
    width: theme.spacing(2.2)
  }
}));

const Assets = () => {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const dispatch = useDispatch();
  const { page, portfolioIds, setPortfolios } = useUrlParams();
  const { loaded, data, error } = useSelector((state) => state.assets.assets);
  const { loaded: reportsLoaded, data: asset_reports } = useSelector(
    (state) => state.reports.asset_reports
  );
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const EditAssetsPermissions = UserHasAccess('asset', 'edit', userPermissions);
  const router = useRouter();
  const [api, setAPI] = useState();
  const [tab, setTab] = useState(() => 0);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: '',
      field: 'rowSelection',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      maxWidth: 60
    },
    {
      headerName: 'Assets',
      field: 'name',
      filter: 'agSetColumnFilter',
      chartDataType: 'category'
    },
    {
      headerName: 'City',
      field: 'city',
      filter: 'agSetColumnFilter',
      chartDataType: 'category'
    },
    {
      headerName: 'Type',
      field: 'type',
      filter: 'agSetColumnFilter',
      chartDataType: 'category'
    },
    {
      headerName: 'Portfolio',
      field: 'portfolioname',
      filter: 'agSetColumnFilter',
      chartDataType: 'category'
    },
    {
      headerName: 'Tenants',
      field: 'tenantcount',
      filter: 'agSetColumnFilter',
      chartDataType: 'series'
    },

    {
      headerName: 'Occupancy (%)',
      field: 'occupancy',
      filter: 'agNumberColumnFilter',
      chartDataType: 'series'
    },
    {
      headerName: 'GLA (m2)',
      field: 'glatotal',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value),
      chartDataType: 'series'
    },
    {
      headerName: 'Last Valuation',
      field: 'lastvaluation',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value),
      chartDataType: 'series'
    },
    {
      headerName: 'Current Year NOI (F)',
      field: 'noi',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value),
      chartDataType: 'series'
    },
    {
      headerName: 'Net Yield (%)',
      field: 'yield',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value, '', 2),
      chartDataType: 'series'
    },
    {
      headerName: 'Gross Yield (%)',
      field: 'grossyield',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatZillions(params.value, '', 2),
      chartDataType: 'series'
    },
    {
      headerName: 'Average Rent / SQM',
      field: 'avgrentpersqm',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatNumberToCurrency(params.value),
      chartDataType: 'series'
    },
    {
      headerName: 'Current Year Rent (F)',
      field: 'totalrent',
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => formatNumberToCurrency(params.value),
      chartDataType: 'series'
    },
    {
    headerName: 'Comment',
      field: 'comment',
      filter: 'agSetColumnFilter',
      chartDataType: 'category'
  },
    {
      headerName: 'Notifications',
      field: 'notifs',
      cellRenderer: (params) => (
        <Badge
          badgeContent={params.data.alertcount}
          color="error"
          overlap="circular"
          classes={{ badge: localClasses.badge }}
          onClick={(e) => {
            e.stopPropagation();

            dispatch(
              actions.setAlertFilters({
                asset: params.data.id,
                portfolio: params.data.portfolioid,
                tenant: ''
              })
            );

            dispatch(actions.toggleAlertMenu());
          }}
        >
          <Notifications className={localClasses.icon} />
        </Badge>
      ),
      chartDataType: 'excluded'
    }
  ]);

  useEffect(() => {
    dispatch(actions.getAssets());
    dispatch(actions.getAssetReports(null, portfolioIds[0]));
  }, [page]);

  useEffect(async () => {
    if (!api) {
      return;
    }

    if (portfolioIds.length > 0) {
      await api.setColumnFilterModel('portfolioname', { values: portfolioIds });
    } else {
      await api.setColumnFilterModel('portfolioname', null);
    }
    api.onFilterChanged();

    dispatch(actions.getAssetReports(null, portfolioIds[0]));
  }, [portfolioIds, api]);

  const onGridReady = async (params) => {
    setAPI(params.api);
  };

  if (error && loaded) {
    return (
      <div className={localClasses.root}>
        <Typography color="error" variant="h5">
          {error}
        </Typography>
      </div>
    );
  }

  const resetAssetDetailsState = (e) => {
    if (e.column.colId !== 'name') {
      return;
    }

    dispatch(actions.resetAssetDetails());
    dispatch(actions.resetValuations());
    dispatch(actions.resetActuals());
    dispatch(actions.resetOpexCostManager());
    dispatch(actions.resetAssetCostManager());
    dispatch(actions.resetMLA());
    dispatch(actions.resetGeoData());
    router.push(`Asset/${e.data.id}/Details`);
  };

  return (
    <Container>
      <Suspense fallback={<Spinner mini />}>
        <Grow in>
          <Box className={clsx(classes.containerLarge)}>
            <Box className={classes.header}>
              <Typography className={classes.title}>{`Assets (${data.length})`}</Typography>
            </Box>
            <Tabs
              value={tab}
              handleChange={handleChange}
              tabNames={["Table", "Charts"]}
            />
            <TabPanel value={tab} index={1}>
              <br/>
              <Box>
                <Paper sx={{ padding: '20px' }}>
                {reportsLoaded && (
                  <Grid2 container>
                    <Grid2 size={{ lg: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>Asset Type by Count</Typography>
                      <ApexChart
                        type="bar"
                        options={{
                          xaxis: {
                            type: 'category'
                          }
                        }}
                        series={[
                          {
                            data: asset_reports?.assetCountByType?.dataMap.map((item) => {
                              return {
                                x: item[0],
                                y: item[1]
                              };
                            })
                          }
                        ]}
                      />
                    </Grid2>
                    <Grid2 size={{ lg: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>
                        Average gross yield by Asset Type
                      </Typography>

                      <ApexChart
                        type="bar"
                        options={{
                          xaxis: {
                            type: 'category'
                          }
                        }}
                        series={[
                          {
                            data: asset_reports?.averageGrossYieldByType?.dataMap.map((item) => {
                              return {
                                x: item[0],
                                y: item[1]
                              };
                            })
                          }
                        ]}
                      />
                    </Grid2>
                    <Grid2 size={{ lg: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>GLA by Energy Score</Typography>
                      <ApexChart
                        type="bar"
                        options={{
                          xaxis: {
                            type: 'category'
                          },
                          yaxis: {
                            labels: {
                              formatter: (value) => {
                                return formatZillions(value);
                              }
                            }
                          }
                        }}
                        series={[
                          {
                            data: asset_reports?.glaByAssetType?.dataMap.map((item) => {
                              return {
                                x: item[0],
                                y: item[1]
                              };
                            })
                          }
                        ]}
                      />
                    </Grid2>
                    <Grid2 size={{ lg: 1 }}></Grid2>
                    <Grid2 size={{ lg: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>GLA (sqm) by Asset Type</Typography>
                      <ApexChart
                        type="pie"
                        options={{
                          labels: asset_reports?.glaByEnergyScore?.dataMap.map((item) => item[0]),
                          chart: {
                            width: 380
                          }
                        }}
                        series={asset_reports?.glaByEnergyScore?.dataMap.map((item) => item[1])}
                      />
                    </Grid2>
                    <Grid2 size={{ lg: 2 }}></Grid2>
                    <Grid2 size={{ lg: 4 }}>
                      <Typography sx={{ textAlign: 'center' }}>
                        Total Valuations by Asset Type
                      </Typography>
                      <ApexChart
                        type="pie"
                        options={{
                          labels: asset_reports?.valuationByAssetType?.dataMap.map((item) => item[0]),
                          chart: {
                            width: 380
                          }
                        }}
                        series={asset_reports?.valuationByAssetType?.dataMap.map((item) => item[1])}
                      />
                    </Grid2>
                    <Grid2 size={{ lg: 1 }}></Grid2>
                  </Grid2>
                )}
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={0}>
            <Box className={clsx(classes.table)}>
              <AGGridTable
                onGridReady={onGridReady}
                data={data}
                colDefs={colDefs}
                classes={classes}
                onCellClicked={resetAssetDetailsState}
                deleteHandler={(selectedRows, api) => {
                  api.applyTransaction({ remove: selectedRows });
                  for (let i = 0; i < selectedRows.length; i++) {
                    dispatch(actions.deleteAsset(selectedRows[i].id))
                      .then(() => {})
                      .catch((err) => toast.error(err));
                  }
                }}
                buttons={[
                  EditAssetsPermissions && (
                    <Button
                      className={classes.ctaButton}
                      style={{ marginRight: '10px' }}
                      startIcon={<Icon style={{ color: '#5381EF' }}>add</Icon>}
                      onClick={() =>
                        dispatch(
                          openModal({
                            modalType: 'AssetModal',
                            modalProps: { asset: { portfolioid: portfolioIds[0] } }
                          })
                        )
                      }
                    >
                      NEW
                    </Button>
                  )
                ]}
              ></AGGridTable>
            </Box>
            </TabPanel>
          </Box>
        </Grow>
      </Suspense>
    </Container>
  );
};

export default Assets;
