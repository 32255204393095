/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./turnover_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TurnoverServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TurnoverServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantTurnoverRequest,
 *   !proto.external.GetTenantTurnoverResponse>}
 */
const methodDescriptor_TurnoverService_GetTenantTurnover = new grpc.web.MethodDescriptor(
  '/external.TurnoverService/GetTenantTurnover',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantTurnoverRequest,
  proto.external.GetTenantTurnoverResponse,
  /**
   * @param {!proto.external.GetTenantTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantTurnoverResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantTurnoverRequest,
 *   !proto.external.GetTenantTurnoverResponse>}
 */
const methodInfo_TurnoverService_GetTenantTurnover = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantTurnoverResponse,
  /**
   * @param {!proto.external.GetTenantTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantTurnoverResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantTurnoverResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantTurnoverResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TurnoverServiceClient.prototype.getTenantTurnover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TurnoverService/GetTenantTurnover',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_GetTenantTurnover,
      callback);
};


/**
 * @param {!proto.external.GetTenantTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantTurnoverResponse>}
 *     Promise that resolves to the response
 */
proto.external.TurnoverServicePromiseClient.prototype.getTenantTurnover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TurnoverService/GetTenantTurnover',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_GetTenantTurnover);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantTurnoverRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TurnoverService_UpdateTenantTurnover = new grpc.web.MethodDescriptor(
  '/external.TurnoverService/UpdateTenantTurnover',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantTurnoverRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantTurnoverRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TurnoverService_UpdateTenantTurnover = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TurnoverServiceClient.prototype.updateTenantTurnover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TurnoverService/UpdateTenantTurnover',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_UpdateTenantTurnover,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TurnoverServicePromiseClient.prototype.updateTenantTurnover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TurnoverService/UpdateTenantTurnover',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_UpdateTenantTurnover);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateGCPIRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TurnoverService_UpdateGCPI = new grpc.web.MethodDescriptor(
  '/external.TurnoverService/UpdateGCPI',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateGCPIRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateGCPIRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateGCPIRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TurnoverService_UpdateGCPI = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateGCPIRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateGCPIRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TurnoverServiceClient.prototype.updateGCPI =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TurnoverService/UpdateGCPI',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_UpdateGCPI,
      callback);
};


/**
 * @param {!proto.external.UpdateGCPIRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TurnoverServicePromiseClient.prototype.updateGCPI =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TurnoverService/UpdateGCPI',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_UpdateGCPI);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetGCPIRequest,
 *   !proto.external.GetGCPIResponse>}
 */
const methodDescriptor_TurnoverService_GetGCPI = new grpc.web.MethodDescriptor(
  '/external.TurnoverService/GetGCPI',
  grpc.web.MethodType.UNARY,
  proto.external.GetGCPIRequest,
  proto.external.GetGCPIResponse,
  /**
   * @param {!proto.external.GetGCPIRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetGCPIResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetGCPIRequest,
 *   !proto.external.GetGCPIResponse>}
 */
const methodInfo_TurnoverService_GetGCPI = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetGCPIResponse,
  /**
   * @param {!proto.external.GetGCPIRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetGCPIResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetGCPIRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetGCPIResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetGCPIResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TurnoverServiceClient.prototype.getGCPI =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TurnoverService/GetGCPI',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_GetGCPI,
      callback);
};


/**
 * @param {!proto.external.GetGCPIRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetGCPIResponse>}
 *     Promise that resolves to the response
 */
proto.external.TurnoverServicePromiseClient.prototype.getGCPI =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TurnoverService/GetGCPI',
      request,
      metadata || {},
      methodDescriptor_TurnoverService_GetGCPI);
};


module.exports = proto.external;

