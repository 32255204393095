// source: external/unit.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.external.CreateUnitRequest', null, global);
goog.exportSymbol('proto.external.CreateUnitsRequest', null, global);
goog.exportSymbol('proto.external.DeleteUnitRequest', null, global);
goog.exportSymbol('proto.external.GetUnitsByFloorsResponse', null, global);
goog.exportSymbol('proto.external.GetUnitsByIDRequest', null, global);
goog.exportSymbol('proto.external.GetUnitsRequest', null, global);
goog.exportSymbol('proto.external.GetUnitsResponse', null, global);
goog.exportSymbol('proto.external.Unit', null, global);
goog.exportSymbol('proto.external.UnitsByFloor', null, global);
goog.exportSymbol('proto.external.UpdateUnitRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitsByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetUnitsByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitsByIDRequest.displayName = 'proto.external.GetUnitsByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateUnitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CreateUnitsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.CreateUnitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateUnitsRequest.displayName = 'proto.external.CreateUnitsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateUnitRequest.displayName = 'proto.external.CreateUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Unit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.Unit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Unit.displayName = 'proto.external.Unit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetUnitsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitsRequest.displayName = 'proto.external.GetUnitsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetUnitsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetUnitsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitsResponse.displayName = 'proto.external.GetUnitsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UnitsByFloor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.UnitsByFloor.repeatedFields_, null);
};
goog.inherits(proto.external.UnitsByFloor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UnitsByFloor.displayName = 'proto.external.UnitsByFloor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitsByFloorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetUnitsByFloorsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetUnitsByFloorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitsByFloorsResponse.displayName = 'proto.external.GetUnitsByFloorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UpdateUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UpdateUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UpdateUnitRequest.displayName = 'proto.external.UpdateUnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteUnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.DeleteUnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteUnitRequest.displayName = 'proto.external.DeleteUnitRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetUnitsByIDRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetUnitsByIDRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetUnitsByIDRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetUnitsByIDRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitsByIDRequest}
 */
proto.external.GetUnitsByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitsByIDRequest;
  return proto.external.GetUnitsByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitsByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitsByIDRequest}
 */
proto.external.GetUnitsByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitsByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitsByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitsByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.GetUnitsByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetUnitsByIDRequest} returns this
 */
proto.external.GetUnitsByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CreateUnitsRequest.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateUnitsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateUnitsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateUnitsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateUnitsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
        proto.external.CreateUnitRequest.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateUnitsRequest}
 */
proto.external.CreateUnitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateUnitsRequest;
  return proto.external.CreateUnitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateUnitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateUnitsRequest}
 */
proto.external.CreateUnitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.CreateUnitRequest;
        reader.readMessage(value, proto.external.CreateUnitRequest.deserializeBinaryFromReader);
        msg.addUnits(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateUnitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateUnitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateUnitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateUnitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CreateUnitRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateUnitRequest units = 1;
 * @return {!Array<!proto.external.CreateUnitRequest>}
 */
proto.external.CreateUnitsRequest.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.external.CreateUnitRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CreateUnitRequest, 1));
};


/**
 * @param {!Array<!proto.external.CreateUnitRequest>} value
 * @return {!proto.external.CreateUnitsRequest} returns this
 */
proto.external.CreateUnitsRequest.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CreateUnitRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CreateUnitRequest}
 */
proto.external.CreateUnitsRequest.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CreateUnitRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CreateUnitsRequest} returns this
 */
proto.external.CreateUnitsRequest.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.CreateUnitRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.CreateUnitRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.CreateUnitRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.CreateUnitRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ''),
      floor: jspb.Message.getFieldWithDefault(msg, 2, 0),
      gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
      assetid: jspb.Message.getFieldWithDefault(msg, 4, ''),
      building: (f = msg.getBuilding()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      powerSupplyNumber: (f = msg.getPowerSupplyNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      waterSupplyNumber: (f = msg.getWaterSupplyNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      autonomousBuilding: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      establishmentOfHorizontalProperty: (f = msg.getEstablishmentOfHorizontalProperty()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      pea: (f = msg.getPea()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      expirationOfPea: (f = msg.getExpirationOfPea()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      // kaek: (f = msg.getKaek()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      // commonSpace: jspb.Message.getFieldWithDefault(msg, 13, 0),
      // gba: jspb.Message.getFieldWithDefault(msg, 14, 0),
      // unitType: jspb.Message.getFieldWithDefault(msg, 15, ''),
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateUnitRequest}
 */
proto.external.CreateUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateUnitRequest;
  return proto.external.CreateUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateUnitRequest}
 */
proto.external.CreateUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFloor(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setGla(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setBuilding(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPowerSupplyNumber(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setWaterSupplyNumber(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutonomousBuilding(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setEstablishmentOfHorizontalProperty(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPea(value);
        break;
      case 11:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setExpirationOfPea(value);
        break;
      case 12:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setKaek(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCommonSpace(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setGba(value);
        break;
      case 15:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setUnitType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuilding();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPowerSupplyNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getWaterSupplyNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAutonomousBuilding();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEstablishmentOfHorizontalProperty();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPea();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExpirationOfPea();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getKaek();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCommonSpace();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getGba();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getUnitType();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.CreateUnitRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 floor = 2;
 * @return {number}
 */
proto.external.CreateUnitRequest.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float gla = 3;
 * @return {number}
 */
proto.external.CreateUnitRequest.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string assetID = 4;
 * @return {string}
 */
proto.external.CreateUnitRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue building = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getBuilding = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setBuilding = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearBuilding = function() {
  return this.setBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasBuilding = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue power_supply_number = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getPowerSupplyNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setPowerSupplyNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearPowerSupplyNumber = function() {
  return this.setPowerSupplyNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasPowerSupplyNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue water_supply_number = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getWaterSupplyNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setWaterSupplyNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearWaterSupplyNumber = function() {
  return this.setWaterSupplyNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasWaterSupplyNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool autonomous_building = 8;
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.getAutonomousBuilding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setAutonomousBuilding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.BoolValue establishment_of_horizontal_property = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.external.CreateUnitRequest.prototype.getEstablishmentOfHorizontalProperty = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setEstablishmentOfHorizontalProperty = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearEstablishmentOfHorizontalProperty = function() {
  return this.setEstablishmentOfHorizontalProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasEstablishmentOfHorizontalProperty = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue PEA = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getPea = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setPea = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearPea = function() {
  return this.setPea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasPea = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue expiration_of_pea = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getExpirationOfPea = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setExpirationOfPea = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearExpirationOfPea = function() {
  return this.setExpirationOfPea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasExpirationOfPea = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue kaek = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getKaek = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setKaek = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.clearKaek = function() {
  return this.setKaek(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateUnitRequest.prototype.hasKaek = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 commonSpace = 13;
 * @return {number}
 */
proto.external.CreateUnitRequest.prototype.getCommonSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setCommonSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};

/**
 * optional int32 commonSpace = 13;
 * @return {number}
 */
proto.external.CreateUnitRequest.prototype.getGba = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setGba = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};

/**
 * optional google.protobuf.StringValue kaek = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateUnitRequest.prototype.getUnitType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateUnitRequest} returns this
 */
proto.external.CreateUnitRequest.prototype.setUnitType = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};




if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.Unit.prototype.toObject = function(opt_includeInstance) {
    return proto.external.Unit.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.Unit} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.Unit.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      floor: jspb.Message.getFieldWithDefault(msg, 3, 0),
      gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
      building: jspb.Message.getFieldWithDefault(msg, 5, ''),
      powerSupplyNumber: jspb.Message.getFieldWithDefault(msg, 6, ''),
      waterSupplyNumber: jspb.Message.getFieldWithDefault(msg, 7, ''),
      autonomousBuilding: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      establishmentOfHorizontalProperty: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      pea: jspb.Message.getFieldWithDefault(msg, 10, ''),
      expirationOfPea: jspb.Message.getFieldWithDefault(msg, 11, ''),
      kaek: jspb.Message.getFieldWithDefault(msg, 12, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Unit}
 */
proto.external.Unit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Unit;
  return proto.external.Unit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Unit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Unit}
 */
proto.external.Unit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFloor(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setGla(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setBuilding(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setPowerSupplyNumber(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setWaterSupplyNumber(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setAutonomousBuilding(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setEstablishmentOfHorizontalProperty(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setPea(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setExpirationOfPea(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setKaek(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Unit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Unit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Unit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Unit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBuilding();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPowerSupplyNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWaterSupplyNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAutonomousBuilding();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEstablishmentOfHorizontalProperty();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPea();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExpirationOfPea();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKaek();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCommonSpace();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getGba();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getUnitType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.Unit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.Unit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 floor = 3;
 * @return {number}
 */
proto.external.Unit.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float gla = 4;
 * @return {number}
 */
proto.external.Unit.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string building = 5;
 * @return {string}
 */
proto.external.Unit.prototype.getBuilding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setBuilding = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string power_supply_number = 6;
 * @return {string}
 */
proto.external.Unit.prototype.getPowerSupplyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setPowerSupplyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string water_supply_number = 7;
 * @return {string}
 */
proto.external.Unit.prototype.getWaterSupplyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setWaterSupplyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool autonomous_building = 8;
 * @return {boolean}
 */
proto.external.Unit.prototype.getAutonomousBuilding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setAutonomousBuilding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool establishment_of_horizontal_property = 9;
 * @return {boolean}
 */
proto.external.Unit.prototype.getEstablishmentOfHorizontalProperty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setEstablishmentOfHorizontalProperty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string PEA = 10;
 * @return {string}
 */
proto.external.Unit.prototype.getPea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setPea = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string expiration_of_pea = 11;
 * @return {string}
 */
proto.external.Unit.prototype.getExpirationOfPea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setExpirationOfPea = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string kaek = 12;
 * @return {string}
 */
proto.external.Unit.prototype.getKaek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setKaek = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * optional int32 commonSpace = 13;
 * @return {number}
 */
proto.external.Unit.prototype.getCommonSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setCommonSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 gba = 13;
 * @return {number}
 */
proto.external.Unit.prototype.getGba = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setGba = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string unitType = 15;
 * @return {string}
 */
proto.external.Unit.prototype.getUnitType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.Unit} returns this
 */
proto.external.Unit.prototype.setUnitType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetUnitsRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetUnitsRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetUnitsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetUnitsRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      assetid: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitsRequest}
 */
proto.external.GetUnitsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitsRequest;
  return proto.external.GetUnitsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitsRequest}
 */
proto.external.GetUnitsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setAssetid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetUnitsRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.GetUnitsRequest} returns this
 */
proto.external.GetUnitsRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetUnitsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetUnitsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetUnitsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetUnitsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetUnitsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
        proto.external.Unit.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitsResponse}
 */
proto.external.GetUnitsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitsResponse;
  return proto.external.GetUnitsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitsResponse}
 */
proto.external.GetUnitsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.Unit;
        reader.readMessage(value, proto.external.Unit.deserializeBinaryFromReader);
        msg.addUnits(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.Unit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Unit units = 1;
 * @return {!Array<!proto.external.Unit>}
 */
proto.external.GetUnitsResponse.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.external.Unit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Unit, 1));
};


/**
 * @param {!Array<!proto.external.Unit>} value
 * @return {!proto.external.GetUnitsResponse} returns this
 */
proto.external.GetUnitsResponse.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Unit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Unit}
 */
proto.external.GetUnitsResponse.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Unit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetUnitsResponse} returns this
 */
proto.external.GetUnitsResponse.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.UnitsByFloor.repeatedFields_ = [3];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UnitsByFloor.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UnitsByFloor.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UnitsByFloor} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UnitsByFloor.toObject = function(includeInstance, msg) {
    var f, obj = {
      floor: jspb.Message.getFieldWithDefault(msg, 1, 0),
      totalgla: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
        proto.external.Unit.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UnitsByFloor}
 */
proto.external.UnitsByFloor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UnitsByFloor;
  return proto.external.UnitsByFloor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UnitsByFloor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UnitsByFloor}
 */
proto.external.UnitsByFloor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFloor(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setTotalgla(value);
        break;
      case 3:
        var value = new proto.external.Unit;
        reader.readMessage(value, proto.external.Unit.deserializeBinaryFromReader);
        msg.addUnits(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UnitsByFloor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UnitsByFloor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UnitsByFloor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitsByFloor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalgla();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.external.Unit.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 floor = 1;
 * @return {number}
 */
proto.external.UnitsByFloor.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitsByFloor} returns this
 */
proto.external.UnitsByFloor.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float totalGLA = 2;
 * @return {number}
 */
proto.external.UnitsByFloor.prototype.getTotalgla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitsByFloor} returns this
 */
proto.external.UnitsByFloor.prototype.setTotalgla = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated Unit units = 3;
 * @return {!Array<!proto.external.Unit>}
 */
proto.external.UnitsByFloor.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.external.Unit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Unit, 3));
};


/**
 * @param {!Array<!proto.external.Unit>} value
 * @return {!proto.external.UnitsByFloor} returns this
 */
proto.external.UnitsByFloor.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.external.Unit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Unit}
 */
proto.external.UnitsByFloor.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.external.Unit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.UnitsByFloor} returns this
 */
proto.external.UnitsByFloor.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetUnitsByFloorsResponse.repeatedFields_ = [1];


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.GetUnitsByFloorsResponse.prototype.toObject = function(opt_includeInstance) {
    return proto.external.GetUnitsByFloorsResponse.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.GetUnitsByFloorsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.GetUnitsByFloorsResponse.toObject = function(includeInstance, msg) {
    var f, obj = {
      unitsbyfloorList: jspb.Message.toObjectList(msg.getUnitsbyfloorList(),
        proto.external.UnitsByFloor.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitsByFloorsResponse}
 */
proto.external.GetUnitsByFloorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitsByFloorsResponse;
  return proto.external.GetUnitsByFloorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitsByFloorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitsByFloorsResponse}
 */
proto.external.GetUnitsByFloorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.external.UnitsByFloor;
        reader.readMessage(value, proto.external.UnitsByFloor.deserializeBinaryFromReader);
        msg.addUnitsbyfloor(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitsByFloorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitsByFloorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitsByFloorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsByFloorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitsbyfloorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.UnitsByFloor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UnitsByFloor unitsByFloor = 1;
 * @return {!Array<!proto.external.UnitsByFloor>}
 */
proto.external.GetUnitsByFloorsResponse.prototype.getUnitsbyfloorList = function() {
  return /** @type{!Array<!proto.external.UnitsByFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.UnitsByFloor, 1));
};


/**
 * @param {!Array<!proto.external.UnitsByFloor>} value
 * @return {!proto.external.GetUnitsByFloorsResponse} returns this
 */
proto.external.GetUnitsByFloorsResponse.prototype.setUnitsbyfloorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.UnitsByFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.UnitsByFloor}
 */
proto.external.GetUnitsByFloorsResponse.prototype.addUnitsbyfloor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.UnitsByFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetUnitsByFloorsResponse} returns this
 */
proto.external.GetUnitsByFloorsResponse.prototype.clearUnitsbyfloorList = function() {
  return this.setUnitsbyfloorList([]);
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.UpdateUnitRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.UpdateUnitRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.UpdateUnitRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.UpdateUnitRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ''),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      floor: (f = msg.getFloor()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      gla: (f = msg.getGla()) && google_protobuf_wrappers_pb.FloatValue.toObject(includeInstance, f),
      building: (f = msg.getBuilding()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      powerSupplyNumber: (f = msg.getPowerSupplyNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      waterSupplyNumber: (f = msg.getWaterSupplyNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      autonomousBuilding: (f = msg.getAutonomousBuilding()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      establishmentOfHorizontalProperty: (f = msg.getEstablishmentOfHorizontalProperty()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      pea: (f = msg.getPea()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      expirationOfPea: (f = msg.getExpirationOfPea()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      kaek: (f = msg.getKaek()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UpdateUnitRequest}
 */
proto.external.UpdateUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UpdateUnitRequest;
  return proto.external.UpdateUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UpdateUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UpdateUnitRequest}
 */
proto.external.UpdateUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;
      case 3:
        var value = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value, google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setFloor(value);
        break;
      case 4:
        var value = new google_protobuf_wrappers_pb.FloatValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.FloatValue.deserializeBinaryFromReader);
        msg.setGla(value);
        break;
      case 5:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setBuilding(value);
        break;
      case 6:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPowerSupplyNumber(value);
        break;
      case 7:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setWaterSupplyNumber(value);
        break;
      case 8:
        var value = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setAutonomousBuilding(value);
        break;
      case 9:
        var value = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setEstablishmentOfHorizontalProperty(value);
        break;
      case 10:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setPea(value);
        break;
      case 11:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setExpirationOfPea(value);
        break;
      case 12:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setKaek(value);
        break;
      case 13:
        var value = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value, google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setCommonSpace(value);
        break;
      case 14:
        var value = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value, google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setGba(value);
        break;
      case 15:
        var value = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setUnitType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UpdateUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UpdateUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UpdateUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UpdateUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFloor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getGla();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.FloatValue.serializeBinaryToWriter
    );
  }
  f = message.getBuilding();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPowerSupplyNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getWaterSupplyNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAutonomousBuilding();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getEstablishmentOfHorizontalProperty();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPea();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExpirationOfPea();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getKaek();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCommonSpace();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getGba();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnitType();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.UpdateUnitRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value floor = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.external.UpdateUnitRequest.prototype.getFloor = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setFloor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearFloor = function() {
  return this.setFloor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasFloor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.FloatValue gla = 4;
 * @return {?proto.google.protobuf.FloatValue}
 */
proto.external.UpdateUnitRequest.prototype.getGla = function() {
  return /** @type{?proto.google.protobuf.FloatValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.FloatValue, 4));
};


/**
 * @param {?proto.google.protobuf.FloatValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setGla = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearGla = function() {
  return this.setGla(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasGla = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue building = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getBuilding = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setBuilding = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearBuilding = function() {
  return this.setBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasBuilding = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue power_supply_number = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getPowerSupplyNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setPowerSupplyNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearPowerSupplyNumber = function() {
  return this.setPowerSupplyNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasPowerSupplyNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue water_supply_number = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getWaterSupplyNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setWaterSupplyNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearWaterSupplyNumber = function() {
  return this.setWaterSupplyNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasWaterSupplyNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue autonomous_building = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.external.UpdateUnitRequest.prototype.getAutonomousBuilding = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setAutonomousBuilding = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearAutonomousBuilding = function() {
  return this.setAutonomousBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasAutonomousBuilding = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue establishment_of_horizontal_property = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.external.UpdateUnitRequest.prototype.getEstablishmentOfHorizontalProperty = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setEstablishmentOfHorizontalProperty = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearEstablishmentOfHorizontalProperty = function() {
  return this.setEstablishmentOfHorizontalProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasEstablishmentOfHorizontalProperty = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue PEA = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getPea = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setPea = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearPea = function() {
  return this.setPea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasPea = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue expiration_of_pea = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getExpirationOfPea = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setExpirationOfPea = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearExpirationOfPea = function() {
  return this.setExpirationOfPea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasExpirationOfPea = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue kaek = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.UpdateUnitRequest.prototype.getKaek = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.setKaek = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.UpdateUnitRequest} returns this
 */
proto.external.UpdateUnitRequest.prototype.clearKaek = function() {
  return this.setKaek(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.UpdateUnitRequest.prototype.hasKaek = function() {
  return jspb.Message.getField(this, 12) != null;
};


if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.external.DeleteUnitRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.external.DeleteUnitRequest.toObject(opt_includeInstance, this);
  };


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.external.DeleteUnitRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.external.DeleteUnitRequest.toObject = function(includeInstance, msg) {
    var f, obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, '')
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteUnitRequest}
 */
proto.external.DeleteUnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteUnitRequest;
  return proto.external.DeleteUnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteUnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteUnitRequest}
 */
proto.external.DeleteUnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteUnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteUnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteUnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteUnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.DeleteUnitRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};


/**
 * @param {string} value
 * @return {!proto.external.DeleteUnitRequest} returns this
 */
proto.external.DeleteUnitRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.external);
