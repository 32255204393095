import * as actionTypes from '../actions/actionTypes';

export const reportsInitialState = {
  reports: [],
  loading: false,
  loaded: false,
  error: null,
  footfall: {
    footfall: {
      categoriesList: [],
      seriesList: []
    },
    loading: false,
    loaded: false,
    error: null
  },
  asset_reports: {
    data: [],
    loading: false,
    loaded: false,
    error: null
  },
  turnover: {
    turnover: {
      categoriesList: [],
      seriesList: []
    },
    loading: false,
    loaded: false,
    error: null,
    allIds: [],
    byId: []
  },
  tenantPerformance: {
    tenantPerformance: {
      baserent: 0,
      servicecharge: 0,
      turnoverrent: 0,
      other: 0,
      totalincome: 0,
      tenantcosts: 0,
      rentdiscount: 0,
      leasingcosts: 0,
      allocatedopex: 0,
      effortratio: 0,
      salesdensity: 0,
      netprofitability: 0
    },
    loading: false,
    loaded: false,
    error: null,
    allIds: [],
    byId: []
  },
  assetPerformance: {
    assetPerformance: {
      noi: 0,
      increasedrentalvalue: 0,
      increasedservicecharge: 0,
      forecastednoi: 0
    },
    loading: false,
    loaded: false,
    error: null,
    allIds: [],
    byId: []
  }
};

const reducer = (state = reportsInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORTS_START:
      return {
        ...state,
        loading: true,
        loaded: false,
        reports: []
      };
    case actionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        reports: action.reports
      };
    case actionTypes.GET_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    case actionTypes.GET_ASSET_REPORTS_START:
      return {
        ...state,
        asset_reports: {
          loading: true
        }
      };
    case actionTypes.GET_ASSET_REPORTS_SUCCESS:
      return {
        ...state,
        asset_reports: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_ASSET_REPORTS_FAIL:
      return {
        ...state,
        asset_reports: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    case actionTypes.GET_FOOTFALL_REPORT_START:
      return {
        ...state,
        footfall: {
          ...state.footfall,
          loading: true
        }
      };
    case actionTypes.GET_FOOTFALL_REPORT_SUCCESS:
      return {
        ...state,
        footfall: {
          ...state.footfall,
          loading: false,
          loaded: true,
          footfall: action.footfall
        }
      };
    case actionTypes.GET_FOOTFALL_REPORT_FAIL:
      return {
        ...state,
        footfall: {
          ...state.footfall,
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    case actionTypes.GET_TURNOVER_REPORT_START:
      return {
        ...state,
        turnover: {
          ...state.turnover,
          loading: true
        }
      };
    case actionTypes.GET_TURNOVER_REPORT_SUCCESS:
      return {
        ...state,
        turnover: {
          ...state.turnover,
          loading: false,
          loaded: true,
          turnover: action.turnover,
          byId: state.turnover.byId.some((id) => id === action.bundleId)
            ? state.turnover.byId
            : [...state.turnover.byId, action.bundleId],
          allIds: {
            ...state.turnover.allIds,
            [action.bundleId]: action.turnover
          }
        }
      };

    case actionTypes.SET_TURNOVER_REPORT_BUNDLE:
      return {
        ...state,
        turnover: {
          ...state.turnover,
          turnover: state.turnover.allIds[action.bundleId]
        }
      };
    case actionTypes.GET_TURNOVER_REPORT_FAIL:
      return {
        ...state,
        turnover: {
          ...state.turnover,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_TENANT_PERFORMANCE_REPORT_START:
      return {
        ...state,
        tenantPerformance: {
          ...state.tenantPerformance,
          loading: true
        }
      };
    case actionTypes.GET_TENANT_PERFORMANCE_REPORT_SUCCESS:
      return {
        ...state,
        tenantPerformance: {
          ...state.tenantPerformance,
          loading: false,
          loaded: true,
          tenantPerformance: action.tenantPerformance,
          byId: state.tenantPerformance.byId.some((id) => id === action.bundleId)
            ? state.tenantPerformance.byId
            : [...state.tenantPerformance.byId, action.bundleId],
          allIds: {
            ...state.tenantPerformance.allIds,
            [action.bundleId]: action.tenantPerformance
          }
        }
      };
    case actionTypes.SET_TENANT_PERFORMANCE_REPORT_BUNDLE:
      return {
        ...state,
        tenantPerformance: {
          ...state.tenantPerformance,
          tenantPerformance: state.tenantPerformance.allIds[action.bundleId]
        }
      };
    case actionTypes.GET_TENANT_PERFORMANCE_REPORT_FAIL:
      return {
        ...state,
        tenantPerformance: {
          ...state.tenantPerformance,
          tenantPerformance: {
            baserent: 0,
            servicecharge: 0,
            turnoverrent: 0,
            other: 0,
            totalincome: 0,
            tenantcosts: 0,
            rentdiscount: 0,
            leasingcosts: 0,
            allocatedopex: 0,
            effortratio: 0,
            salesdensity: 0,
            netprofitability: 0
          },
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_ASSET_PERFORMANCE_REPORT_START:
      return {
        ...state,
        assetPerformance: {
          ...state.assetPerformance,
          loading: true
        }
      };
    case actionTypes.GET_ASSET_PERFORMANCE_REPORT_SUCCESS:
      return {
        ...state,
        assetPerformance: {
          ...state.assetPerformance,
          loading: false,
          loaded: true,
          assetPerformance: action.assetPerformance,
          byId: state.assetPerformance.byId.some((id) => id === action.bundleId)
            ? state.assetPerformance.byId
            : [...state.assetPerformance.byId, action.bundleId],
          allIds: {
            ...state.assetPerformance.allIds,
            [action.bundleId]: action.assetPerformance
          }
        }
      };
    case actionTypes.GET_ASSET_PERFORMANCE_REPORT_FAIL:
      return {
        ...state,
        assetPerformance: {
          ...state.assetPerformance,
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    case actionTypes.SET_ASSETS_PERFORMANCE_EPORT_BUNDLE:
      return {
        ...state,
        assetPerformance: {
          ...state.assetPerformance,
          assetPerformance: state.assetPerformance.allIds[action.bundleId]
        }
      };
    case actionTypes.RESET_REPORTS: {
      return reportsInitialState;
    }

    default:
      return state;
  }
};

export default reducer;
