import React from 'react';

import { useSelector } from 'react-redux';

import AlertModal from './AlertModal';
import AssetModal from './forms/AddAsset';
import AssetOtherModal from './forms/AssetOther';
import FacilityManagerModal from './forms/AddFacilityManager';
import FootfallModal from './forms/AddFootfall';
import LegalTrackerModal from './forms/AddLegalTracker';
import OrganisationModal from './forms/AddOrganisation';
import PortfolioModal from './forms/AddPortfolio';
import TenantModal from './forms/AddTenant';
import UnitModal from './forms/AddUnit';
import ValuationModal from './forms/AddValuation';
import UploadActualModal from './forms/UploadActuals';
import UploadImagesModal from './forms/UploadImages';
import UploadRentrollModal from './forms/UploadRentroll';
import ActualModal from './forms/AddActual';
import UploadDocumentModal from './forms/UploadDocument';

export default function ModalManager() {
  const modalLookup = {
    AlertModal,
    PortfolioModal,
    AssetModal,
    AssetOtherModal,
    TenantModal,
    UnitModal,
    ValuationModal,
    FootfallModal,
    OrganisationModal,
    LegalTrackerModal,
    FacilityManagerModal,
    UploadRentrollModal,
    UploadActualModal,
    UploadImagesModal,
    ActualModal,
    UploadDocumentModal
  };

  const currentModal = useSelector((state) => state.modals);

  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
