/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import { assetTypes, countryList } from 'constants/index';

import React, { useState } from 'react';

import { Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import useUrlParams from 'hooks/useUrlParams';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const makeBundleId = (page, portfolioIds) => `Page${page}-PortfolioIds${portfolioIds}`;

const getForm = (asset) => ({
  name: asset?.name || '',
  country: asset?.country || '',
  city: asset?.city || '',
  street: asset?.street || '',
  postcode: asset?.postcode || '',
  type: asset?.type ? assetTypes.find((type) => type.name === asset.type).name : 'Shopping Centre',
  portfolioID: asset?.portfolioid || '',
  id: asset?.id || '',
  manager: asset?.manager || '',
  comment: asset?.comment || ''
});

const countryToFlag = (isoCode) =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;

const AssetModal = () => {
  const classes = useStyles();
  const asset = useSelector((state) => state.modals.modalProps.asset);
  const [form, setForm] = useState(getForm(asset));
  const [formCopy, setFormCopy] = useState(form);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.assets.loading);
  const organizationId = useSelector((state) => state.user.user.organizationid);
  const portfolios = useSelector((state) => state.portfolios.portfolios);

  const updateUI = () => {
    setFormCopy(form);
    setForm(getForm());
    dispatch(closeModal());
    dispatch(actions.getAssets());
    dispatch(actions.getGeoData({ PortfolioID: [], AssetID: [asset.id], Year: [] }));
  };

  const createAsset = () => {
    dispatch(actions.createAsset(form, organizationId))
      .then(() => {
        toast.success('Asset Added!');
        updateUI();
        dispatch(actions.resetAssetDetails());
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const updateAsset = () => {
    dispatch(actions.updateAsset(form, organizationId))
      .then(() => {
        toast.success('Asset Updated!');
        updateUI();
        dispatch(actions.getAssetDetails(form.id));
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSave = () => {
    if (
      !form.portfolioID ||
      !form.name ||
      !form.country ||
      !form.type ||
      !form.city ||
      !form.street ||
      !form.postcode ||
      !form.comment
    ) {
      return toast.error('Please fill in the form');
    }

    return isEmpty(asset) || isEmpty(asset.id) ? createAsset() : updateAsset();
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    (<ModalWrapper
      size="xl"
      header={form.id ? 'Edit Asset' : 'Create Asset'}
      handleSave={handleSave}
      disabled={isEqual(formCopy, form)}
      loading={loading}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Autocomplete
          disabled={!!form.id}
          id="portfolio"
          options={portfolios}
          getOptionLabel={(option) => option.name}
          className={classes.autocomplete}
          autoComplete
          autoHighlight
          clearOnEscape
          value={[...portfolios].find((portfolio) => portfolio.id === form.portfolioID || portfolio.name === form.portfolioID)}
          onChange={(_, value) => handleChange('portfolioID', value?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={form.portfolioID}
              required
              label="Portfolio"
              variant="outlined"
            />
          )}
        />
        <TextField
          fullWidth
          label="Manager"
          variant="outlined"
          className={classes.textField}
          value={form.manager}
          multiline
          disabled={loading}
          onChange={(e) => handleChange('manager', e.target.value)}
          data-cy="asset-manager"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <Autocomplete
          id="type"
          options={assetTypes.sort((a, b) => a.name.localeCompare(b.name))}
          getOptionLabel={(option) => option.name}
          className={classes.autocomplete}
          autoComplete
          autoHighlight
          clearOnEscape
          value={[...assetTypes].find((type) => type.name === form.type)}
          onChange={(_, value) => handleChange('type', value?.name)}
          renderInput={(params) => (
            <TextField {...params} required label="Type" variant="outlined" />
          )}
        />
        <Autocomplete
          id="country"
          options={countryList}
          getOptionLabel={(option) => option.label}
          className={classes.autocomplete}
          autoComplete
          autoHighlight
          clearOnEscape
          value={countryList.find((country) => form.country?.includes(country.label))}
          onChange={(_, value) => handleChange('country', value?.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Country"
              variant="outlined"
              value={form.country}
              slotProps={{
                htmlInput: {
                  ...params.inputProps
                }
              }}
            />
          )}
          renderOption={(props, option) => (
              <li {...props} >
                <span style={{marginRight: 10}}>{countryToFlag(option.code)}</span>
                <span>{option.label}</span>
              </li>
          )}
        />
        <TextField
          fullWidth
          label="Asset Name"
          variant="outlined"
          className={classes.textField}
          value={form.name}
          multiline
          disabled={loading}
          onChange={(e) => handleChange('name', e.target.value)}
          required
          data-cy="asset-name"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="City"
          variant="outlined"
          className={classes.textField}
          value={form.city}
          required
          disabled={loading}
          onChange={(e) => handleChange('city', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Street"
          variant="outlined"
          className={classes.textField}
          value={form.street}
          required
          disabled={loading}
          onChange={(e) => handleChange('street', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Post Code"
          variant="outlined"
          className={classes.textField}
          value={form.postcode}
          required
          disabled={loading}
          onChange={(e) => handleChange('postcode', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />

        <TextField
          fullWidth
          label="Comment"
          variant="outlined"
          className={classes.textField}
          value={form.comment}
          disabled={loading}
          onChange={(e) => handleChange('comment', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />

      </Box>
    </ModalWrapper>)
  );
};

export default AssetModal;
