import * as actionTypes from '../actions/actionTypes';

export const turnoverInitialState = {
  turnover: [],
  gcpi: {
    data: [],
    loading: false,
    loaded: false,
    error: null
  },
  loading: false,
  loaded: false,
  error: null
};
const reducer = (state = turnoverInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TURNOVER_DATA_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_TURNOVER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        turnover: action.data.tenantsList
      };
    case actionTypes.GET_TURNOVER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.GET_GCPI_DATA_START:
      return {
        ...state,
        gcpi: {
          ...state.gcpi,
          loading: true
        }
      };
    case actionTypes.GET_GCPI_DATA_SUCCESS:
      return {
        ...state,
        gcpi: {
          ...state.gcpi,
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_GCPI_DATA_FAIL:
      return {
        ...state,
        gcpi: {
          ...state.gcpi,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
