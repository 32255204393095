import React, { useEffect } from 'react';

import { Grid2, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { openModal } from 'components/Modal/modalReducer';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { formatZillions, numberWithCommas } from '../../../helpers/utils';
import RootPaper from '../../../styles/rootPaper';
import * as actions from '../../../store/actions';
import useRouter from '../../../hooks/useRouter';

export default function Header() {
  const dispatch = useDispatch();
  const { data: asset } = useSelector((state) => state.assets.assetDetails);
  const { assetKpi, loaded } = useSelector((state) => state.assets.assetKpi);
  const { query } = useRouter();

  useEffect(() => {
    if (!query.id || loaded) {
      return;
    }

    dispatch(actions.getAssetKpi(query.id));
  }, [query.id]);

  if (!loaded) {
    return null;
  }

  const icons = [
    {
      icon: RoomOutlinedIcon,
      text: 'Country',
      value: asset.country.includes('United Kingdom') ? 'UK' : asset.country
    },
    {
      icon: PeopleOutlineOutlinedIcon,
      text: 'Leases',
      value: assetKpi?.tenantcount || 0
    },
    {
      icon: ApartmentOutlinedIcon,
      text: 'Type',
      value: asset.type
    },
    {
      icon: ViewQuiltOutlinedIcon,
      text: 'GLA (m2)',
      value: numberWithCommas(Math.trunc(assetKpi?.totalgla), '0') || 0
    },
    {
      icon: AccountBalanceWalletOutlinedIcon,
      text: 'Rent',
      value: `€${formatZillions(assetKpi.annualrent)}`
    },
    {
      icon: DashboardOutlinedIcon,
      text: 'Occupancy',
      value: assetKpi?.occupancyrate || 0
    }
  ];

  return (
    <RootPaper sx={{ padding: '20px' }}>
      <Grid2 container spacing={{lg: 0, xl: 0}} rowSpacing={5}>
        <Grid2 item size={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Typography variant="h4" sx={{
              fontWeight: 400
            }}>
              {asset.name}
            </Typography>
            <Box sx={{ padding: '10px' }}>
              <IconButton aria-label="edit" onClick={() =>
                dispatch(
                  openModal({
                    modalType: 'AssetModal',
                    modalProps: { asset }
                  })
                )
              }>
                <EditIcon sx={{ color: '#3379FF' }} />
              </IconButton>
            </Box>
            <Typography variant="h5" sx={{
              fontWeight: 400,
              marginLeft: 'auto'
            }}>
              {asset.manager}
            </Typography>
          </Box>
        </Grid2>
        {icons.map((icon, index) => (
          <Grid2 item container size={2} justifyContent="center"
                 alignItems="center">
            <Grid2 size={{lg: 3, xl: 2}}>
              <Avatar sx={{ bgcolor: '#D6E2FD', color: '#3379FF' }}>
                <icon.icon />
              </Avatar>
            </Grid2>
            <Grid2 size={{lg: 9, xl: 10}}>
              <Typography variant="body2" color="text.secondary">
                {icon.text}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>{icon.value}</Typography>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </RootPaper>
  );
}
