/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";

import { Box, TextField } from "@mui/material";
import { closeModal } from "components/Modal/modalReducer";
import ModalWrapper from "components/Modal/ModalWrapper";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "store/actions";
import useStyles from "styles/forms";

const getForm = (asset) => ({
  id: asset?.id || '',
  profitcenter: asset?.profitcenter || '',
  costcentercode: asset?.costcentercode || '',
  internalassetid: asset?.internalassetid || ''
});

const AssetOtherModal = () => {
  const classes = useStyles();
  const asset = useSelector((state) => state.modals.modalProps.asset);
  const [form, setForm] = useState(getForm(asset));
  const [formCopy, setFormCopy] = useState(form);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.assets.loading);
  const organizationId = useSelector((state) => state.user.user.organizationid);

  const updateUI = () => {
    setFormCopy(form);
    setForm(getForm());
    dispatch(closeModal());
    dispatch(actions.getAssets());
  };

  const updateAsset = () => {
    dispatch(actions.updateAsset(form, organizationId))
      .then(() => {
        toast.success('Asset Updated!');
        updateUI();
        dispatch(actions.getAssetDetails(form.id));
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSave = () => {
    return updateAsset();
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <ModalWrapper
      size="xl"
      header={form.id ? 'Edit Asset' : 'Create Asset'}
      handleSave={handleSave}
      disabled={isEqual(formCopy, form)}
      loading={loading}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <TextField
          fullWidth
          label="Internal Asset ID"
          variant="outlined"
          className={classes.textField}
          value={form.internalassetid}
          multiline
          disabled={loading}
          onChange={(e) => handleChange('internalassetid', e.target.value)}
          required
          data-cy="asset-internalassetid"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Profit Center"
          variant="outlined"
          className={classes.textField}
          value={form.profitcenter}
          multiline
          disabled={loading}
          onChange={(e) => handleChange('profitcenter', e.target.value)}
          required
          data-cy="asset-profitcenter"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Cost Center Code"
          variant="outlined"
          className={classes.textField}
          value={form.costcentercode}
          multiline
          disabled={loading}
          onChange={(e) => handleChange('costcentercode', e.target.value)}
          required
          data-cy="asset-costcentercode"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
      </Box>
    </ModalWrapper>
  );
};

export default AssetOtherModal;
