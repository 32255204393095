import {GetTenantTurnoverRequest, UpdateTenantTurnoverRequest, GetGCPIRequest, UpdateGCPIRequest} from 'api/turnover_grpc_web_pb';
import {metaData} from 'config/config';
import {DataTurnoverService} from '../services/turnover';

import * as actionTypes from './actionTypes';
import wrappers from "google-protobuf/google/protobuf/wrappers_pb";

const getTurnoverDataStart = () => ({
    type: actionTypes.GET_TURNOVER_DATA_START
});

const getTurnoverDataSuccess = (data) => ({
    type: actionTypes.GET_TURNOVER_DATA_SUCCESS,
    data
});

const getTurnoverDataFail = (error) => ({
    type: actionTypes.GET_TURNOVER_DATA_FAIL,
    error
});

export const updateTurnover = (tenantID, month, year, turnover) => () => {
    const request = new UpdateTenantTurnoverRequest();
    request.setTenantid(tenantID);
    request.setMonth(month);
    request.setYear(year);
    request.setTurnover(turnover);

    return new Promise((resolve, reject) => {
        DataTurnoverService.updateTenantTurnover(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const getTurnoverData = (assetId, year) => (dispatch) => {
    dispatch(getTurnoverDataStart());
    const request = new GetTenantTurnoverRequest();
    request.setAssetid(assetId);
    request.setYear(year);

    return new Promise((resolve, reject) => {
        DataTurnoverService.getTenantTurnover(request, metaData(), (err, response) => {
            if (err) {
                dispatch(getTurnoverDataFail(err.message));
                reject(err.message);
            } else {
                dispatch(getTurnoverDataSuccess(response.toObject()));
                resolve(response.toObject());
            }
        });
    });
};

const getGCPIDataStart = () => ({
    type: actionTypes.GET_GCPI_DATA_START
});

const getGCPIDataSuccess = (data) => ({
    type: actionTypes.GET_GCPI_DATA_SUCCESS,
    data
});

const getGCPIDataFail = (error) => ({
    type: actionTypes.GET_GCPI_DATA_FAIL,
    error
});

export const getGCPIData = (assetId) => (dispatch) => {
    dispatch(getGCPIDataStart());
    const request = new GetGCPIRequest();
    request.setAssetid(assetId);

    return new Promise((resolve, reject) => {
        DataTurnoverService.getGCPI(request, metaData(), (err, response) => {
            if (err) {
                dispatch(getGCPIDataFail(err.message));
                reject(err.message);
            } else {
                dispatch(getGCPIDataSuccess(response.toObject()));
                resolve(response.toObject());
            }
        });
    });
};

export const updateGCPIData = (assetId, yearvalue, averagevalue, year) => () => {
    const request = new UpdateGCPIRequest();
    request.setAssetid(assetId);
    request.setYear(year);
    if (yearvalue) {
        const yearValue = new wrappers.FloatValue();
        yearValue.setValue(yearvalue);
        request.setYearValue(yearValue);
    }
    if (averagevalue) {
        const averageValue = new wrappers.FloatValue();
        averageValue.setValue(averagevalue);
        request.setAverageValue(averageValue);
    }

    return new Promise((resolve, reject) => {
        DataTurnoverService.updateGCPI(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};
